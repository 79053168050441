import InputsObjects from "./InputsObjects";
import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function DisplayManyInputs({
                                              obj, setObj, baseUrl,
                                              setBlockSubmit, name,
                                              inputOrTypography,
                                              message, emailRequired,
                                              rgInput, errorMessage, setErrorMessage,
                                              errorColumn, setErrorColumn, cpfPresent,
                                              cnpjPresent, switchInput,
                                              setLoadData, openPopupEdit,
                                              isCredor, notShowAddressInputs
                                          }) {

    const addComponent = () => {
        const index = obj.length
        setObj([...obj, {index}]);
    };

    const removeComponent = (index) => {
        if (obj.length > 1) {
            const updatedComponents = [...obj];
            updatedComponents.splice(index, 1);
            setObj(updatedComponents);
        }
    };

    const alterItem = (index, data) => {
        setObj((prevComponents) => {
            const updatedComponents = [...prevComponents];
            updatedComponents[index] = data;
            return updatedComponents;
        });
    };

    return (
        <div>
            {obj.map((componentData, index) => (
                <div key={index}>
                    {obj.length > 1 && (
                        <>

                            <Box mb={0.5} pb={0.5}>
                                {name} {index + 1}
                            </Box>
                        </>
                    )}
                    <InputsObjects
                        obj={componentData}
                        setObj={(newData) => {
                            alterItem(index, newData);
                        }}
                        setBlockSubmit={setBlockSubmit}
                        baseUrl={baseUrl}
                        message={message}
                        emailRequired={emailRequired}
                        canBeRemoved={obj.length > 1}
                        canBeNotRemoved={obj.length > 0}
                        removeFunction={() => removeComponent(index)}
                        rgInput={rgInput} switchInput={switchInput}
                        errorMessage={errorMessage} setErrorMessage={setErrorMessage}
                        errorColumn={errorColumn} setErrorColumn={setErrorColumn}
                        cpfPresent={cpfPresent}
                        cnpjPresent={cnpjPresent}
                        setLoadData={setLoadData}
                        openPopupEdit={openPopupEdit}
                        inputOrTypography={inputOrTypography}
                        isCredor={isCredor}
                        notShowAddressInputs={notShowAddressInputs}
                    />
                </div>
            ))}
            <Button fullWidth onClick={addComponent} type='button'>Adicionar mais um {name} </Button>
        </div>
    );
}