import React, {useState} from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {ListItemButton} from "@mui/material";
import AdjustIcon from '@mui/icons-material/Adjust';
import ListItem from '@mui/material/ListItem';

export default function DisplayDetailsData({title, itens, openDetails}) {

    const [show, setShow] = useState(openDetails)

    return (
        <>
            <ListItemButton onClick={() => setShow(!show)}>
                <ListItemIcon>
                    <AdjustIcon/>
                </ListItemIcon>
                <ListItemText primary={title}/>
                {show ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>

            <Collapse in={show} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {itens.map((item, index) => (
                        <ListItem sx={{pl: 4}} key={index}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.display}/>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    )
}