import {useState} from "react";
import InputsClima from "./InputsClima";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";


const PopupEditClima = ({item, baseUrl, setLoadData}) => {

    const [vitalidadeSolo, setVitalidadeSolo] = useState(item.vitalidadeSolo);
    const [temperatura, setTemperatura] = useState(item.temperatura);
    const [pluviosidade, setPluviosidade] = useState(item.pluviosidade);

    const [estadoId, setEstadoId] = useState(item.estado.id);
    const [produtoId, setProdutoId] = useState(item.produto.id)

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsClima
        vitalidadeSolo={vitalidadeSolo} setVitalidadeSolo={setVitalidadeSolo}
        pluviosidade={pluviosidade} setPluviosidade={setPluviosidade}
        temperatura={temperatura} setTemperatura={setTemperatura}
        estadoId={estadoId} setEstadoId={setEstadoId}
        produtoId={produtoId} setProdutoId={setProdutoId}
        errorMessage={errorMessage} setErrorMessage={setErrorMessage}
        errorColumn={errorColumn} setErrorColumn={setErrorColumn}
    />

    const title = "Editar Condições Climáticas"

    const returnData = () => {
        return {
            id: item.id,
            vitalidadeSolo,
            pluviosidade,
            temperatura,
            estadoId,
            produtoId
        }
    }

    return(
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + item.id} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}
export default PopupEditClima