import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterFormCidades from "./FilterFormCidades";
import PopupDeleteCidade from "./PopupDeleteCidade";
import PopupEditCidade from "./PopupEditCidade";
import {useUser} from "../../../auth/AuthProvider";

const ListCidades = ({
                         baseUrl, loadData,
                         setLoadData, size, page,
                         setTotalPages, setTotalElements,
                         sort, setSort, updateCode, setPage
                     }) => {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})


    const titles = [
        "Nome da cidade",
        "Nome do estado"
    ]


    return (
        <>
            <FilterFormCidades filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td>{item.estado.nome}</td>
                        {user.isMaster && <>
                            <td>
                            <span>
                                <PopupEditCidade
                                    item={item}
                                    baseUrl={baseUrl}
                                    setLoadData={setLoadData}
                                />
                            </span>
                            </td>
                            <td>
                            <span>
                                <PopupDeleteCidade
                                    item={item}
                                    baseUrl={baseUrl}
                                    setLoadData={setLoadData}
                                />
                            </span>
                            </td>
                        </>}
                    </tr>
                )}
            </TableList>
        </>
    )
}

export default ListCidades