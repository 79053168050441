import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import SelectInput from "../../../component/SelectInput";
import {aliasRoles} from "./aliasRoles";
import applyRegex from "../../../utils/ApplyRegex";
import {ApiUrl} from "../../../auth/authMethods";

export default function InputsUsers({
                                        username, setUsername,
                                        errorColumn, errorMessage, email, setEmail,
                                        roleId, setRoles, setBlockSubmit
                                    }) {

    const [roleList, setRoleList] = useState([])
    const baseUrl = `${ApiUrl}`

    const [errorEmail, setErrorEmail] = useState('')

    // validação de email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    useEffect(() => {
        if (email.length > 3 && !emailRegex.test(email)) {
            setErrorEmail('Email inválido!')
            setBlockSubmit(true)
        } else {
            setErrorEmail('')
            setBlockSubmit(false)
        }
    }, [email])


    useEffect(() => {
        if (errorColumn === 'email') {
            setErrorEmail(errorMessage)
        } else {
            setErrorEmail('')
        }
    }, [errorMessage, errorColumn])

    return <>
        <TextField
            label="Username"
            inputProps={{
                maxLength: 29
            }}
            error={errorColumn === 'username'}
            helperText={errorColumn === 'username' ? errorMessage : ''}
            value={username}
            onChange={(e) => setUsername(applyRegex(e.target.value, /^[a-zA-Z.]+/))}
            required
            fullWidth
            autoFocus
        />
        <TextField
            label="Email"
            type={"email"}
            inputProps={{
                maxLength: 37,
                minLength: 4
            }}
            error={!!errorEmail}
            helperText={errorEmail}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
        />
        <SelectInput
            url={baseUrl + '/role?size=2000'}
            list={roleList} set={setRoleList}
            setObj={setRoles}
            returnList={() => {
                let returnList = []
                roleList.forEach((item) => {
                    returnList.push({
                        value: item,
                        label: aliasRoles.filter(alias => alias.received === item.name)[0].display
                    })
                })
                return returnList
            }}
            placeholder={"Tipo de usuário"}
            required={true}
            fullWidth
        />
    </>
}

