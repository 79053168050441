import {useUser} from "../../../auth/AuthProvider";
import {Link} from "react-router-dom";


export default function CommonUserNavLinks(){
    const {user} = useUser()

    if (!user?.isAdmin && user?.token)
        return <>
            <Link to={'/cprs'} className='link-light nav-link'>
                Minha(s) CPR(s)
            </Link>
            <Link to={'/cars'} className='link-light nav-link'>
                Meu(s) CAR(s)
            </Link>
        </>
    else {
        return <></>
    }
}