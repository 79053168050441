import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import NumeriInput from "../../../component/NumeriInput";
import {ApiUrl} from "../../../auth/authMethods";


export default function InputCar({
                                     registro, setRegistro,
                                     dataCadastro, setDataCadastro,
                                     nomeProprietario, setNomeProprietario,
                                     nomeImovelRural, setNomeImovelRural,
                                     latitude, setLatitude,
                                     longitude, setLongitude,
                                     hectares, setHectares,
                                     moduloFiscal, setModuloFiscal,
                                     codigoProtocolo, setCodigoProtocolo,
                                     municipioId, setMunicipioId
                                 }) {

    const [municipioList, setMunicipioList] = useState([])
    const baseUrl = `${ApiUrl}`;

    const handleChangeData = (value, set) => {
        let data = new Date(value)
        try {
            set(data.toISOString().slice(0, 10))
        } catch (e) {
            set(value)
        }
    }

    return (
        <>
            <TextField
                label="Registro Car"
                required={true}
                error={registro.length > 60}
                helperText={
                    "Clique no ícone de calendário para inserir a data ou digite manualmente"
                }
                errortext="Campo obrigatório!"
                value={registro}
                onChange={(e) => {
                    if (e.target.value.length <= 60) {
                        setRegistro(e.target.value);
                    }
                }}
                fullWidth
                inputProps={{maxLength: 38}}
                autoFocus
            />

            <DatePicker
                label="Data de Cadastro"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataCadastro ? dayjs(dataCadastro) : null}
                onChange={(e) => handleChangeData(e, setDataCadastro)}
                slotProps={{
                    textField: {
                        required: true
                    },
                }}
            />

            <TextField
                label="Nome do Proprietário"
                required={false}
                value={nomeProprietario}
                onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remover acentos usando a função normalize
                    const normalizedValue = inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                    // Aplicar a expressão regular para permitir apenas letras e espaços
                    const sanitizedValue = normalizedValue.replace(/[^A-Za-z\sáéíóúãõâêîôûàèìòùäëïöü]/g, '');

                    setNomeProprietario(sanitizedValue);
                }}
                fullWidth
                inputProps={{ maxLength: 57, pattern: '^[A-Za-z\\sáéíóúãõâêîôûàèìòùäëïöü]*$' }} // Aceita apenas letras, espaços, acentos e til
            />
            <TextField
                label="Nome do Imóvel Rural"
                required={false}
                value={nomeImovelRural}
                onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remover acentos usando a função normalize
                    const normalizedValue = inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                    // Aplicar a expressão regular para permitir apenas letras e espaços
                    const sanitizedValue = normalizedValue.replace(/[^A-Za-z\sáéíóúãõâêîôûàèìòùäëïöü]/g, '');

                    setNomeImovelRural(sanitizedValue);
                }}
                fullWidth
                inputProps={{ maxLength: 57, pattern: '^[A-Za-z\\sáéíóúãõâêîôûàèìòùäëïöü]*$' }} // Aceita apenas letras, espaços, acentos e til
            />
            {/*// possivelmente faremos validacao para esse cara*/}
            <TextField
                label="Latitude"
                required={true}
                value={latitude}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    setLatitude(inputValue);
                }}
                fullWidth
                inputProps={{
                    maxLength: 20,
                }}
            />
            {/*// possivelmente faremos validacao para esse cara*/}
            <TextField
                label="Longitude"
                required={true}
                value={longitude}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    setLongitude(inputValue);
                }}
                fullWidth
                inputProps={{
                    maxLength: 20,
                }}
            />
            <NumeriInput
                label="Área do Imóvel"
                customInput={TextField}
                suffix=' ha'
                value={hectares}
                decimalScale={4}
                onValueChange={(e) => setHectares(e.floatValue)}
                required
                fullWidth
                inputProps={{
                    maxLength: 36,
                }}
            />
            <SelectInput
                url={baseUrl + '/cidade?size=20000'}
                list={municipioList}
                set={setMunicipioList}
                setObj={setMunicipioId}
                returnList={() => {
                    let returnList = []
                    municipioList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nome} - ${item.estado.sigla}`})
                    })
                    return returnList
                }}
                defaultValue={municipioId}
                placeholder={"Municipio"}
                required={true}
                fullWidth
            />
            <TextField
                label="Módulo Fiscal"
                value={moduloFiscal}
                inputProps={{maxLength: 38}}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
                onChange={(e) => {
                    setModuloFiscal(e.target.value);
                }}
                fullWidth
            />
            <TextField
                label="Código do Protocolo"
                errortext="Campo Não Obrigatório!"
                value={codigoProtocolo}
                inputProps={{maxLength: 38}}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
                onChange={(e) => setCodigoProtocolo(e.target.value)}
                fullWidth
            />
        </>
    )
}