import React, {useEffect, useState} from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import {Dropdown} from "react-bootstrap";
import PopupDetails from "./PopupDetails";
import PopupAddArea from "./PopupAddArea";
import PopupAddCar from "../../Cars/components/PopupAddCar";
import {useUser} from "../../../auth/AuthProvider";
import axios from "axios";
import CarDetails from "../../Cars/components/CarDetails";
import PopupEditCpr from "./PopupEditCpr";
import PopupDeleteCpr from "./PopupDeleteCpr";
import PopupImportKml from "../AddArea/PopupImportKml";
import PopupMonitoring from "./PopupMonitoring";

export default function CprActions({item, baseUrl, setLoadData}) {

    const {user} = useUser()

    const [car, setCar] = useState("");

    useEffect(() => {

        if (item.car) {

            const headers = {
                'Authorization': `Bearer ${user?.token}`
            }

            axios.get(baseUrl + '/car/' + item.car, {headers})
                .then((response) => {
                    if (response?.data)
                        setCar(response.data)
                })
        }
    }, [])

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupDetails item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>

            {user?.isMaster && (<>
                <PopupAddArea
                    id={item.id}
                    numeroOrdem={item.numeroOrdem}
                    coordenadas={item.coordenadas}
                    setLoadData={setLoadData}
                />

                <PopupImportKml
                    cprId={item.id}
                    setLoadData={setLoadData}
                />

                {car && car.ativo &&
                    <CarDetails item={car}/>
                }

                {(!car || !car.ativo) &&
                    <PopupAddCar cprId={item.id}/>
                }
                <PopupEditCpr
                    item={item} baseUrl={baseUrl} setLoadData={setLoadData}
                />

                <PopupMonitoring
                    item={item}
                    baseUrl={baseUrl}
                    setLoadData={setLoadData}
                />

                <PopupDeleteCpr
                    item={item}
                    baseUrl={baseUrl}
                    setLoadData={setLoadData}
                />
            </>)}
        </Dropdown.Menu>

    </Dropdown>
}