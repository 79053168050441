import ContentContainer from "../../component/ContentContainer";
import './Noticias.css';
import NoticiasContent from "./NoticiasContent";

export default function Noticias() {

    return (
        <>
            <ContentContainer width='xl'>
                <NoticiasContent/>
            </ContentContainer>
        </>
    );
}
