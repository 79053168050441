import {useUser} from "../../auth/AuthProvider";
import {useState} from "react";
import displayAlertMsg from "../../component/AlertMsg/displayAlertMsg";
import verifyPassword from "../../utils/verifyPassword";
import axios from "axios";
import {ApiUrl} from "../../auth/authMethods";
import ChangePasswordForm from "./Components/ChangePasswordForm";
import ChangePasswordConfirmation from "./Components/ChangePasswordConfirmation";
import ContentContainer from '../../component/ContentContainer/'

export default function ChangePassword() {

    const {user} = useUser();

    const [isLoading, setIsLoading] = useState(false);
    const [changed, setChanged] = useState(false)

    const [error, setError] = useState(false)
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [passwordConfError, setPasswordConfError] = useState(false)

    const changePassword = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);

        const oldPassword = data.get('oldPassword')
        const newPassword = data.get('newPassword')
        const passwordConf = data.get('passwordConf')

        setOldPasswordError(false)
        setNewPasswordError(false)
        setPasswordConfError(false)

        // password rules verification
        if (oldPassword === newPassword) {
            setError(displayAlertMsg('A sua nova senha não pode ser igual à anterior.'))
            setNewPasswordError(true)
            return
        }

        let passwordValid = verifyPassword(newPassword)
        if (passwordValid !== 'Senha válida') {
            setError(displayAlertMsg(passwordValid))
            setNewPasswordError(true)
            return
        }

        if (newPassword !== passwordConf) {
            setError(
                displayAlertMsg('Confirme corretamente a nova senha!')
            )
            setPasswordConfError(true)
            return
        }

        setError(false)

        // sending request for backend
        const dataToSend = {
            login: user.email? user.email : user.login,
            oldPassword,
            newPassword
        }
        setIsLoading(true)
        axios.post(ApiUrl + '/auth/changePassword', dataToSend)
            .then(function (_) {
                setChanged(true)
            }).catch(function (error) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log("Error: " + error);
            if (error.response.status === 400) {
                setError(
                    displayAlertMsg('A sua senha atual está  incorreta!')
                )
                setOldPasswordError(true)
            }
            if (error.response.status === 500) {
                console.log('erro do sistema')
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const message = user?.firstAccess ? 'Por ser seu primeiro acesso, ' +
        'é necessário que seja feita ' +
        'a alteração da sua senha gerada ' +
        'automaticamente.'
        : user?.passwordExpired ? 'Sua senha expirou! ' +
            'É necessário criar uma nova senha.' : ''

    return (
        <ContentContainer>
            {!changed ?
                <ChangePasswordForm
                    changePassword={changePassword}
                    error={error} oldPasswordError={oldPasswordError}
                    newPasswordError={newPasswordError} passwordConfError={passwordConfError}
                    isLoading={isLoading} message={message}
                /> :
                <ChangePasswordConfirmation linkToLogin={user?.firstAccess || user?.passwordExpired}/>
            }
        </ContentContainer>
    )
}