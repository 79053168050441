import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from "@mui/material";
import React, {useEffect, useState} from "react";
import {aliasRoles} from "./aliasRoles";
import applyRegex from "../../../utils/ApplyRegex";
import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";


export default function InputUsersEdit({
                                           id,
                                           username, setUsername,
                                           email, setEmail,
                                           roles, setRoles,
                                           isEnabled, setIsEnabled,
                                           isCredentialsNonExpired, setIsCredentialsNonExpired,
                                           isAccountNonExpired, setIsAccountNonExpired,
                                           isAccountNonLocked, setIsAccountNonLocked,
                                           isTwoFactorEnabled, setIsTwoFactorEnabled,
                                           roleList, setRoleList,
                                           errorColumn, errorMessage,
                                           setBlockSubmit
                                       }) {

    const {user} = useUser()
    const ownUser = id === user.id
    const [errorEmail, setErrorEmail] = useState('')

    // validação de email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    useEffect(() => {
        if (email.length > 3 && !emailRegex.test(email)) {
            setErrorEmail('Email inválido!')
            setBlockSubmit(true)
        } else {
            setErrorEmail('')
            setBlockSubmit(false)
        }
    }, [email])

    return <>
        <TextField
            label="Username"
            inputProps={{
                maxLength: 29
            }}
            error={errorColumn === 'username'}
            helperText={errorColumn === 'username' ? errorMessage : ''}
            value={username}
            onChange={(e) => setUsername(applyRegex(e.target.value, /^[a-zA-Z.]+/))}
            fullWidth
            autoFocus
        />
        <TextField
            label="Email"
            type={"email"}
            inputProps={{
                maxLength: 37,
                minLength: 4
            }}
            error={!!errorEmail}
            helperText={errorEmail}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
        />

        {!ownUser && <>
            <SelectInput
                url={ApiUrl + '/role?size=2000'}
                list={roleList} set={setRoleList}
                setObj={setRoles}
                defaultValue={roles[0]?.id || roles}
                returnList={() => {
                    let returnList = []
                    roleList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: aliasRoles.filter(alias => alias.received === item.name)[0].display
                        })
                    })
                    return returnList
                }}
                placeholder={"Tipo de usuário"}
                required={false}
                fullWidth
            />

            <FormControlLabel control={
                <Switch checked={isEnabled} onChange={
                    () => setIsEnabled(!isEnabled)
                }/>
            } label="Usuário Ativo"/>

            <FormControlLabel control={
                <Switch checked={!isAccountNonExpired} onChange={
                    () => setIsAccountNonExpired(!isAccountNonExpired)
                }/>
            } label="Conta expirada"/>

            <FormControlLabel control={
                <Switch checked={!isAccountNonLocked} onChange={
                    () => setIsAccountNonLocked(!isAccountNonLocked)
                }/>
            } label="Conta Bloqueada"/>

        </>}

        <FormControlLabel control={
            <Switch checked={isTwoFactorEnabled} onChange={
                () => setIsTwoFactorEnabled(!isTwoFactorEnabled)
            }/>
        } label="Verificação de duas etapas"/>
        <br/>
    </>
}
