import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PopupEditUsers from "./PopupEditUsers";
import UserDetails from "./UserDetails";
import PasswordReset from "./passwordReset";
import {useUser} from "../../../auth/AuthProvider";


export default function UserActions({item, setLoadData, baseUrl}) {

    const {user} = useUser()

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <UserDetails item={item}/>
            {user.isMaster && <>
                <PopupEditUsers item={item} setLoadData={setLoadData}/>
                <PasswordReset item={item} setLoadData={setLoadData} baseUrl={baseUrl + '/user/redefinir-senha/'}/>
            </>}
        </Dropdown.Menu>

    </Dropdown>
}