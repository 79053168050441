import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {useState} from "react";
import ListCidades from "./components/ListCidades";
import PopupAddCidade from "./components/PopupAddCidade";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";

export default function Cidades() {

    const {user} = useUser()

    const baseUrl = `${ApiUrl}/cidade`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(100)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("nome")

    const [totalPages, setTotalPages] = useState("")
    const [totalElements, setTotalElements] = useState(10)

    return <>
        <ContentContainer width='xl'>

            <h1> Lista de Cidades </h1>
            {user.isMaster &&
                <PopupAddCidade baseUrl={baseUrl} setLoadData={setLoadData}/>
            }
            <ListCidades
                setLoadData={setLoadData}
                baseUrl={baseUrl}
                loadData={loadData}
                page={page}
                setPage={setPage}
                size={size}
                setTotalPages={setTotalPages}
                setSort={setSort}
                sort={sort}
                setTotalElements={setTotalElements}
            />
            <PaginationControl
                totalElements={totalElements}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                setLoadData={setLoadData}
                rowsPerPageOptions={[100, 200, 500, 1000]}
            />

        </ContentContainer>
    </>
}