import React, {useEffect, useState} from "react";
import LargeModal from "../../../component/LargeModal/LargeModal";
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import Map from "../AddArea/Map";
import {Divider} from "@mui/material";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import {Button} from "react-bootstrap";
import DefaultProblemMessage from "../../../component/DefaultProblemMessage/DefaultProblemMessage";
import {numeroOrdemMask} from "../../../utils/numeroOrdemMask";

export default function PopupAddArea({id, numeroOrdem, coordenadas, setLoadData}) {

    const [lgShow, setLgShow] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [needToReload, setNeedToReload] = useState(false);
    const numeroOrdemWithMask = numeroOrdemMask(numeroOrdem)

    const button = <>
        <SouthAmericaIcon fontSize="medium"/>
        &nbsp;Adicionar Área
    </>

    const title = "Adicionar Área"

    const iconSpan = <>
        <SouthAmericaIcon fontSize="large"/>
        <br/>{title}
    </>

    useEffect(() => {
        if (!lgShow) {
            setError(false)
            setSent(false)
            if (needToReload){
                setLoadData(true)
                setNeedToReload(false)
            }
        }
    }, [lgShow])


    return (
        <>
            <LargeModal
                buttonText={button}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonVariant={"text"}
                buttonClass='dropdown-item'
                backdrop='static'
            >

                <Divider/>
                {error ?
                    <DefaultProblemMessage
                        message={<>
                            Houve um erro no cadastro da área, tente novamente mais tarde. <br/>
                            Caso o erro persista, contate o suporte
                        </>}/> :
                    sent ?
                        <DefaultSuccessMessage message='A área foi cadastrada com sucesso!'>
                            <Button onClick={() => setSent(false)}>
                                Cadastrar nova área
                            </Button>
                        </DefaultSuccessMessage> :
                        <>
                            <h5 className="text-center">Selecione o campo onde será feita a plantação referente à cpr de
                                ordem: {numeroOrdemWithMask}</h5>
                            <Map
                                id={id} coordenadas={coordenadas}
                                setSent={setSent} setError={setError}
                                setNeedToReload={setNeedToReload}
                            />
                        </>
                }
            </LargeModal>
        </>
    )
}