import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsCredor from "./InputsCredor";

const PopupEditCredor = ({ item, baseUrl, setLoadData, highlighted}) => {

    const [id, setId] = useState("");
    const [nome, setNome] = useState("");
    const [cidadeNome, setCidadeNome] = useState("");
    const [cnpj, setCnpj] = useState();
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState();
    const [cidadeId, setCidadeId] = useState('')
    const [cepEndereco, setCepEndereco] = useState("");
    const [complementoEndereco, setComplementoEndereco] = useState("");
    const [logradouroEndereco, setLogradouroEndereco] = useState("");
    const [numeroEndereco, setNumeroEndereco] = useState("");
    const [bairroEndereco, setBairroEndereco] = useState("");
    const [endereco, setEndereco] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");

    const [blockSubmit, setBlockSubmit] = useState(false);

    const inputs = (
        <InputsCredor
            required={false}
            nome={nome}
            setNome={setNome}
            cidadeNome ={cidadeNome}
            setCidadeNome={setCidadeNome}
            cnpj={cnpj} setCnpj={setCnpj}
            cpf={cpf} setCpf={setCpf}
            email={email} setEmail={setEmail}
            endereco={endereco} setEndereco={setEndereco}
            cidadeId={cidadeId} setCidadeId={setCidadeId}
            bairro={bairroEndereco} setBairro={setBairroEndereco}
            cep={cepEndereco} setCep={setCepEndereco}
            complemento={complementoEndereco} setComplemento={setComplementoEndereco}
            logradouro={logradouroEndereco} setLogradouro={setLogradouroEndereco}
            numero={numeroEndereco} setNumero={setNumeroEndereco}
            setErrorColumn={setErrorColumn}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            errorColumn={errorColumn}
        />
    );

    const itemToState = () => {
        setId(item.id); // Assuming item has an 'id' property
        setNome(item.nome);
        setEmail(item.email);
        setCnpj(item.cnpj === null ? "" : item.cnpj);
        setCpf(item.cpf === null ? "" : item.cpf)
        setCidadeNome(item.endereco?.cidade?.nome);
        setCidadeId(item.endereco?.cidade?.id)
        setComplementoEndereco(item.endereco?.complemento)
        setBairroEndereco(item.endereco?.bairro)
        setNumeroEndereco(item.endereco?.numero)
        setCepEndereco(item.endereco?.cep)
        setLogradouroEndereco(item.endereco?.logradouro)
        setErrorMessage("");
        setErrorColumn("");
        setBlockSubmit(false);
    };

    const title = "Editar Credor";

    const returnData = () => {

        const data = {
        }

        if (nome !== '') {
            data.nome = nome;
        }
        if (email !== '') {
            data.email = email;
        }
        if (cnpj !== "" ) {
            data.cnpj = cnpj;
        }
        if (cpf !== "") {
            data.cpf = cpf;
        }
        if (cepEndereco !== null) {
            data.cepEndereco = cepEndereco;
        }
        if (complementoEndereco !== null) {
            data.complementoEndereco = complementoEndereco;
        }
        if (logradouroEndereco !== null) {
            data.logradouroEndereco = logradouroEndereco;
        }
        if (numeroEndereco !== null) {
            data.numeroEndereco = numeroEndereco;
        }
        if (bairroEndereco !== null) {
            data.bairroEndereco = bairroEndereco;
        }
        if (cidadeNome !== null) {
            data.cidadeNome = cidadeNome;
        }
        if (cidadeId !== null) {
            data.cidadeId = cidadeId;
        }

        return (data);
    };

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + id}
                setLoadData={setLoadData}
                inputs={inputs}
                title={title}
                returnData={returnData}
                itemToState={itemToState}
                setErrorMessage={setErrorMessage}
                setErrorColumn={setErrorColumn}
                highlighted={highlighted}
            />
        </>
    );
};
export default PopupEditCredor;
