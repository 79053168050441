import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";

const PopupDeleteCidade = ({
                                         baseUrl,
                                         setLoadData,
                                         item
                                     }) => {
    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de Cidade"} id={item.id}
        >

            <div className="mt-3 mb-3">
                <b>Nome da Cidade: </b>
                <>{item.nome}</>
                <br/>
                <b>Nome do Estado: </b>
                <>{item.estado.nome}</>
                <br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir esta cidade?</p>

        </PopupDelete>
    )
}
export default PopupDeleteCidade