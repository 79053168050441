import { NumericFormat } from 'react-number-format';
import TextField from "@mui/material/TextField";
import React from "react";

export default function NumeriInput(props){
    return <NumericFormat
        customInput={TextField}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        {...props}
    />
}