import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../utils/ApplyRegex";


export default function FilterFormEstados({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [nome, setNome] = useState('');
    const [sigla, setSigla] = useState('');

    const handlesSubmit = (e) => {

        e.preventDefault();

        setFilter({nome, sigla});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNome('')
        setSigla('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <TextField
                label="Estado"
                inputProps={{
                    maxLength: 40
                }}
                value={nome}
                onChange={(e) => {
                    setNome(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ ])/g))
                }}
                fullWidth
                autoFocus
            />

            <TextField
                label="Sigla"
                inputProps={{
                    maxLength: 2
                }}
                value={sigla}
                onChange={(e) => {
                    setSigla(applyRegex(e.target.value.toUpperCase(), /[A-Z]/g));
                }}
                fullWidth
            />

        </FilterForm>
    </>
}