import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import {rgMask} from "../../../utils/rgMask";
import {cnpjMask} from "../../../utils/cnpjMask";
import FilterFormEmitente from "./FilterFormEmitente";
import PopupEditEmitente from "./PopupEditEmitente";
import {cpfMask} from "../../../utils/cpfMask";
import {useUser} from "../../../auth/AuthProvider";

const ListEmitente = ({
                          baseUrl, loadData,
                          setLoadData, size, page,
                          setTotalPages, setTotalElements,
                          sort, setSort, updateCode, setPage
                      }) => {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})


    const titles = [
        "Nome do emitente",
        "RG do emitente",
        "CPNJ/CPF do emitente",
        "Endereço do emitente",
    ]

    const displayEndereco = (endereco) => {
        if (endereco)
            return endereco.cidade.nome + " - " + endereco.cidade.estado.sigla
        else
            return ''
    }


    return (
        <>
            <FilterFormEmitente filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td>{item.rg}</td>
                        <td>{item.cpf === null ? cnpjMask(item.cnpj) : cpfMask(item.cpf) || ''}</td>
                        <td>{displayEndereco(item.endereco)}</td>
                        {user.isMaster &&
                            <td>
                                <PopupEditEmitente
                                    setLoadData={setLoadData}
                                    baseUrl={baseUrl}
                                    item={item}
                                />
                            </td>
                        }
                    </tr>
                )}
            </TableList>
        </>
    )
}

export default ListEmitente