import React, {useState} from "react";
import FilterForm from "../../../component/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";


export default function FilterFormClimas ({baseUrl, filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [vitalidadeSolo, setVitalidadeSolo] = useState('');
    const [temperatura, setTemperatura] = useState('');
    const [pluviosidade, setPluviosidade] = useState('');

    const [estadoId, setEstadoId] = useState('');
    const [produtoId, setProdutoId] = useState('')

    const [estadoList, setEstadoList] = useState([])
    const [productsList, setProductsList] = useState([])


    const handlesSubmit = (e) => {

        e.preventDefault();

        setFilter({
            vitalidadeSolo,
            temperatura,
            pluviosidade,
            estadoNome: estadoId,
            produtoId
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setVitalidadeSolo('')
        setTemperatura('')
        setPluviosidade('')
        setEstadoId('')
        setProdutoId('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <TextField

                label="Vitalidade do Solo"
                inputProps={{
                    maxLength: 10
                }}
                type="number"
                value={vitalidadeSolo}
                onChange={(e) =>{
                    if (e.target.value.length <= 10) {
                        setVitalidadeSolo(e.target.value);
                    }
                }}
                fullWidth
                autoFocus
            />

            <TextField
                label="Pluviometria"
                inputProps={{
                    maxLength: 10
                }}
                value={pluviosidade}
                onChange={(e) =>{
                    if (e.target.value.length <= 10) {
                        setPluviosidade(e.target.value);
                    }
                }}
                fullWidth
                type="number"
            />

            <TextField
                label="Temperatura"
                inputProps={{
                    maxLength: 10
                }}
                type="number"
                value={temperatura}
                onChange={(e) =>{
                    if (e.target.value.length <= 10) {
                        setTemperatura(e.target.value);
                    }
                }}
                fullWidth
            />

            <SelectInput
                url={ApiUrl + '/estado?size=2000'}
                list={estadoList} set={setEstadoList}
                setObj={setEstadoId}
                returnList={() => {
                    let returnList = []
                    estadoList.forEach((item) => {
                        returnList.push({value: item.nome, label: item.nome})
                    })
                    return returnList
                }}
                placeholder={"Estado"}
                defaultValue={estadoId?.nome}
                fullWidth
            />
            <SelectInput
                url={ApiUrl + '/produto?size=2000'}
                list={productsList} set={setProductsList}
                setObj={setProdutoId}
                returnList={() => {
                    let returnList = []
                    productsList.forEach((item) => {
                        returnList.push({value: item.id, label: item.tipo + " - " + item.variedade})
                    })
                    return returnList
                }}
                placeholder={"Produto"}
                defaultValue={produtoId}
                fullWidth
            />
        </FilterForm>
    </>
}