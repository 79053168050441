import {useUser} from "../../auth/AuthProvider";
import useAuthMethods from "../../auth/authMethods";
import {useEffect, useState} from "react";

export default function TokenValidator() {

    const minutesToRevalidate = 1

    const [check, setCheck] = useState(true)

    const {user, removeUser} = useUser()
    const {validateToken} = useAuthMethods()

    // verifica periodicamente o token do usuário
    useEffect(() => {
        // Função que será executada após o intervalo definido
        function verifyToken() {
            if (user?.token) {
                validateToken().then(valid => {
                    if (!valid) {
                        removeUser()
                    }
                })
            }
            setCheck(false)
        }

        if (check)
            verifyToken()

    }, [check]);

    useEffect(() => {

        function alterCheck() {
            setCheck(true)
        }

        const intervalId = setInterval(alterCheck, minutesToRevalidate * 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [])
}