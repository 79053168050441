import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import React from "react";

const PopupDeleteCalendario = ({
                                         baseUrl,
                                         setLoadData,
                                         item
                                     }) => {
    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
                title={"Confirmar Exclusão de Calendário"} id={item.id}
        >

            <div className="mt-3 mb-3">
                <b>Região do calendário: </b>
                <>{item.regiao}</>
                <br/>
                <b>Data inicial: </b>
                <>{item.dataInicial}</>
                <br/>
                <b>Data Final: </b>
                <>{item.dataFinal}</>
                <br/>
                <b>Tipo: </b>
                <>{item.tipo}</>
                <br/>
                <b>Data inicial: </b>
                <>{item.produtoTipo +" - "+ item.produtoVariedade}</>
                <br/>
                <b>Ano: </b>
                <>{item.ano}</>
                <br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir este calendário?</p>

        </PopupDelete>
    )
}
export default PopupDeleteCalendario