import PasswordIcon from "@mui/icons-material/Password";
import InputPassword from "../../../component/InputPassword/InputPassword";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function ChangePasswordForm({
                                               changePassword,
                                               error, oldPasswordError,
                                               newPasswordError, passwordConfError,
                                               isLoading, message
                                           }) {

    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
        >
            Alterar
        </Button>


    return <Box component="form" onSubmit={changePassword} sx={{mt: 1}}>
        <div className="text-center">
            {message &&
                <b>
                    {message}
                    <br/>
                </b>
            }
            <PasswordIcon fontSize='large' sx={{fontSize: '4rem'}}/><br/>
            <b>Alterar sua senha </b><br/>
            <div style={{border: '1px solid black', fontSize: '.8rem', margin: '2% 0'}}>
                <p>Requisitos para a nova senha:</p>
                <ul style={{margin: '-1% 0 1% 0'}} className='text-start '>
                    <li>No mínimo 8 caracteres</li>
                    <li>Possuir ao menos uma letra maiúscula</li>
                    <li>Possuir ao menos uma letra minúscula</li>
                    <li>Possuir ao menos um caractere especial</li>
                </ul>
            </div>
            Preencha os campos abaixo.
        </div>
        {error}
        <InputPassword
            id={'oldPassword'}
            name={'oldPassword'}
            label={'Senha atual'}
            error={oldPasswordError}
        />
        <InputPassword
            id={'newPassword'}
            name={'newPassword'}
            label={'Nova senha'}
            error={newPasswordError}
        />
        <InputPassword
            id={'passwordConf'}
            name={'passwordConf'}
            label={'Confirmar nova senha'}
            error={passwordConfError}
        />

        {button}
    </Box>
}