import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsEstado from "./InputsEstado";


const PopupEditEstado = ({item, baseUrl, setLoadData}) => {

    const [id, setId] = useState("")
    const [nome, setNome] = useState("")
    const [sigla, setSigla] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsEstado
        nome={nome} setNome={setNome}
        sigla={sigla} setSigla={setSigla}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
    />
    const itemToState = () => {
        setId(item.id)
        setNome(item.nome)
        setSigla(item.sigla)
    }

    const title = "Editar Estado"

    const returnData = () => {
        return {
            id: item.id,
            nome,
            sigla
        }
    }

    return(
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + id} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}
export default PopupEditEstado