import React, {useState} from "react";
import InputsCprs from "./inputs/InputsCprs";
import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

const PopupAddCprs = ({baseUrl, setLoadData}) => {

    const [blockSubmit, setBlockSubmit] = useState(false);

    const [numeroOrdem, setNumeroOrdem] = useState("")
    const [dataVencimentoCedula, setDataVencimentoCedula] = useState("")
    const [dataEmissaoCedula, setDataEmissaoCedula] = useState("")
    const [produtoId, setProdutoId] = useState('')
    const [quantidadeProduto, setQuantidadeProduto] = useState('')
    const [estadoId, setEstadoId] = useState('')
    const [localEmissao, setLocalEmissao] = useState('')
    const [cidadeId, setCidadeId] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)
    const [errorColumn, setErrorColumn] = useState(false)

    const [showAdressInputs, setShowAdressInputs] = useState()


    const [credores, setCredores] = useState([
        {index: 0},
    ]);

    const [emitentes, setEmitentes] = useState([
        {index: 0},
    ]);

    const [coordenadas, setCoordenadas] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const icon = <WorkOutlineIcon fontSize="large" id="icon-svg"/>

    const title = "CPR's"

    const inputs = <InputsCprs
        numeroOrdem={numeroOrdem} setNumeroOrdem={setNumeroOrdem}
        dataVencimentoCedula={dataVencimentoCedula} setDataVencimentoCedula={setDataVencimentoCedula}
        dataEmissaoCedula={dataEmissaoCedula} setDataEmissaoCedula={setDataEmissaoCedula}
        coordenadas={coordenadas} setCoordenadas={setCoordenadas}
        credores={credores} setCredores={setCredores} emitentes={emitentes} setEmitentes={setEmitentes}
        produtoId={produtoId} setProdutoId={setProdutoId} setBlockSubmit={setBlockSubmit}
        estadoId={estadoId} setEstadoId={setEstadoId}
        quantidadeProduto={quantidadeProduto} setQuantidadeProduto={setQuantidadeProduto}
        localEmissao={localEmissao} setLocalEmissao={setLocalEmissao}
        cidadeId={cidadeId} setCidadeId={setCidadeId}
        errorMessage={errorMessage} setErrorMessage={setErrorMessage}
        errorColumn={errorColumn} setErrorColumn={setErrorColumn}
        inputOrTypography={true} notShowAddressInputs={false}
    />

    const clearForm = () => {
        setNumeroOrdem("")
        setDataVencimentoCedula("")
        setDataEmissaoCedula("")
        setCidadeId('')
        setProdutoId('')
        setQuantidadeProduto('')
        setCoordenadas('')
        setLocalEmissao('')
        setCredores([{index: 0},])
        setEmitentes([{index: 0},])
        setErrorColumn(false)
        setErrorMessage(false)
    }

    const returnData = () => {
        let credoresIds = []
        let credoresData = []
        credores.map((credor) => {
            credor?.id?
                credoresIds.push(credor.id):
                credoresData.push(credor)
        })

        let emitentesIds = []
        let emitentesData = []
        emitentes.map((emitente) => {
            emitente?.id?
                emitentesIds.push(emitente.id):
                emitentesData.push(emitente)
        })
        console.log({
            numeroOrdem: numeroOrdem.replace(/[^\w\s]/gi, ''),
            dataVencimentoCedula,
            dataEmissaoCedula,
            coordenadas,
            localEmissao,
            cidadeId,
            produtoId,
            quantidadeProduto,
            emitentesIds,
            emitentes: emitentesData,
            credoresIds,
            credores: credoresData
        })

        return {
            numeroOrdem: numeroOrdem.replace(/[^\w\s]/gi, ''),
            dataVencimentoCedula,
            dataEmissaoCedula,
            coordenadas,
            localEmissao,
            cidadeId,
            produtoId,
            quantidadeProduto,
            emitentesIds,
            emitentes: emitentesData,
            credoresIds,
            credores: credoresData
        }
    }



    return (
        <>
            <PopupAdd
                baseUrl={baseUrl + '/cpr'} setLoadData={setLoadData}
                inputs={inputs} title={title} icon={icon}
                clearForm={clearForm} returnData={returnData}
                disableButton={isLoading} blockSubmit={blockSubmit}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}
export default PopupAddCprs