import ReCAPTCHA from "react-google-recaptcha";

export default function GoogleRecaptcha({setCaptcha}) {

    return <div className='' style={{margin: '1.5% 12%'}}>
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={setCaptcha}
            // size='invisible'
        />
    </div>
}