import React, {useEffect, useState} from "react";
import MonitorIcon from '@mui/icons-material/Monitor';
import LargeModal from "../../../component/LargeModal/LargeModal";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import GrassIcon from '@mui/icons-material/Grass';
import CloudIcon from '@mui/icons-material/Cloud';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BiomassaGrafico from "../../../component/Dashboard/BiomassaGrafico";
import MapWithFeatures from "./MapWithFeatures";
import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";
import {numeroOrdemMask} from "../../../utils/numeroOrdemMask";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import List from "@mui/material/List";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import Filter1Icon from "@mui/icons-material/Filter1";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import RoomIcon from "@mui/icons-material/Room";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import BadgeIcon from "@mui/icons-material/Badge";
import {cpfMask} from "../../../utils/cpfMask";
import {cnpjMask} from "../../../utils/cnpjMask";

export default function PopupMonitoring({item, baseUrl, setLoadData, setErrorMessage, setErrorColumn})  {

    const {user} = useUser()
    const [lgShow, setLgShow] = useState(false);
    const [features, setFeatures] = useState([]);
    const [needToReload, setNeedToReload] = useState(false);

    useEffect(() => {
        if (!lgShow && needToReload)
            setLoadData(true)
    }, [lgShow])


    const button = (
        <>
            <MonitorIcon fontSize="medium"/>
            &nbsp; Monitoramento CPR
        </>
    );

    const title = `Monitoramento da CPR: ${item.emitentes[0].nome}`;

    const iconSpan = (
        <>
            <MonitorIcon fontSize="large" id="icon-svg"/>
            {title}
        </>
    );

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${user?.token}`
        }
        axios.get(`${baseUrl}/arquivos/cpr/${item.id}`, {headers})
            .then(function (response) {

                // Mapeie os objetos conteudo para uma nova lista
                const conteudos = response.data.map(item => ({
                    ...JSON.parse(item.conteudo),
                    id: item.id,
                    hectares: item.hectares,
                    nome: item.nome
                }));

                setFeatures(conteudos);
                console.log(features)
            })
            .catch(function (errorReceived) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(errorReceived);
            });
    }, []);

    return (
        <LargeModal
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonId={"links-action"}
            buttonClass='dropdown-item'
        >

            {needToReload ?
                <DefaultSuccessMessage message='O campo excluído com sucesso!'/> :
                <>
                    {features.length > 0 ?
                        <MapWithFeatures
                            item={item}
                            features={features}
                            setNeedToReload={setNeedToReload}
                            baseUrl={baseUrl}
                            showAruaEditor={setLoadData}
                            setErrorMessage={setErrorMessage}
                            setErrorColumn={setErrorColumn}
                        /> :
                        <h5 className='text-center'>
                            Ainda não há nenhuma área cadastrada para esta CPR!
                        </h5>
                    }
                </>
            }
        </LargeModal>
    );
};

