import React, {useEffect, useRef, useState} from 'react';
import {GoogleMap, Polygon} from '@react-google-maps/api';
import Typography from "@mui/material/Typography";
import axios from "axios";
import {Button} from 'react-bootstrap';
import {useUser} from "../../../auth/AuthProvider";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InputsMap from "./inputs/inputsMap";
import { Link } from 'react-router-dom';

import "./inputsMap.css";

const MapWithFeatures = ({item, features, baseUrl, setNeedToReload, showAruaEditor , setErrorMessage, setErrorColumn}) => {

    const {user} = useUser()

    const [map, setMap] = useState(null);
    const [activePolygon, setActivePolygon] = useState(null);
    const [polygons, setPolygons] = useState([]);
    const [updatedFeatures, setUpdatedFeatures] = useState(features);
    const [rerender, setRerender] = useState(false);
    const [hectares, setHectares] = useState(null);

    const [nome, setNome] = useState("")
    const [id, setId] = useState("")

    const [showPopup, setShowPopup] = useState(false);

    const arquivoId = activePolygon ? activePolygon?.id : null;

    const [mapCenter, setMapCenter] = useState({
        lat: -14.235004,
        lng: -51.925280,
    });
    const [mapPolygons, setMapPolygons] = useState([]);

    const containerStyle = {
        width: '100%',
        height: '400px',
    };

    const displayHectares = () => {
        if (activePolygon && activePolygon.hectares) {
            setHectares(activePolygon.hectares);
        }
    };

    let center = {
        lat: -14.235004,
        lng: -51.925280,
    };

    const zoom = 4;

    const convertCoordinates = (coordinates) => {
        return coordinates.map(coordPair => ({
            lat: coordPair[1],
            lng: coordPair[0],
        }));
    };

    const polygonRefs = useRef([]);

    useEffect(() => {
        if (map && features && features.length > 0) {
            polygonRefs.current.forEach(polygon => {
                polygon.setMap(null);
            });

            polygonRefs.current = [];

            const allPolygonCoordinates = [];

            const newPolygons = features.map((feature) => {
                const coordinates = convertCoordinates(feature[0].geometry.coordinates[0]);
                allPolygonCoordinates.push(...coordinates);

                const polygon = new window.google.maps.Polygon({
                    paths: coordinates,
                    map: map,
                });

                polygon.addListener('click', () => {
                    setActivePolygon(feature);
                });

                polygonRefs.current.push(polygon);

                return polygon;
            });

            setPolygons(newPolygons);

            const bounds = new window.google.maps.LatLngBounds();
            allPolygonCoordinates.forEach((coord) => {
                bounds.extend(coord);
            });

            map.fitBounds(bounds);
        }
    }, [map, features, rerender]);

    const navigateToPolygon = (polygon) => {
        if (polygon && polygon[0] && polygon[0].geometry && polygon[0].geometry.coordinates) {
            const coordinates = convertCoordinates(polygon[0].geometry.coordinates[0]);
            const bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach((coord) => {
                bounds.extend(coord);
            });
            map.fitBounds(bounds);
            setActivePolygon(polygon)
        }
    };

    const handleDeletePolygon = () => {
        if (activePolygon) {
            const arquivoId = activePolygon ? activePolygon?.id : null;
            axios.put(`${baseUrl}/arquivos/${arquivoId}`, {},{
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                },
            })
                .then(response => {
                    console.log("Arquivo excluído com sucesso:", response);
                    setNeedToReload(true)
                })
                .catch(error => {
                    console.error("Erro ao excluir arquivo:", error);
                });

            const polygonIndex = updatedFeatures.indexOf(activePolygon);
            if (polygonIndex !== -1) {
                const deletedPolygon = polygonRefs.current[polygonIndex];
                deletedPolygon.setMap(null);

                setPolygons((prevPolygons) => prevPolygons.filter((_, index) => index !== polygonIndex));
                setUpdatedFeatures((prevFeatures) => prevFeatures.filter((_, index) => index !== polygonIndex));
                polygonRefs.current.splice(polygonIndex, 1);
            }

            setActivePolygon(null);

            setRerender(prev => !prev);
        }
    };

    useEffect(() => {
        if (activePolygon) {
            navigateToPolygon(activePolygon);
            displayHectares(); // Chamando a função para exibir os hectares
        } else {
            setHectares(null); // Limpar os hectares quando nenhum polígono estiver ativo
        }
    }, [activePolygon]);

    useEffect(() => {
        if (map && mapCenter && mapPolygons.length > 0) {
            map.setCenter(mapCenter);

            mapPolygons.forEach(polygon => {
                polygon.setMap(map);
            });
        }
    }, [map, mapCenter, mapPolygons]);

    const inputs = <InputsMap
        nome={nome}
        setNome={setNome}
        />

    const title = "Editar Nome do Campo"
    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp;{title}
    </>

    const returnData = () => {

        console.log({
            nome,
            features
        })

        return {
            nome:nome
        }
    }

    return (
        <div style={{ marginInline: '10%' }}>
            <Typography style={{ marginTop: '10px', marginBottom: '10px' }} variant="h5">
                Campos salvos
            </Typography>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={zoom}
                onLoad={(map) => setMap(map)}
                mapTypeId={'satellite'}
                options={{
                    mapTypeId: 'satellite',
                    streetViewControl: false,
                }}
            >
                {polygons.map((polygon, index) => (
                    <Polygon
                        key={index}
                        path={polygon.getPath().getArray()}
                        onClick={() => setActivePolygon(updatedFeatures[index])}
                    />
                ))}
            </GoogleMap>

            <div className='align-items-center text-center'>
                {updatedFeatures.map((feature, index) => (
                    <Button
                        key={index}
                        onClick={() => navigateToPolygon(feature)}
                        style={{ marginTop: '10px', marginRight: '10px' }}
                    >
                        Campo: {feature.nome.split('.zip')[0]}(#{feature.id})
                    </Button>
                ))}
            </div>
            <hr />
            {hectares && (
                <div className='align-items-center text-center'>
                    <Typography variant="body1">
                        Hectares do Polígono: {hectares.toString().replace('.', ',')} ha
                    </Typography>
                </div>
            )}
            {user.isMaster && showAruaEditor && (
                <div style={{ marginTop: '20px' }} className='align-items-center text-center'>
                    {activePolygon ? (
                        <div className='align-items-end d-inline-block'>
                            <Typography variant="h5" className='d-inline-block'>
                                Campo {activePolygon.nome.split('.zip')[0]}(#{activePolygon.id}):
                            </Typography>
                            <br/>
                            &nbsp;
                            {user.isMaster && (
                                <>
                                    <br/>
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        variant={"danger"}
                                        onClick={handleDeletePolygon}
                                        className='d-inline-block'
                                    >
                                        Excluir Campo
                                    </Button>
                                </>
                            )}
                            &nbsp;
                            &nbsp;
                            {user.isMaster && (
                                <>
                                    <PopupEdit
                                        baseUrl={baseUrl + `/arquivos/update/${arquivoId}`} setLoadData={showAruaEditor}
                                        inputs={inputs} title={title}
                                        returnData={returnData}
                                        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                                        buttonText={buttonText}
                                        highlighted={true}
                                        className="edit-monitoring"
                                    />
                                </>
                            )}
                            &nbsp;
                            {/*<MonitoringScreen*/}
                            {/*    item={item}*/}
                            {/*    campoNome={activePolygon.nome.split('.')[0]}*/}
                            {/*/>*/}
                            <Link to={`/monitoringfield/${activePolygon.id}`} target="_blank">
                                <Button
                                    style={{ marginLeft: '10px' }}
                                    variant="primary"
                                >
                                    Monitorar Campo
                                </Button>
                            </Link>
                            <Button
                                style={{ marginLeft: '10px' }}
                                variant="outline-danger"
                                onClick={() => setActivePolygon(null)}
                                className='d-inline-block'
                            >
                                Cancelar
                            </Button>
                        </div>
                    ) : (
                        <Typography variant="h6">
                            * Caso queira excluir algum campo, clique em sua área ou em seu respectivo botão.
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );

};

export default MapWithFeatures;