import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useUser} from "../../auth/AuthProvider";


export default function Logout({pathToRedirect}) {

    const navigate = useNavigate()
    const {removeUser} = useUser()

    useEffect(() => {
        removeUser()
        navigate(pathToRedirect)
    })

    return <></>
}