import "./FilterForm.css";
import React, { useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import LargeModal from "../LargeModal/LargeModal";
import { Button, Form } from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

const FilterForm = ({ lgShow, setLgShow, handleSubmit, clearFilter, children, showSecondaryButton }) => {
    const [isLoading, setIsLoading] = useState(false); // Adicionando estado de carregamento

    const button = <>
        Filtros <TuneIcon fontSize="medium"/>
    </>;

    const title = <>
        Filtros
    </>;

    const iconSpan = <>
        <TuneIcon fontSize="large"/>
        <h3>Escolha quais campos filtrar</h3>
    </>;

    const secondaryButton = <Button className='btn-secondary'
                                    onClick={() => {
                                        clearFilter();
                                    }}
    >
        Limpar Filtros <CleaningServicesIcon fontSize="medium"/>
    </Button>;

    const handleSubmitWithLoading = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Ativar o estado de isLoading quando o formulário for enviado
        await handleSubmit(e);
        setIsLoading(false); // Desativar o estado de isLoading após o término do handleSubmit
    };

    return (
        <LargeModal
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonVariant={"text"} buttonClass='btn btn-primary btn-show-filter'
            secondaryButton={showSecondaryButton ? secondaryButton : null}
        >
            <Form onSubmit={handleSubmitWithLoading} className="form-inputs form-filter">
                {children}
                <Button
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? "Filtrando..." : "Filtrar"} <SearchIcon fontSize="medium"/>
                </Button>
                <Button
                    type="reset"
                    onClick={clearFilter}
                    className="btn btn-secondary"
                    disabled={isLoading}
                >
                    Limpar <CleaningServicesIcon fontSize="medium"/>
                </Button>
            </Form>
        </LargeModal>
    );
};

export default FilterForm;
