import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import {normalizeTimestemp} from "../../utils/normalizeTimestemp";

//mock data
const mockData = [
    {
        "imagem": "img - 03 fev 2024.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/b9ba5bd5-4565-441c-be77-9c07c9b25a67/thumbnails/vitality.png",
        "biomassa": "79%",
        "nuvens": "22%",
        "data": "2024-02-03",
        "dataNormalizada": "03 fev 2024"
    },
    {
        "imagem": "img - 04 jan 2024.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/39baa913-9217-4252-93f2-142c565d74e1/thumbnails/vitality.png",
        "biomassa": "66%",
        "nuvens": "14%",
        "data": "2024-01-04",
        "dataNormalizada": "04 jan 2024"
    },
    {
        "imagem": "img - 26 out 2023.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/22e47ef3-4896-44a2-bd6c-a4d001448d63/thumbnails/vitality.png",
        "biomassa": "32%",
        "nuvens": "0%",
        "data": "2023-10-26",
        "dataNormalizada": "26 out 2023"
    },
    {
        "imagem": "img - 01 out 2023.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/64b61135-d32a-41ae-9129-2c8dfdaebf3a/thumbnails/vitality.png",
        "biomassa": "41%",
        "nuvens": "0%",
        "data": "2023-10-01",
        "dataNormalizada": "01 out 2023"
    },
    {
        "imagem": "img - 26 set 2023.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/36e002c9-f052-43ea-8c51-1c89cf06daa4/thumbnails/vitality.png",
        "biomassa": "44%",
        "nuvens": "0%",
        "data": "2023-09-26",
        "dataNormalizada": "26 set 2023"
    },
    {
        "imagem": "img - 06 set 2023.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/dbaf20d9-d4ae-4f3e-9fc0-313d3be438bc/thumbnails/vitality.png",
        "biomassa": "57%",
        "nuvens": "38%",
        "data": "2023-09-06",
        "dataNormalizada": "06 set 2023"
    },
    {
        "imagem": "img - 27 ago 2023.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/9ca88abe-28eb-4053-9e60-578e14c6d1de/thumbnails/vitality.png",
        "biomassa": "67%",
        "nuvens": "2%",
        "data": "2023-08-27",
        "dataNormalizada": "27 ago 2023"
    },
    {
        "imagem": "img - 22 ago 2023.jpg",
        "url": "https://tiles.skyfld.com/biomassmap/04a7dd2b-4dce-4795-874d-0e440c133e82/thumbnails/vitality.png",
        "biomassa": "65%",
        "nuvens": "39%",
        "data": "2023-08-22",
        "dataNormalizada": "22 ago 2023"
    }
]

// chart options
const areaChartOptions = {
    chart: {
        height: 450,
        type: 'area',
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 2
    },
    grid: {
        strokeDashArray: 0
    }
}

const BiomassaGrafico = ({arquivos, showAll}) => {
    const theme = useTheme();

    const {primary, secondary} = theme.palette.text;
    const line = theme.palette.divider;

    const [options, setOptions] = useState(areaChartOptions);

    const [dataSorted, setDataSorted] = useState(
        (arquivos.sort((a, b) => new Date(a.dataCriacao) - new Date(b.dataCriacao))).slice(-8)
    )

    useEffect(() => {
        if (showAll)
            setDataSorted((arquivos.sort((a, b) => new Date(a.dataCriacao) - new Date(b.dataCriacao))))
        else
            setDataSorted((arquivos.sort((a, b) => new Date(a.dataCriacao) - new Date(b.dataCriacao))).slice(-12))
    }, [showAll])

    // const dataSorted = (arquivos.sort((a, b) => new Date(a.dataCriacao) - new Date(b.dataCriacao))).slice(-8)

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            colors: [theme.palette.primary.main, theme.palette.primary[700]],
            xaxis: {
                categories:
                    dataSorted.map(item => normalizeTimestemp(item.dataCriacao)),
                labels: {
                    style: {
                        colors: [
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary
                        ]
                    }
                },
                axisBorder: {
                    show: true,
                    color: line
                },
                tickAmount: dataSorted.length
            },
            yaxis: {
                min: 0, // Define o valor mínimo do eixo Y
                max: 100, // Define o valor máximo do eixo Y
                labels: {
                    style: {
                        colors: [secondary]
                    }
                }
            },
            grid: {
                borderColor: line
            },
            tooltip: {
                theme: 'light'
            }
        }));
    }, [primary, secondary, line, theme, showAll, dataSorted]);

    const [series, setSeries] = useState([
        {
            name: 'Biomassa',
            data: dataSorted.map(item => item.biomassa)
        }
    ]);

    useEffect(() => {
        setSeries([
            {
                name: 'Biomassa',
                data: dataSorted.map(item => item.biomassa + '%')
            }
        ]);
    }, [arquivos, showAll, dataSorted]);

    return <ReactApexChart options={options} series={series} type="area" height={450}/>;
};

BiomassaGrafico.propTypes = {
    slot: PropTypes.string
};

export default BiomassaGrafico;
