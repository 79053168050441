import Alert from "react-bootstrap/Alert";

export default function AlertMsg({message}) {


    return <Alert key='danger' variant='danger'>
        {message ?
            message :
            'Ocorreu um erro inesperado, tente novamente mais tarde'
        }
    </Alert>
}