export const numeroOrdemMask = value => {
    value = String(value); // Convertendo para string, se necessário
    if (!value) {
        return '';
    }
    let numWithZeros = value;
    while (numWithZeros.length < 8) {
        numWithZeros = '0' + numWithZeros;
    }
    return numWithZeros
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
}
