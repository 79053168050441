import {useState} from "react";
import ListCars from "./components/ListCars";
import ContentContainer from "../../component/ContentContainer";
import PaginationControl from "../../component/Pagination/PaginationControl";
import {ApiUrl} from "../../auth/authMethods";

const Cars = () => {

    const baseUrl = ApiUrl + `/car`

    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("id")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return (
        <>
            <ContentContainer width='xl'>
                <h1> Lista de CAR's </h1>
                {/*<PopupAddCar*/}
                {/*    setLoadData={setLoadData}*/}
                {/*/>*/}
                <ListCars
                    baseUrl={baseUrl}
                    loadData={loadData}
                    setLoadData={setLoadData}
                    page={page}
                    setPage={setPage}
                    size={size}
                    setSize={setSize}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    totalElements={totalElements}
                    setTotalElements={setTotalElements}
                    sort={sort} setSort={setSort}
                />

                <PaginationControl
                    totalElements={totalElements}
                    page={page}
                    setPage={setPage}
                    size={size}
                    setSize={setSize}
                    setLoadData={setLoadData}
                />
            </ContentContainer>
        </>
    )
}
export default Cars