import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CallIcon from '@mui/icons-material/Call';
import Grid from "@mui/material/Grid";
import InputPassword from "../../../component/InputPassword/InputPassword";
import ContentContainer from "../../../component/ContentContainer";
import LargeModal from "../../../component/LargeModal/LargeModal";
import React, {useState} from "react";
import UseTerms from "../../UseTerms/UseTerms";
import GoogleRecaptcha from "../../../recaptcha/GoogleRecaptcha";

export default function LoginForm({error, handleSubmit, loginLoading}) {

    const [showTermsUsePopup, setShowTermsUsePopup] = useState(false)
    const [captcha, setCaptcha] = useState('')

    const button = loginLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
            disabled={!captcha}
        >
            Entrar
        </Button>

    const linkLabel = <Link className="">termos de uso</Link>
    const useTermsLabel = <>
        <span>
            Li e concordo com os &nbsp;
        </span>
        <LargeModal
            lgShow={showTermsUsePopup} setLgShow={setShowTermsUsePopup}
            buttonClass='link-opacity-100' buttonText={linkLabel}
            title={'Termos de uso'}
            iconSpan={<AssignmentIcon fontSize="large" id="icon-svg"/>}
        >
            <UseTerms/>
        </LargeModal>
    </>

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",

                }}
            >
                <Typography component="h1" variant="h4" className='text-center font mt-2'>
                    {/*<AccountCircleIcon sx={{ fontSize: 80 }}/> <br/>*/}
                    Bem Vindo ao
                    <img
                        alt=""
                        src="/marca-verde.png"
                        width="180"
                        className="d-inline-block align-top"
                    />{' '}
                    <img src='/simbolo-verde.png' className='w-50 ' alt=''/><br/>
                </Typography>
                {error}
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Usuário ou email"
                        name="login"
                        type='text'
                        autoComplete="login"
                        variant='standard'
                        autoFocus
                    />
                    <InputPassword
                        id={'password'}
                        name={'password'}
                        label={'Senha'}
                    />

                    <FormControlLabel
                        required
                        control={<Checkbox/>}
                        label={useTermsLabel}
                    />
                    <br/>
                    {/*<FormControlLabel*/}
                    {/*    control={<Switch value="remember" color="primary"/>}*/}
                    {/*    label="Manter-me conectado"*/}
                    {/*    sx={{mt: 1}}*/}
                    {/*/>*/}
                    <GoogleRecaptcha captcha={captcha} setCaptcha={setCaptcha}/>
                    {button}
                    <div className="text-center mb-3">
                        <Link to='/forget-password'>
                            Esqueceu a senha?
                        </Link>
                    </div>
                    <div className="text-center ">
                        <span onClick={() => setShowTermsUsePopup(true)} className='btn btn-dark'>
                            <AssignmentIcon/>
                            Termos de uso
                        </span>
                        &nbsp;&nbsp;
                        <Link to='/contact-us' color='primary' className="btn btn-dark">
                            <CallIcon/>
                            Fale conosco
                        </Link>
                        {/*&nbsp;&nbsp;*/}
                        {/*<Link to='/admin' color='primary' className="btn btn-dark">*/}
                        {/*    <SupervisorAccountIcon/>*/}
                        {/*    Administrativo*/}
                        {/*</Link>*/}
                    </div>
                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ContentContainer>
    )
}