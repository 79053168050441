import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useUser} from "../../../auth/AuthProvider";

export default function ChangePasswordConfirmation({linkToLogin}) {

    const navigate = useNavigate()
    const {removeUser} = useUser();

    return <Box component="div" sx={{mt: 1}} className="text-center">
        <p style={{color: 'green'}}>

            <CheckCircleIcon sx={{
                fontSize: 40,
                height: '5vw',
                width: '5vw',
            }}/><br/>
            <h4>Senha alterada com sucesso!</h4>
        </p>

        {linkToLogin && <>
            <p>Senha alterada! Faça login para entrar</p>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => {
                    removeUser()
                    navigate('/login')
                }}
                sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
            >
                Ir para o login
            </Button>
        </>}
    </Box>

}