import {Switch} from "@mui/material";
import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";


export default function SwitchJobActive({item, setLoadData, baseUrl}){

    const {user} = useUser()


    const handleChange = () => {

        const headers = {
            'Authorization': `Bearer ${user?.token}`
        }

        axios.put(baseUrl + '/' + item.id, {ativo: !item.ativo}, {headers})
            .then(function (response) {
                setLoadData(true)
            })
            .catch(function (errorReceived) {
                setLoadData(true)
            });
    }

    return <Switch defaultChecked={item.ativo} color="success" onClick={handleChange}/>
} 