import React, {useState} from "react";
import InputCar from "./InputCar";
import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import * as PropTypes from "prop-types";
import {Description} from "@mui/icons-material";
import {ApiUrl} from "../../../auth/authMethods";


function TextInput(props) {
    return null;
}

TextInput.propTypes = {
    leading: PropTypes.func,
    label: PropTypes.string,
};

const PopupAddCar = ({setLoadData, cprId, setCprId}) => {

    const baseUrl = `${ApiUrl}/car`

    const [registro, setRegistro] = useState("");
    const [dataCadastro, setDataCadastro] = useState("");
    const [nomeImovelRural, setNomeImovelRural] = useState("");
    const [nomeProprietario, setNomeProprietario] = useState("");
    const [municipio, setMunicipio] = useState("");
    const [estado, setEstado] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [hectares, setHectares] = useState("");
    const [moduloFiscal, setModuloFiscal] = useState("");
    const [codigoProtocolo, setCodigoProtocolo] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");
    const [blockSubmit, setBlockSubmit] = useState(false);
    const [cidadeId, setCidadeId] = useState('')

    const icon = <Description fontSize="large" id="icon-svg" />;

    const title = "Registro do Imóvel Rural CAR";

    const button = <>
        <Description fontSize="medium"/>
        &nbsp; Adicionar CAR
    </>

    const clearForm = () => {
        setRegistro('')
        setDataCadastro('')
        setNomeProprietario('')
        setNomeImovelRural('')
        setMunicipio('')
        setEstado('')
        setLatitude('')
        setLongitude('')
        setHectares('')
        setModuloFiscal('')
        setCodigoProtocolo('')
        setErrorMessage('')
        setErrorColumn('')
        setBlockSubmit(false)
    }

    const returnData = () => {
        return {
            registro,
            dataCadastro,
            nomeProprietario,
            nomeImovelRural,
            estado,
            latitude,
            longitude,
            hectares,
            cidadeId: municipio,
            moduloFiscal,
            codigoProtocolo,
            cprId: cprId
        };
    };

    const inputs = (
        <InputCar
            setRegistro={setRegistro}
            registro={registro}
            dataCadastro={dataCadastro}
            setDataCadastro={setDataCadastro}
            nomeProprietario={nomeProprietario}
            setNomeProprietario={setNomeProprietario}
            setNomeImovelRural={setNomeImovelRural}
            nomeImovelRural={nomeImovelRural}
            setMunicipioId={setMunicipio}
            municipioId={municipio}
            setEstado={setEstado}
            estado={estado}
            setLatitude={setLatitude}
            latitude={latitude}
            setLongitude={setLongitude}
            longitude={longitude}
            setHectares={setHectares}
            hectares={hectares}
            setModuloFiscal={setModuloFiscal}
            moduloFiscal={moduloFiscal}
            setCodigoProtocolo={setCodigoProtocolo}
            codigoProtocolo={codigoProtocolo}
            cidadeId={cidadeId} setCidadeId={setCidadeId}
        />
    );

    return (
        <PopupAdd
            baseUrl={baseUrl}
            setLoadData={setLoadData}
            inputs={inputs}
            title={title}
            icon={icon}
            returnData={returnData}
            setErrorMessage={setErrorMessage}
            setErrorColumn={setErrorColumn}
            blockSubmit={blockSubmit}
            button={button}
            buttonClass='dropdown-item'
            clearForm={clearForm}
        />
    );
};

export default PopupAddCar;
