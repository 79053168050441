import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import JSZip from "jszip";
import { DOMParser } from 'xmldom';

const KMLUploader = ({onKMLUpload, error, setError, onFileSelect, calculatePolygonArea, file, setFile}) => {
    const dropzoneStyle = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0 10px 0',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '20px 0',
        color: '#787878',
    };

    const fileNameStyle = {
        border: '1px solid #cccccc',
        color: error ? 'red' : 'green',
        marginTop: '-6%',
        background: 'white',
        width: '50%',
        textAlign: 'center',
        marginInline: '25%'
    };


    const limparArquivo = () => {
        setFile(null);
    };

    const onDrop = (acceptedFiles) => {
        const reader = new FileReader();

        if (acceptedFiles.length !== 1) {
            setError("Por favor, selecione apenas um arquivo.");
            return;
        }

        const file = acceptedFiles[0];

        reader.onload = (e) => {
            try {
                const kmlContent = e.target.result;
                const kmlData = parseKML(kmlContent);
                onKMLUpload(kmlData);
                setError("");
            } catch (error) {
                setError('Erro ao processar o arquivo KML. \n' + error);
            }
        };

        // Checking if the dropped file is a KMZ
        const isKmz = file.name.toLowerCase().endsWith('.kmz');
        if (isKmz) {
            const zip = new JSZip();
            setFile(file.name);
            zip.loadAsync(file).then((zip) => {
                const kmlFile = Object.keys(zip.files).find((filename) =>
                    filename.toLowerCase().endsWith('.kml')
                );
                zip.files[kmlFile].async('string').then((kmlContent) => {
                    reader.readAsText(new Blob([kmlContent]));
                });
            });
        } else {
            // Not a KMZ, read as text directly
            setFile(file.name);
            reader.readAsText(file);
        }

        // Passando o estado do arquivo
        onFileSelect(file, limparArquivo);
    };

    const parsedCoordinates = (coordinatesElement) => {
        if (!coordinatesElement) {
            throw new Error('O arquivo KML não contém coordenadas válidas.')
        }

        const coordinatesString = coordinatesElement.textContent
            .replaceAll('\t', '')
            .replaceAll('\n', ' ')
            .trim()
        const coordinatesArray = coordinatesString.split(' ')

        const coordinates = []
        coordinatesArray.forEach((coord) => {
            const [lng, lat] = coord.split(',').map(parseFloat);
            if (lng && lat)
                coordinates.push({lat, lng})
            else if (lng)
                console.log(`Erro - possui lng mas não lat: ${lng} - ${lat}`)
            else if (lat)
                console.log(`Erro - possui lat mas não lng: ${lng} - ${lat}`)
        });

        // console.log(coordinates)

        const area = calculatePolygonArea(coordinates)

        return {coordinates, area}
    }

    const parseKML = (kmlContent) => {
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(kmlContent, 'text/xml')

        const coordinates = Array.from(
            xmlDoc.getElementsByTagName('coordinates')
        )

        if (coordinates.length < 1) {
            throw new Error('O arquivo KML não contém áreas ou está em um formato incorreto.')
        }

        console.log(coordinates.length)

        const listCoordinates = []
        coordinates.forEach(coordinate => {
            // console.log(parsedCoordinates(coordinate))
            listCoordinates.push(parsedCoordinates(coordinate))
        })

        return listCoordinates
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div>
            <div {...getRootProps()} style={dropzoneStyle}>
                <input {...getInputProps()} hidden accept='.kml,.kmz'/>
                {isDragActive ? (
                    <p>
                        <UploadFileIcon fontSize="medium"/> &nbsp; Solte o arquivo KML ou KMZ aqui...
                    </p>
                ) : (
                    <p>
                        <UploadFileIcon fontSize="medium"/> &nbsp; Selecione ou arraste seu arquivo KML ou KMZ aqui!
                    </p>
                )}
            </div>
            {file && (
                <p style={fileNameStyle}>
                    {error ? <ClearIcon fontSize="medium"/> : <CheckIcon fontSize="medium"/>}
                    Arquivo selecionado: {file}
                </p>
            )}
            {error && <p style={{color: 'red'}}>{error}</p>}
        </div>
    );
};

export default KMLUploader;
