import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from './AuthProvider';

export function PrivateRoute() {
    const { user } = useUser();

    // Se não houver usuário autenticado, redirecione para a página de notícias
    if (!user?.token) {
        return <Navigate to="/noticias" />;
    }

    // Se houver um usuário autenticado, renderize as rotas filhas
    return <Outlet />;
}
