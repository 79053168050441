import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LargeModal from "../LargeModal/LargeModal";
import { useUser } from "../../auth/AuthProvider";

const PopupDelete = ({
  buttonClass,
  baseUrl,
  setLoadData,
  title,
  id,
  buttonText,
  children,
}) => {
  const { user } = useUser();
  // modal control
  const [lgShow, setLgShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Adicionando estado de carregamento

  const iconSpan = (
    <>
      <DeleteForeverIcon fontSize="large" id="icon-svg" />
      {title}
    </>
  );

  const button = (
    <span className="link-danger">
      Deletar
      <DeleteForeverIcon fontSize="medium" />
    </span>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Ativar o estado de isLoading quando o formulário for enviado

    try {
      await axios.delete(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setLoadData(true);
      setLgShow(false);
      console.log(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Desativar o estado de isLoading após o término da solicitação
    }
  };

  return (
    <>
      {/* Botão e popup */}
      <LargeModal
        buttonClass={buttonClass ? buttonClass : "text-danger"}
        buttonText={buttonText ? buttonText : button}
        title={title}
        iconSpan={iconSpan}
        lgShow={lgShow}
        setLgShow={setLgShow}
        buttonId={"links-action"}
      >
        <div>
          <Form onSubmit={handleSubmit} className="form-inputs">
            {children}
            <Button
              type="submit"
              className={
                isLoading ? "btn btn-danger disabled" : "btn btn-danger"
              }
              disabled={isLoading}
            >
              {isLoading ? "Deletando..." : "Confirmar"}
            </Button>
          </Form>
        </div>
      </LargeModal>
    </>
  );
};

export default PopupDelete;
