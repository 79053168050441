import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {useState} from "react";
import ListEstados from "./components/ListEstados";
import PopupAddEstado from "./components/PopupAddEstado";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";

export default function Estados() {

    const {user} = useUser()

    const baseUrl = `${ApiUrl}/estado`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("id")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return <>
        <ContentContainer width='xl'>

            <h1>Lista de Estados</h1>
            {user.isMaster &&
                <PopupAddEstado baseUrl={baseUrl} setLoadData={setLoadData}/>
            }
            <ListEstados
                baseUrl={baseUrl}
                loadData={loadData}
                setLoadData={setLoadData}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                totalElements={totalElements}
                setTotalElements={setTotalElements}
                sort={sort} setSort={setSort}
            />
            <PaginationControl
                totalElements={totalElements}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                setLoadData={setLoadData}
            />

        </ContentContainer>
    </>
}