import React, {useState} from "react";
import FilterForm from "../../../component/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import {Divider, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import {cnpjMask} from "../../../utils/cnpjMask";
import SelectInput from "../../../component/SelectInput";
import applyRegex from "../../../utils/ApplyRegex";
import Typography from "@mui/material/Typography";
import {cpfMask} from "../../../utils/cpfMask";
import {senderNameFormatter} from "../../../utils/senderNameFormatter/senderNameFormatter.js";
import InputMask from "react-input-mask";

const FilterFormCprs = ({baseUrl, filter, setFilter, setLoadData, setPage}) => {

    const [lgShow, setLgShow] = useState(false);

    const [numeroOrdem, setNumeroOrdem] = useState("");
    const [dataVencimentoCedula, setDataVencimentoCedula] = useState("");
    const [dataEmissaoCedula, setDataEmissaoCedula] = useState("");
    const [produto, setProduto] = useState("");
    const [produtoList, setProdutoList] = useState("");
    const [cidadeList, setCidadeList] = useState("");
    const [cidadeId, setCidadeId] = useState("");
    const [coordenadas, setCoordenadas] = useState("")
    const [credorCnpj, setCredorCnpj] = useState("");
    const [emitenteCnpj, setEmitenteCnpj] = useState("");
    const [emitenteCpf, setEmitenteCpf] = useState("");
    const [credorCpf, setCredorCpf] = useState("");
    const [credorNome, setCredorNome] = useState("")
    const [emitenteNome, setEmitenteNome] = useState("")

    const [isCnpj, setIsCnpj] = useState();
    const [isCnpj2, setIsCnpj2] = useState();

    const toggleSwitch = () => {
        setIsCnpj((prev) => !prev);
    };
    const toggleSwitch2 = () => {
        setIsCnpj2((prev) => !prev);
    };


    const handlesSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        // Crie um objeto para armazenar os campos não nulos
        const filterData = {};

        // Verifique cada campo e adicione ao objeto se não for nulo
        if (numeroOrdem !== "") {
            filterData.numeroOrdem = numeroOrdem.replace(/[^\w\s]/gi, '')
        }

        if (dataVencimentoCedula !== "") {
            filterData.dataVencimentoCedula = dataVencimentoCedula;
        }

        if (dataEmissaoCedula !== "") {
            filterData.dataEmissaoCedula = dataEmissaoCedula;
        }

        if (coordenadas !== "") {
            filterData.coordenadas = coordenadas;
        }

        if (cidadeId !== "") {
            filterData.cidadeId = cidadeId;
        }

        if (produto !== "") {
            filterData.produto = produto;
        }

        if (credorNome !== "") {
            filterData.credorNome = credorNome;
        }

        if (emitenteNome !== "") {
            filterData.emitenteNome = senderNameFormatter(emitenteNome);
        }

        if (credorCnpj !== "") {
            filterData.credorCnpj = credorCnpj;
        }

        if (emitenteCnpj !== "") {
            filterData.emitenteCnpj = emitenteCnpj;
        }

        if (emitenteCpf!== "") {
            filterData.emitenteCpf = emitenteCpf;
        }
        if (credorCpf!== "") {
            filterData.credorCpf = credorCpf;
        }

        // Defina o filtro com os campos não nulos
        setFilter(filterData);

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNumeroOrdem("")
        setDataVencimentoCedula("")
        setDataEmissaoCedula("")
        setCidadeId("")
        setProduto("")
        setCredorCnpj("")
        setCredorCpf("")
        setEmitenteCnpj("")
        setEmitenteCpf("")
        setCoordenadas("")
        setEmitenteNome("")
        setCredorNome("")


        setFilter({})

        setLgShow(false)
        setLoadData(true)
    }


    return(
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Número Ordem"
                inputProps={{
                    maxLength: 11
                }}
                error={numeroOrdem.length > 11}
                helperText={numeroOrdem.length > 11 ? 'Limite de 11 caracteres excedido' : ''}
                errortext="Campo obrigatório!"
                value={numeroOrdem}
                onChange={(e) => {
                    // Remover caracteres não numéricos
                    const numericValue = e.target.value.replace(/[^\d]/g, '');

                    // Atualizar o estado apenas com os números
                    if (numericValue.length <= 9) {
                        setNumeroOrdem(numericValue);
                    }
                }}
                fullWidth
                autoFocus
            />

            <TextField
                label="Data de Vencimento"
                type="date"
                errortext="Campo obrigatório!"
                value={dataVencimentoCedula}
                onChange={(e) => setDataVencimentoCedula(e.target.value)}
                fullWidth
                InputLabelProps={{shrink: true}} //mantem o texto encima
                inputProps={{
                    min: "1970-01-01",
                    max: "2100-12-31"
                }}
            />
            <TextField
                label="Data de Emissão"
                type="date"
                value={dataEmissaoCedula}
                onChange={(e) => setDataEmissaoCedula(e.target.value)}
                fullWidth
                InputLabelProps={{shrink: true}} //mantem o texto encima
                inputProps={{
                    min: "1970-01-01",
                    max: "2100-12-31"
                }}
            />
            <SelectInput
                url={baseUrl + '/cidade?size=20000'}
                list={cidadeList}
                set={setCidadeList}
                setObj={setCidadeId}
                defaultValue={cidadeId}
                returnList={() => {
                    let returnList = []
                    cidadeList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nome} - ${item.estado.sigla}`})
                    })
                    return returnList
                }}
                placeholder={"Local de Formação do Produto"}
                fullWidth
            />
            <SelectInput
                url={`${baseUrl}/produto?size=2000`}
                placeholder={"Tipo de Produto"}
                list={produtoList}
                set={setProdutoList}
                setObj={setProduto}
                defaultValue={produto}
                returnList={() => {
                    let returnList = []
                    returnList.push({value: "", label: "Todos"})
                    produtoList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.tipo} - ${item.variedade}`})
                    })
                    return returnList
                }}
                fullWidth
            />
            <TextField
                label="Coordenadas"
                fullWidth
                value={coordenadas}
                erortext="Campo obrigatório!"
                onChange={(e) => setCoordenadas(e.target.value.replaceAll('_', ''))}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />
            <Divider/>
            <Box mb={0.5} pb={0.5}>
                Emitente
            </Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                    {isCnpj ? 'CNPJ' : 'CPF'}
                </Typography>
                <Switch
                    checked={isCnpj}
                    onChange={toggleSwitch}
                    color="primary"
                    inputProps={{ 'aria-label': 'alternar entre CNPJ e CPF' }}
                />
            </div>
            {isCnpj ? (
                <TextField
                    label="CNPJ"
                    inputProps={{
                    }}
                    value={cnpjMask(emitenteCnpj)}
                    onChange={(e) => setEmitenteCnpj(e.target.value
                        .replaceAll('.', '')
                        .replaceAll('-', '')
                        .replaceAll('/', '')
                    )}
                    id="input-cnpj"
                    fullWidth
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                />
            ) : (
                <TextField
                    label="CPF"
                    inputProps={{
                    }}
                    value={cpfMask(emitenteCpf)}
                    onChange={(e) => setEmitenteCpf(e.target.value
                    )}

                    id="input-cpf"
                    fullWidth
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                />
            )}
            <TextField
                label="Nome do Emitente"
                inputProps={{
                    maxLength: 80
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={emitenteNome}
                onChange={(e) => {
                    if (e.target.value.length <= 80) {
                        setEmitenteNome(e.target.value)
                    }
                }}
                fullWidth
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />
            <Divider/>
            <Box mb={0.5} pb={0.5}>
                Credor
            </Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                    {isCnpj2 ? 'CNPJ' : 'CPF'}
                </Typography>
                <Switch
                    checked={isCnpj2}
                    onChange={toggleSwitch2}
                    color="primary"
                    inputProps={{ 'aria-label': 'alternar entre CNPJ e CPF' }}
                />
            </div>
            {isCnpj2 ? (
                <TextField
                    label="CNPJ"
                    inputProps={{
                    }}
                    value={cnpjMask(credorCnpj)}
                    onChange={(e) => setCredorCnpj(e.target.value
                        .replaceAll('.', '')
                        .replaceAll('-', '')
                        .replaceAll('/', '')
                    )}
                    id="input-cnpj"
                    fullWidth
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                />
            ) : (
                <TextField
                    label="CPF"
                    inputProps={{
                    }}
                    value={cpfMask(credorCpf)}
                    onChange={(e) => setCredorCpf(e.target.value
                    )}

                    id="input-cpf"
                    fullWidth
                />
            )}
            <TextField
                label="Nome do Credor"
                inputProps={{
                    maxLength: 80
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={credorNome}
                onChange={(e) => {
                    if (e.target.value.length <= 80) {
                        setCredorNome(e.target.value)
                    }
                }}
                fullWidth
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />

        </FilterForm>
    )

}
export default FilterFormCprs