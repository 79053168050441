import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const PopupDeleteProduto = ({
                                         baseUrl,
                                         setLoadData,
                                         item
                                     }) => {
    const buttonText = <span className="link-danger">
        <DeleteForeverIcon fontSize="medium"/>
        &nbsp;Deletar
    </span>

    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão do Estado"} id={item.id}
            buttonClass={'dropdown-item text-danger'}
            buttonText={buttonText}
        >

            <div className="mt-3 mb-3">
                <b>Tipo do Produto: </b>
                <>{item.tipo}</>
                <br/>
                <b>Variedade do Produto: </b>
                <>{item.variedade}</>
                <br/>
                <b>Características do Produto: </b>
                <>{item.caracteristicas && <span style={{ maxWidth: '100px', wordWrap: 'break-word' }}>{item.caracteristicas}</span>}</>
                <br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir este produto?</p>

        </PopupDelete>
    )
}
export default PopupDeleteProduto