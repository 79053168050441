import React from 'react';
import Modal from 'react-bootstrap/Modal';

import "./Popup.css"

const Popup = ({
                   lgShow, setLgShow,
                   buttonText, buttonClass,
                   buttonId, title, iconSpan,
                   children,
                   backdrop, hideCloseButton,
                   modalClass, additionalComponent,
                   centered, highlighted
               }) => {

    return (
        <>
            <span onClick={() => setLgShow(true)} className={buttonClass ? buttonClass : 'btn btn-primary'}
                  id={buttonId} style={{cursor: 'pointer'}}>
                {buttonText}
            </span>

            {additionalComponent}

            <Modal
                className={modalClass ? modalClass : "modal-xl"}
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop={backdrop}
                centered={centered}
                style={{ backgroundColor: highlighted ? 'rgba(0, 0, 0, 0.6)' : '' }}
            >
                <Modal.Header closeButton={!hideCloseButton}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row contact-info">
                        <div className="col-md-4"></div>
                        <div className="icon-span col-md-4">
                            {iconSpan}
                        </div>
                        <div className="col-md-4"></div>
                    </div>


                    {children}

                </Modal.Body>
            </Modal>
        </>
    );
}

export default Popup