import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import LargeModal from "../../../component/LargeModal/LargeModal";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import GrassIcon from '@mui/icons-material/Grass';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DescriptionIcon from '@mui/icons-material/Description';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import axios from "axios";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import {useUser} from "../../../auth/AuthProvider";

export default function PopupProdutoDetails({item, baseUrl, setLoadData}) {

    const {user} = useUser()

    const [lgShow, setLgShow] = useState(false);
    const [features, setFeatures] = useState([]);
    const [needToReload, setNeedToReload] = useState(false);
    const [hectares, setHectares] = useState([]);

    useEffect(() => {
        if (!lgShow && needToReload)
            setLoadData(true)
    }, [lgShow])

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${user?.token}`
        }
        axios.get(`${baseUrl}/${item.id}`, {headers})
            .then(function (response) {

                // Mapeie os objetos conteudo para uma nova lista
                const conteudos = response.data.map(item => ({
                    ...JSON.parse(item.conteudo),
                    id: item.id,
                    hectares: item.hectares
                }));
                setFeatures(conteudos);
            })
            .catch(function (errorReceived) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(errorReceived);
            });
    }, []);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Produto
    </>

    const title = "Detalhes do Produto"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    return (
        <LargeModal
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
            {needToReload ?
                <DefaultSuccessMessage message='O campo excluído com sucesso!'/> :
                <>
                    <List
                        sx={{width: '80%', bgcolor: 'background.paper', marginInline: '10%'}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className='pointer-event'
                    >

                        {/* Produto */}
                        <DisplayDetailsData
                            openDetails={true}
                            key="productData" // Adicione uma chave única aqui
                            title='Produto'
                            itens={[
                                {
                                    icon: <GrassIcon/>,
                                    display: `Tipo:  ${item.tipo}`
                                },
                                {
                                    icon: <PlaylistAddCheckIcon/>,
                                    display: `Variedade:  ${item.variedade}`
                                },
                                {
                                    icon: <ThunderstormIcon/>,
                                    display: `Pluviometricidade Miníma:  ${item.pluviometricidadeMinima+"mm"}`
                                },
                                {
                                    icon: <ThunderstormIcon/>,
                                    display: `Pluviometricidade Máxima:  ${item.pluviometricidadeMaxima+"mm"}`
                                },
                                {
                                    icon: <DeviceThermostatIcon/>,
                                    display: `Temperatura Miníma:  ${item.temperaturaMinima+"°C"}`
                                },
                                {
                                    icon: <DeviceThermostatIcon/>,
                                    display: `Temperatura Máxima:  ${item.temperaturaMaxima+"°C"}`
                                },
                                {
                                    icon: <DescriptionIcon/>,
                                    display: `Características:  ${item.caracteristicas}`
                                }
                            ]}
                        />

                    </List>
                    <hr style={{marginInline: '10%'}}/>
                </>
            }
        </LargeModal>
    )
}