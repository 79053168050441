import PropTypes from 'prop-types';

// material-ui
import {Box, Chip, Grid, Stack, Typography} from '@mui/material';

// project import
import MainCard from '../MainCard';

// assets
import {RiseOutlined, FallOutlined} from '@ant-design/icons';

import CheckIcon from '@mui/icons-material/Check';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticEcommerce = ({
                               color,
                               color2,
                               color3,
                               title,
                               title2,
                               title3,
                               count,
                               count2,
                               count3,
                               percentage,
                               percentage2,
                               percentage3,
                               isLoss,
                               isLoss2,
                               isLoss3,
                               iconTotal
                           }) => (
    <MainCard contentSX={{p: 2.5}}>
        <Grid container spacing={5}>

            <Grid item xs={4}>
                <Stack spacing={2}>
                    <Typography variant="h6" color="textSecondary" align="center">
                        {title}
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant="h4" color="inherit" align="center">
                            {count}
                        </Typography>
                        {iconTotal && (
                            <Grid item>
                                <Chip
                                    variant="combined"
                                    style={{backgroundColor: '#77dd77', color: '#FFFFFF'}}
                                    icon={
                                        <>
                                            <CheckIcon style={{color: 'white'}}/>
                                            <RiseOutlined style={{fontSize: '1rem', color: 'inherit'}}/>
                                        </>
                                    }
                                    sx={{ml: 1.25, pl: 2}}
                                    size="medium"
                                />
                            </Grid>
                        )}
                    </Box>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack spacing={2}>
                    <Typography variant="h6" color="textSecondary" align="center">
                        {title2}
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant="h4" color="inherit" align="center">
                            {count2}
                        </Typography>
                        {percentage2 !== null && percentage2 !== undefined && (
                            <Grid item>
                                <Chip
                                    variant="combined"
                                    color="primary"
                                    icon={
                                        <>
                                            {!isLoss2 && <RiseOutlined style={{fontSize: '1rem', color: 'inherit'}}/>}
                                            {isLoss2 && <FallOutlined style={{fontSize: '1rem', color: 'inherit'}}/>}
                                        </>
                                    }
                                    label={`${percentage2.toFixed(1)}%`}
                                    sx={{ml: 1.25, pl: 1}}
                                    size="medium"
                                />
                            </Grid>
                        )}
                    </Box>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack spacing={2}>
                    <Typography variant="h6" color="textSecondary" align="center">
                        {title3}
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant="h4" color="inherit" align="center">
                            {count3}
                        </Typography>
                        {percentage3 !== null && percentage3 !== undefined && (
                            <Grid item>
                                <Chip
                                    variant="combined"
                                    color={color3}
                                    icon={
                                        <>
                                            {!isLoss3 && <RiseOutlined style={{fontSize: '1rem', color: 'inherit'}}/>}
                                            {isLoss3 && <FallOutlined style={{fontSize: '1rem', color: 'inherit'}}/>}
                                        </>
                                    }
                                    label={`${percentage3.toFixed(1)}%`}
                                    sx={{ml: 1.25, pl: 1}}
                                    size="medium"
                                />
                            </Grid>
                        )}
                    </Box>
                </Stack>
            </Grid>

        </Grid>
    </MainCard>
);

AnalyticEcommerce.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

AnalyticEcommerce.defaultProps = {
    color: 'primary'
};

export default AnalyticEcommerce;
