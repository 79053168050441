import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import React, {useState} from "react";
import InputsEstado from "./InputsEstado";
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';

export default function PopupAddEstado({baseUrl, setLoadData}) {

    const [nome, setNome] = useState('');
    const [sigla, setSigla] = useState('');


    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsEstado
        nome={nome} setNome={setNome}
        sigla={sigla} setSigla={setSigla}
        errorMessage={errorMessage} errorColumn={errorColumn}
    />

    const icon = <SouthAmericaIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setSigla('')
        setNome('')
    }

    const returnData = () => {
        return {
            nome,
            sigla
        }
    }

    return <PopupAdd
        baseUrl={baseUrl} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar Estado'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}