import TextField from "@mui/material/TextField";
import React, {useState} from "react";

export default function InputsMap({  nome, setNome,
                                         errorColumn, errorMessage
                                     }) {

    const sufixo = ".zip";

    const handleChange = (e) => {
        const novoValor = e.target.value + sufixo;
        setNome(novoValor);
    };

    return <>
        <TextField
            label="Nome do Campo"
            inputProps={{
                maxLength: 100
            }}
            value={nome ? nome.replace(sufixo, "") : ""}
            onChange={handleChange}
            fullWidth
            autoFocus
        />
    </>

}