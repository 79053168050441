import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputCar from "./InputCar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";


const PopupEditCar = ({item, baseUrl, setLoadData}) => {

    const [id, setId] = useState("")
    const [dataCadastro, setDataCadastro] = useState("")
    const [codigoProtocolo, setCodigoProtocolo] = useState("")
    const [hectares, setHectares] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [moduloFiscal, setModuloFiscal] = useState("")
    const [registro, setRegistro] = useState("")
    const [municipioId, setMunicipioId] = useState("")
    const [nomeProprietario, setNomeProprietario] = useState("")
    const [nomeImovelRural, setNomeImovelRural] = useState("")
    const [estado, setEstado] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const [blockSubmit, setBlockSubmit] = useState(false)

    const title = "Editar Car"

    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp;{title}
    </>

    const inputs = <InputCar
        estado={estado} setEstado={setEstado}
        codigoProtocolo={codigoProtocolo} setCodigoProtocolo={setCodigoProtocolo}
        dataCadastro={dataCadastro} setDataCadastro={setDataCadastro}
        hectares={hectares} setHectares={setHectares}
        latitude={latitude} setLatitude={setLatitude}
        longitude={longitude} setLongitude={setLongitude}
        moduloFiscal={moduloFiscal} setModuloFiscal={setModuloFiscal}
        municipioId={municipioId} setMunicipioId={setMunicipioId}
        nomeProprietario={nomeProprietario} setNomeProprietario={setNomeProprietario}
        nomeImovelRural={nomeImovelRural} setNomeImovelRural={setNomeImovelRural}
        registro={registro} setRegistro={setRegistro}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
    />

    const itemToState = () => {
        setId(item.id)
        setRegistro(item.registro)
        setDataCadastro(item.dataCadastro)
        setNomeProprietario(item.nomeProprietario)
        setNomeImovelRural(item.nomeImovelRural)
        setLatitude(item.latitude)
        setLongitude(item.longitude)
        setHectares(item.hectares)
        setMunicipioId(item.cidade.id)
        setModuloFiscal(item.moduloFiscal)
        setCodigoProtocolo(item.codigoProtocolo)
        setErrorMessage("")
        setErrorColumn("")
        setBlockSubmit(false)
    }


    const returnData = () => {

        console.log({
            id,
            registro,
            dataCadastro,
            nomeImovelRural,
            latitude,
            longitude,
            hectares,
            cidadeId: municipioId,
            moduloFiscal,
            codigoProtocolo
        })

        return {
            registro,
            dataCadastro,
            nomeProprietario,
            nomeImovelRural,
            latitude,
            longitude,
            hectares,
            cidadeId: municipioId,
            moduloFiscal,
            codigoProtocolo
        }
    }

    return(
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + id} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                buttonClass='dropdown-item' buttonText={buttonText}
            />
        </>
    )
}
export default PopupEditCar