export function numberWithPoints(number, decimalPlaces = 1) {
    const roundedNumber = parseFloat(number).toFixed(decimalPlaces);
    const [integerPart, decimalPart] = roundedNumber.split('.');

    // Se não houver parte decimal, retorne apenas a parte inteira
    if (!decimalPart) {
        return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    // Ajustar a parte decimal para ter apenas uma casa decimal
    const adjustedDecimalPart = decimalPart.slice(0, 3);

    // Caso contrário, retorne a parte inteira com a parte decimal ajustada
    const formattedNumber = `${integerPart},${adjustedDecimalPart}`;

    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
