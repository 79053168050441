import {useState} from "react";
import ContentContainer from "../../component/ContentContainer";
import PaginationControl from "../../component/Pagination/PaginationControl";
import ListCalendarioPlantio from "./components/ListCalendarioPlantio";
import PopupAddCalendarioPlantio from "./components/PopupAddCalendarioPlantio";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";


export default function CalendarioPlantio() {

    const {user} = useUser()

    const baseUrl = `${ApiUrl}/calendario`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(100)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState("")
    const [totalElements, setTotalElements] = useState(10)

    return <>
        <ContentContainer width='xl'>
            <h1>Calendário de Plantio</h1>
            {user.isMaster &&
                <PopupAddCalendarioPlantio baseUrl={baseUrl} setLoadData={setLoadData}/>
            }
            <ListCalendarioPlantio
                setLoadData={setLoadData}
                baseUrl={baseUrl}
                loadData={loadData}
                page={page}
                setPage={setPage}
                size={size}
                setTotalPages={setTotalPages}
                setSort={setSort}
                sort={sort}
                setTotalElements={setTotalElements}
            />
            <PaginationControl
                totalElements={totalElements}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                setLoadData={setLoadData}
                rowsPerPageOptions={[100, 200, 500, 1000]}
            />
        </ContentContainer>


    </>

}