import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import SwitchJobActive from "./SwitchJobActive";
import TriggerJob from "./TriggerJob";

export default function JobManageList({
                                          baseUrl, loadData,
                                          setLoadData, size, page,
                                          setTotalPages, setTotalElements,
                                          sort, setSort, updateCode, setPage
                                      }) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    const titles = [
        'Id',
        'Nome',
        'Descrição',
        'Ativo'
    ]

    const readData = (resp) => {
        return resp.data
    }

    return (
        <>
            <TableList
                titles={titles} baseUrl={baseUrl + '/list'}
                page={page} sort={sort} size={size}
                loadData={loadData} readData={readData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={{}}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.nome}</td>
                        <td>{item.descricao}</td>
                        <td>
                            <SwitchJobActive item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
                        </td>
                        <td>
                            <TriggerJob item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}