import React, {useState} from "react";
import InputsCalendarioPlantio from "./InputsCalendarioPlantio";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PopupAdd from "../../../component/PopupAdd/PopupAdd";


export default function PopupAddCalendarioPlantio({baseUrl, setLoadData}) {


    const [ano, setAno] = useState('');
    const [regiao, setRegiao] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [tipo, setTipo] = useState('');
    const [produtoId, setProdutoId] = useState('');

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsCalendarioPlantio
        baseUrl={baseUrl}
        ano={ano} setAno={setAno}
        regiao={regiao} setRegiao={setRegiao}
        dataInicial={dataInicial} setDataInicial={setDataInicial}
        dataFinal={dataFinal} setDataFinal={setDataFinal}
        tipo={tipo} setTipo={setTipo}
        produtoId={produtoId} setProdutoId={setProdutoId}
        errorMessage={errorMessage} errorColumn={errorColumn}
    />

    const icon = <EventRepeatIcon fontSize="large" id="icon-svg"/>


    const clearForm = () => {
        setAno('')
        setRegiao('')
        setDataInicial('')
        setDataFinal('')
        setTipo('')
        setProdutoId('')
        setErrorMessage(false)
        setErrorColumn(false)
    }

    const returnData = () => {
        console.log({
            ano,
            regiao,
            dataInicial,
            dataFinal,
            tipo,
            produtoId
        })
        return {
            ano,
            regiao,
            dataInicial,
            dataFinal,
            tipo,
            produtoId
        }
    }
    return <PopupAdd
        baseUrl={baseUrl} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar um Calendario de Plantio'} icon={icon}
        returnData={returnData}
        clearForm={clearForm} ano={ano} regiao={regiao} dataInicial={dataInicial}
        dataFinal={dataFinal} tipo={tipo}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}