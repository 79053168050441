import TextField from "@mui/material/TextField";
import React from "react";
import applyRegex from "../../../utils/ApplyRegex";
import InputAdornment from "@mui/material/InputAdornment";

export default function InputsProduto({
                                          tipo, setTipo,
                                          variedade, setVariedade,
                                          caracteristicas, setCaracteristicas,
                                          pluviometricidadeMaxima, setPluviometricidadeMaxima,
                                          pluviometricidadeMinima, setPluviometricidadeMinima,
                                          temperaturaMaxima, setTemperaturaMaxima,
                                          temperaturaMinima, setTemperaturaMinima,
                                          errorColumn, errorMessage,
                                      }) {

    return <>
        <TextField
            label="Tipo"
            inputProps={{
                maxLength: 25
            }}
            error={errorColumn === 'tipo'}
            helperText={errorColumn === 'tipo' ? errorMessage : ''}
            value={tipo}
            onChange={(e) => {
                setTipo(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñÃÕ ])/g))
            }}
            required
            fullWidth
            autoFocus
        />

        <TextField
            label="Variedade"
            inputProps={{
                maxLength: 25
            }}
            error={errorColumn === 'variedade'}
            helperText={errorColumn === 'variedade' ? errorMessage : ''}
            value={variedade}
            onChange={(e) => {
                setVariedade(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ0-9 -])/g))
            }}
            required
            fullWidth
        />

        <TextField
            label="Pluviometricidade Mínima"
            required={true}
            inputProps={{
                maxLength: 5
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={pluviometricidadeMinima}
            onChange={(e) =>{
                if (e.target.value.length <= 5) {
                    setPluviometricidadeMinima(e.target.value);
                }
            }}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">mm(milímetros)</InputAdornment>,
            }}
        />

        <TextField
            label="Pluviometricidade Máxima"
            required={true}
            inputProps={{
                maxLength: 5
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={pluviometricidadeMaxima}
            onChange={(e) =>{
                if (e.target.value.length <= 5) {
                    setPluviometricidadeMaxima(e.target.value);
                }
            }}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">mm(milímetros)</InputAdornment>,
            }}
        />

        <TextField
            label="Temperatura Mínima"
            required={true}
            inputProps={{
                maxLength: 4
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={temperaturaMinima}
            onChange={(e) =>{
                if (e.target.value.length <= 4) {
                    setTemperaturaMinima(e.target.value);
                }
            }}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
        />

        <TextField
            label="Temperatura Máxima"
            required={true}
            inputProps={{
                maxLength: 4
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={temperaturaMaxima}
            onChange={(e) =>{
                if (e.target.value.length <= 4) {
                    setTemperaturaMaxima(e.target.value);
                }
            }}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
        />



        <div className="form-floating mb-2">
                <textarea className="form-control" placeholder=""
                          id="floatingTextarea" required value={caracteristicas}
                          maxLength={255}
                          onChange={(e) => setCaracteristicas(e.target.value)}>
                </textarea>
            <label htmlFor="floatingTextarea">Características do produto</label>
        </div>
    </>

}