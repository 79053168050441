import React, {useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LargeModal from "../../../component/LargeModal/LargeModal";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import Person4Icon from '@mui/icons-material/Person4';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {aliasRoles} from "./aliasRoles";


export default function UserDetails({item}) {

    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Usuário
    </>

    const title = "Detalhes do Usuário"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>


    return (
        <LargeModal
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
            {/* Dados primários */}
            <DisplayDetailsData
                key="primaryData" // Adicione uma chave única aqui
                title='Dados primários'
                itens={[
                    {
                        icon: <PersonIcon key="item1"/>,
                        display: `Nome do Usuário  ${item.username}`
                    },
                    {
                        icon: <EmailIcon key="item2"/>,
                        display: `Email do Usuário:  ${item.email}`
                    },
                ]}
            />
            {/* Dados Secundários */}
            <DisplayDetailsData
                key="secondData" // Adicione uma chave única aqui
                title='Dados Secundários'
                itens={[
                    {
                        icon: <Person4Icon key="item3"/>,
                        display: `Tipo de Usuário:  ${aliasRoles.find(role => role.received === item.roles[0].name).display}`
                    },
                    {
                        icon: <AutorenewIcon key="item4"/>,
                        display: `Status do Usuário:  ${item.isEnabled ? 'Ativo' : 'Inativo'}`
                    },
                    {
                        icon: <RecentActorsIcon key="item4"/>,
                        display: `Credencial do usuário:  ${item.isCredentialsNonExpired ? 'Ativa' : 'Inativa'}`
                    },
                ]}
            />
        </LargeModal>
    )


}

