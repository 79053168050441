import { useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HelpIcon from "@mui/icons-material/Help";
import "./index.css";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import OrdersTable from "./OrdersTable";
import IncomeAreaChart from "./IncomeAreaChart";
import PieChart from "./PieChart";
import MonthlyBarChart from "./MonthlyBarChart";
import ReportAreaChart from "./ReportAreaChart";
import SalesColumnChart from "./SalesColumnChart";
import MainCard from "../MainCard";

import {
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import avatar1 from "../../assets/images/users/avatar-1.png";
import avatar2 from "../../assets/images/users/avatar-2.png";
import avatar3 from "../../assets/images/users/avatar-3.png";
import avatar4 from "../../assets/images/users/avatar-4.png";
import AnalyticEcommerce from "../AnalyticsEcommerce";
import axios from "axios";
import { ApiUrl } from "../../auth/authMethods";
import { useUser } from "../../auth/AuthProvider";
import { numeroOrdemMask } from "../../utils/numeroOrdemMask";
import BasicLoader from "../BasicLoader/BasicLoader";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];

function createData(trackingNo, name, fat, carbs, protein) {
  return { trackingNo, name, fat, carbs, protein };
}
function createDataCprsEmFinalizacao(
  numeroOrdem,
  emitentes,
  // coordenadas((Colocar Nome Campo),),
  dataEmissaoCedula,
  dataVencimentoCedula
) {
  return {
    numeroOrdem: numeroOrdemMask(numeroOrdem.toString()),
    emitentes,
    // coordenadas(Colocar Nome Campo),
    dataEmissaoCedula,
    dataVencimentoCedula,
  };
}

const rows = [
  createData(84564564, "Camera Lens", 40, 2, 40570),
  createData(98764564, "Laptop", 300, 0, 180139),
  createData(98756325, "Mobile", 355, 1, 90989),
  createData(98652366, "Handset", 50, 1, 10239),
  createData(13286564, "Computer Accessories", 100, 1, 83348),
  createData(86739658, "TV", 99, 0, 410780),
  createData(13256498, "Keyboard", 125, 2, 70999),
  createData(98753263, "Mouse", 89, 2, 10570),
  createData(98753275, "Desktop", 185, 1, 98063),
  createData(98753291, "Chair", 100, 0, 14001),
];

const cprs = [
  createData(84564564, "Camera Lens", 40, 2, 40570),
  createData(98764564, "Laptop", 300, 0, 180139),
  createData(98756325, "Mobile", 355, 1, 90989),
  createData(98652366, "Handset", 50, 1, 10239),
  createData(13286564, "Computer Accessories", 100, 1, 83348),
  createData(86739658, "TV", 99, 0, 410780),
  createData(13256498, "Keyboard", 125, 2, 70999),
];

// ==============================|| DASHBOARD ||============================== //

const DashboardDefault = () => {
  const { user } = useUser();

  const baseUrlDadosAtivos = `${ApiUrl}/dashboard/dados-ativos`;
  const baseUrlCprsEmFinalizacao = `${ApiUrl}/dashboard/cprs-em-finalizacao`;
  const baseUrlCampoCpr = `${ApiUrl}/arquivos/cpr`;

  const [value, setValue] = useState("today");
  const [slot, setSlot] = useState("week");
  const [dadosAtivos, setDadosAtivos] = useState([]);
  const [cprsEmFinalizacao, setCprsEmFinalizacao] = useState([]);
  const [status, setStatus] = useState("risco");



  const RenderTooltipRisco = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        O status 'RISCO' exibe os campos que estão em periodo de plantio
        e todos os dias que estão no intervalo de dias do alarme estão com
        quedas.
      </Tooltip>
  );

  const iconRisk = (
    <>
      Campos em Risco
        <ErrorIcon
          className="iconTitle"
          style={{ color: "rgb(255, 105, 97)" }}
        />
      <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={RenderTooltipRisco}
      >
        <HelpIcon fontSize="small" style={{ color: "rgb(255, 105, 97)" }}/>
      </OverlayTrigger>
    </>
  );

  const RenderTooltipEstavel = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        O status 'ESTAVEL' exibe os campos que estão em periodo de plantio
        e todos os dias que estão no intervalo de dias do alarme estão sem
        problemas.
      </Tooltip>
  );

  const iconStable = (
    <>
      Campos Estáveis
        <CheckBoxIcon
          className="iconTitle"
          style={{ color: "rgb(119, 221, 119)" }}
        />
      <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={RenderTooltipEstavel}
      >
        <HelpIcon fontSize="small" style={{ color: "rgb(119, 221, 119)" }}/>
      </OverlayTrigger>
    </>
  );

  const iconAlert = (
    <>
      Campos em Alerta
      {
        <WarningIcon
          className="iconTitle"
          style={{ color: "rgb(233, 220, 35)" }}
        />
      }
    </>
  );

  const RenderTooltipNeutro = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        O status 'NEUTRO' exibe os campos que estão fora de plantio, ou seja,
        estão em colheita ou nenhuma ação está acontecendo na plantação.
      </Tooltip>
  );

  const iconNeutro = (
    <>
      Campos Neutros
      <VerifiedUserIcon
          className="iconTitle"
          style={{ color: "rgb(128, 128, 128)" }}
      />
      <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={RenderTooltipNeutro}
      >
        <HelpIcon fontSize="small" style={{ color: "rgb(128, 128, 128)" }}/>
      </OverlayTrigger>
    </>
  );

  const [cprData, setCprData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrlDadosAtivos, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (!response.data) {
          throw new Error("Erro ao obter dados ativos");
        }

        setDadosAtivos(response.data);
      } catch (error) {
        console.error("Erro:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCprsEmFinalizacao = async () => {
      try {
        const response = await axios.get(baseUrlCprsEmFinalizacao, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (!response.data || !response.data.cprsEmFinalizacao) {
          throw new Error("Erro ao obter CPRs em finalização");
        }

        const dadosFormatados = await Promise.all(
          response.data.cprsEmFinalizacao.map(async (cpr) => {
            const {
              numeroOrdem,
              emitentes,
              // coordenadas,
              dataEmissaoCedula,
              dataVencimentoCedula,
            } = cpr;

            const nomeEmitente =
              cpr.emitentes.length > 0 ? cpr.emitentes[0].nome : "";

            return createDataCprsEmFinalizacao(
              cpr.numeroOrdem,
              nomeEmitente,
              cpr.dataEmissaoCedula,
              cpr.dataVencimentoCedula
            );
          })
        );

        setCprsEmFinalizacao(dadosFormatados);
      } catch (error) {
        console.error("Erro:", error);
      }
    };

    fetchCprsEmFinalizacao();
  }, []);

  const porcentagemAtivaCpr =
    (dadosAtivos.cprsAtivas /
      (dadosAtivos.cprsAtivas + dadosAtivos.cprsInativas)) *
    100;
  const porcentagemInativaCpr =
    (dadosAtivos.cprsInativas /
      (dadosAtivos.cprsAtivas + dadosAtivos.cprsInativas)) *
    100;
  const porcentagemAtivaCampos =
    (dadosAtivos.camposAtivos /
      (dadosAtivos.camposAtivos + dadosAtivos.camposInativos)) *
    100;
  const porcentagemInativaCampos =
    (dadosAtivos.camposInativos /
      (dadosAtivos.camposAtivos + dadosAtivos.camposInativos)) *
    100;

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 0 */}
      {/*<Grid item xs={12} sx={{ mb: -2.25 }}>*/}
      {/*  <Typography variant="h5">Cpr</Typography>*/}
      {/*</Grid>*/}
      <Grid item xs={12} sm={6} md={4} lg={6}>
        <Typography variant="h5" align="center">
          CPRs
        </Typography>
        <AnalyticEcommerce
          title="Total"
          count={
            dadosAtivos.cprsAtivas + dadosAtivos.cprsInativas < 1 ? (
              "0"
            ) : dadosAtivos.cprsAtivas + dadosAtivos.cprsInativas ? (
              dadosAtivos.cprsAtivas + dadosAtivos.cprsInativas
            ) : (
              <CircularProgress />
            )
          }
          iconTotal={dadosAtivos.cprsAtivas ? true : ""}
          title2="CPRs Ativas"
          count2={
            dadosAtivos.cprsAtivas < 1 ? (
              "0"
            ) : dadosAtivos.cprsAtivas ? (
              dadosAtivos.cprsAtivas
            ) : (
              <CircularProgress />
            )
          }
          percentage2={porcentagemAtivaCpr ? porcentagemAtivaCpr : 0}
          title3="CPRs Inativas"
          count3={
            dadosAtivos.cprsInativas < 1 ? (
              "0"
            ) : dadosAtivos.cprsInativas ? (
              dadosAtivos.cprsInativas
            ) : (
              <CircularProgress />
            )
          }
          percentage3={porcentagemInativaCpr ? porcentagemInativaCpr : 0}
          isLoss3
          color3="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={6}>
        <Typography variant="h5" align="center">
          Campos
        </Typography>
        <AnalyticEcommerce
          title="Total"
          count={
            dadosAtivos.camposAtivos + dadosAtivos.camposInativos < 1 ? (
              "0"
            ) : dadosAtivos.camposAtivos + dadosAtivos.camposInativos ? (
              dadosAtivos.camposAtivos + dadosAtivos.camposInativos
            ) : (
              <CircularProgress />
            )
          }
          iconTotal={
            dadosAtivos.camposAtivos + dadosAtivos.camposInativos ? true : ""
          }
          title2="Campos Ativos"
          count2={
            dadosAtivos.camposAtivos < 1 ? (
              "0"
            ) : dadosAtivos.camposAtivos ? (
              dadosAtivos.camposAtivos
            ) : (
              <CircularProgress />
            )
          }
          percentage2={porcentagemAtivaCampos ? porcentagemAtivaCampos : 0}
          title3="Campos Inativos"
          count3={
            dadosAtivos.camposInativos < 1 ? (
              "0"
            ) : dadosAtivos.camposInativos ? (
              dadosAtivos.camposInativos
            ) : (
              <CircularProgress />
            )
          }
          percentage3={porcentagemInativaCampos ? porcentagemInativaCampos : 0}
          isLoss3
          color3="warning"
        />
      </Grid>

      <Grid
        item
        md={8}
        sx={{ display: { sm: "none", md: "block", lg: "none" } }}
      />

      {/* row 1 */}
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="center">
              Status dos Campos
            </Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 1.6, height: "79%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }} content={false}>
          <PieChart onClick={true} setStatus={setStatus} />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="center">
              {status === "estavel"
                ? iconStable
                : status === "neutro"
                ? iconNeutro
                : iconRisk}
            </Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 1.6, pb: 2.1, height: "79%" }} content={false}>
          <OrdersTable status={status} />
        </MainCard>
      </Grid>

      {/* row 2 */}
      <Grid item xs={12} md={7} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="center">
              Pluviometria e temperatura média por mês/região
            </Typography>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Button
                size="small"
                onClick={() => setSlot("month")}
                color={slot === "month" ? "primary" : "secondary"}
                variant={slot === "month" ? "outlined" : "text"}
              >
                Meses
              </Button>
              <Button
                size="small"
                onClick={() => setSlot("week")}
                color={slot === "week" ? "primary" : "secondary"}
                variant={slot === "week" ? "outlined" : "text"}
              >
                Região
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <MainCard content={false} sx={{ mt: 1.5 }}>
          <Box sx={{ pt: 1, pr: 2 }}>
            <IncomeAreaChart slot={slot} />
          </Box>
        </MainCard>
      </Grid>
      {/*<Grid item xs={12} md={5} lg={4}>*/}
      {/*  <Grid container alignItems="center" justifyContent="space-between">*/}
      {/*    <Grid item>*/}
      {/*      <Typography variant="h5">CPRs Iniciadas</Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item />*/}
      {/*  </Grid>*/}
      {/*  <MainCard sx={{ mt: 2 }} content={false}>*/}
      {/*    <Box sx={{ p: 3, pb: 0 }}>*/}
      {/*      <Stack spacing={2}>*/}
      {/*        <Typography variant="h6" color="textSecondary">*/}
      {/*          Estatísticas desta semana*/}
      {/*        </Typography>*/}
      {/*        <Typography variant="h3">R$7,650</Typography>*/}
      {/*      </Stack>*/}
      {/*    </Box>*/}
      {/*    <MonthlyBarChart />*/}
      {/*  </MainCard>*/}
      {/*</Grid>*/}

      {/* row 3 */}
      {/*<Grid item xs={12} md={7} lg={8}>*/}
      {/*  <Grid container alignItems="center" justifyContent="space-between">*/}
      {/*    <Grid item>*/}
      {/*      <Typography variant="h5">CPRs em finalização</Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item />*/}
      {/*  </Grid>*/}
      {/*  <MainCard sx={{ mt: 2 }} content={false}>*/}
      {/*    <OrdersTable data={rows} />*/}
      {/*  </MainCard>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} md={5} lg={4}>*/}
      {/*  <Grid container alignItems="center" justifyContent="space-between">*/}
      {/*    <Grid item>*/}
      {/*      <Typography variant="h5">Principais notícias da semana</Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item />*/}
      {/*  </Grid>*/}
      {/*  <MainCard sx={{ mt: 2 }} content={false}>*/}
      {/*    <List sx={{ p: 0, '& .MuiListItemButton-root': { py: 2 } }}>*/}
      {/*      <ListItemButton divider>*/}
      {/*        <ListItemText primary="Company Finance Growth" />*/}
      {/*        <Typography variant="h5">+45.14%</Typography>*/}
      {/*      </ListItemButton>*/}
      {/*      <ListItemButton divider>*/}
      {/*        <ListItemText primary="Company Expenses Ratio" />*/}
      {/*        <Typography variant="h5">0.58%</Typography>*/}
      {/*      </ListItemButton>*/}
      {/*      <ListItemButton>*/}
      {/*        <ListItemText primary="Business Risk Cases" />*/}
      {/*        <Typography variant="h5">Low</Typography>*/}
      {/*      </ListItemButton>*/}
      {/*    </List>*/}
      {/*    <ReportAreaChart />*/}
      {/*  </MainCard>*/}
      {/*</Grid>*/}

      {/* row 4 */}
      {/*<Grid item xs={12} md={7} lg={8}>*/}
      {/*  <Grid container alignItems="center" justifyContent="space-between">*/}
      {/*    <Grid item>*/}
      {/*      <Typography variant="h5">Sales Report</Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item>*/}
      {/*      <TextField*/}
      {/*        id="standard-select-currency"*/}
      {/*        size="small"*/}
      {/*        select*/}
      {/*        value={value}*/}
      {/*        onChange={(e) => setValue(e.target.value)}*/}
      {/*        sx={{ '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}*/}
      {/*      >*/}
      {/*        {status.map((option) => (*/}
      {/*          <MenuItem key={option.value} value={option.value}>*/}
      {/*            {option.label}*/}
      {/*          </MenuItem>*/}
      {/*        ))}*/}
      {/*      </TextField>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*  <MainCard sx={{ mt: 1.75 }}>*/}
      {/*    <Stack spacing={1.5} sx={{ mb: -12 }}>*/}
      {/*      <Typography variant="h6" color="secondary">*/}
      {/*        Net Profit*/}
      {/*      </Typography>*/}
      {/*      <Typography variant="h4">R$1560</Typography>*/}
      {/*    </Stack>*/}
      {/*    <SalesColumnChart />*/}
      {/*  </MainCard>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} md={5} lg={4}>*/}
      {/*  <Grid container alignItems="center" justifyContent="space-between">*/}
      {/*    <Grid item>*/}
      {/*      <Typography variant="h5">Transaction History</Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item />*/}
      {/*  </Grid>*/}
      {/*  <MainCard sx={{ mt: 2 }} content={false}>*/}
      {/*    <List*/}
      {/*      component="nav"*/}
      {/*      sx={{*/}
      {/*        px: 0,*/}
      {/*        py: 0,*/}
      {/*        '& .MuiListItemButton-root': {*/}
      {/*          py: 1.5,*/}
      {/*          '& .MuiAvatar-root': avatarSX,*/}
      {/*          '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <ListItemButton divider>*/}
      {/*        <ListItemAvatar>*/}
      {/*          <Avatar*/}
      {/*            sx={{*/}
      {/*              color: 'success.main',*/}
      {/*              bgcolor: 'success.lighter'*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <GiftOutlined />*/}
      {/*          </Avatar>*/}
      {/*        </ListItemAvatar>*/}
      {/*        <ListItemText primary={<Typography variant="subtitle1">Order #002434</Typography>} secondary="Today, 2:00 AM" />*/}
      {/*        <ListItemSecondaryAction>*/}
      {/*          <Stack alignItems="flex-end">*/}
      {/*            <Typography variant="subtitle1" noWrap>*/}
      {/*              + $1,430*/}
      {/*            </Typography>*/}
      {/*            <Typography variant="h6" color="secondary" noWrap>*/}
      {/*              78%*/}
      {/*            </Typography>*/}
      {/*          </Stack>*/}
      {/*        </ListItemSecondaryAction>*/}
      {/*      </ListItemButton>*/}
      {/*      <ListItemButton divider>*/}
      {/*        <ListItemAvatar>*/}
      {/*          <Avatar*/}
      {/*            sx={{*/}
      {/*              color: 'primary.main',*/}
      {/*              bgcolor: 'primary.lighter'*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <MessageOutlined />*/}
      {/*          </Avatar>*/}
      {/*        </ListItemAvatar>*/}
      {/*        <ListItemText primary={<Typography variant="subtitle1">Order #984947</Typography>} secondary="5 August, 1:45 PM" />*/}
      {/*        <ListItemSecondaryAction>*/}
      {/*          <Stack alignItems="flex-end">*/}
      {/*            <Typography variant="subtitle1" noWrap>*/}
      {/*              + $302*/}
      {/*            </Typography>*/}
      {/*            <Typography variant="h6" color="secondary" noWrap>*/}
      {/*              8%*/}
      {/*            </Typography>*/}
      {/*          </Stack>*/}
      {/*        </ListItemSecondaryAction>*/}
      {/*      </ListItemButton>*/}
      {/*      <ListItemButton>*/}
      {/*        <ListItemAvatar>*/}
      {/*          <Avatar*/}
      {/*            sx={{*/}
      {/*              color: 'error.main',*/}
      {/*              bgcolor: 'error.lighter'*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <SettingOutlined />*/}
      {/*          </Avatar>*/}
      {/*        </ListItemAvatar>*/}
      {/*        <ListItemText primary={<Typography variant="subtitle1">Order #988784</Typography>} secondary="7 hours ago" />*/}
      {/*        <ListItemSecondaryAction>*/}
      {/*          <Stack alignItems="flex-end">*/}
      {/*            <Typography variant="subtitle1" noWrap>*/}
      {/*              + $682*/}
      {/*            </Typography>*/}
      {/*            <Typography variant="h6" color="secondary" noWrap>*/}
      {/*              16%*/}
      {/*            </Typography>*/}
      {/*          </Stack>*/}
      {/*        </ListItemSecondaryAction>*/}
      {/*      </ListItemButton>*/}
      {/*    </List>*/}
      {/*  </MainCard>*/}
      {/*  <MainCard sx={{ mt: 2 }}>*/}
      {/*    <Stack spacing={3}>*/}
      {/*      <Grid container justifyContent="space-between" alignItems="center">*/}
      {/*        <Grid item>*/}
      {/*          <Stack>*/}
      {/*            <Typography variant="h5" noWrap>*/}
      {/*              Help & Support Chat*/}
      {/*            </Typography>*/}
      {/*            <Typography variant="caption" color="secondary" noWrap>*/}
      {/*              Typical replay within 5 min*/}
      {/*            </Typography>*/}
      {/*          </Stack>*/}
      {/*        </Grid>*/}
      {/*        <Grid item>*/}
      {/*          <AvatarGroup sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>*/}
      {/*            <Avatar alt="Remy Sharp" src={avatar1} />*/}
      {/*            <Avatar alt="Travis Howard" src={avatar2} />*/}
      {/*            <Avatar alt="Cindy Baker" src={avatar3} />*/}
      {/*            <Avatar alt="Agnes Walker" src={avatar4} />*/}
      {/*          </AvatarGroup>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*      <Button size="small" variant="contained" sx={{ textTransform: 'capitalize' }}>*/}
      {/*        Need Help?*/}
      {/*      </Button>*/}
      {/*    </Stack>*/}
      {/*  </MainCard>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default DashboardDefault;
