import {CardActionArea, Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import React, {useEffect, useState} from "react";
import {numeroOrdemMask} from "../../utils/numeroOrdemMask";
import {normalizeTimestemp} from "../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../utils/numberWithPoints";
import axios from "axios";
import {ApiUrl} from "../../auth/authMethods";


const CardClimaAlert = ({item, biomassa}) =>  {

    // console.log({item})

    const [id, setId] = useState(item.id)
    const [numeroOrdem, setNumeroOrdem] = useState(item.numeroOrdem)
    const [dataVencimento, setDataVencimento] = useState(item.dataVencimentoCedula)
    const [dataEmissao, setDataEmissao] = useState(item.dataEmissaoCedula)
    const [produto, setProduto] = useState(item.produto.tipo)
    const [produtoVariedade, setProdutoVariedade] = useState(item.produto.variedade)
    const [quantidadeProduto, setQuantidadeProduto] = useState(item.quantidadeProduto)
    const [emitenteNome, setEmitenteNome] = useState(item.emitentes && item.emitentes[0] && item.emitentes[0].nome ? item.emitentes[0].nome : "Nenhum Emitente Cadastrado!")
    const [credorNome, setCredorNome] = useState(item.credores && item.credores[0] && item.credores[0].nome ? item.credores[0].nome : "Nenhum Credor Cadastrado!")

    // console.log({biomassa})



    return(
        <>
            <Card sx={{
                maxWidth: 480,
                // backgroundColor: '#f3b1b1',
                boxShadow: '10px 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.03)'
                }
            }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="250"
                        image={biomassa.urlImagemSkyfield}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Detalhes do Campo
                        </Typography>
                        <Typography variant="body1" color="text.primary">
                            Biomassa: <strong>{biomassa.biomassa}%</strong><br/>
                            Temperatura: <strong>{biomassa.temperatura ? biomassa.temperatura + "ºC" : "Sem dados de temperatura"}</strong><br/>
                            Pluviosidade: <strong>{biomassa.pluviosidade ? biomassa.pluviosidade + "mm" : "Sem dados de pluviosidade"}</strong><br/>
                        </Typography>
                        <br/>
                        <Typography gutterBottom variant="h5" component="div">
                            Detalhes da Cpr
                        </Typography>
                        <Typography variant="body1" color="text.primary">
                            Id: <strong>{id}</strong><br/>
                            Numero ordem: <strong>{numeroOrdemMask(numeroOrdem)}</strong><br/>
                            Data de Vencimento: <strong>{normalizeTimestemp(dataVencimento)}</strong><br/>
                            Data de Emissão: <strong>{normalizeTimestemp(dataEmissao)}</strong><br/>
                            Produto: <strong>{produto} - {produtoVariedade}</strong><br/>
                            Quantidade de Produto: <strong>{numberWithPoints(quantidadeProduto, 0)} Sacas</strong><br/>
                            Nome(s) do(s) Emitentes(s): <strong>{emitenteNome}</strong><br/>
                            Nome(s) do(s) Credores(s): <strong>{credorNome}</strong><br/>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
};
export default CardClimaAlert;