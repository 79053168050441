import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import React from "react";


export default function BasicLoader({height, message, icon, notLeftShow, size}) {

    const heightValue = height ? `${height}vh` : '50vh'
    const text = message ? message : 'Buscando dados...'
    const loader = icon ? icon : <CircularProgress color='success' size={size ? `${size}%` : '6%'}/>

    return (
        (notLeftShow ?
                <Container maxWidth="xs">
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={heightValue}>
                        {text} &nbsp; {loader}
                    </Box>
                </Container>
                :
                <Container maxWidth="xs">
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={heightValue}>
                        {loader} &nbsp; {text}
                    </Box>
                </Container>
        )
    )
}