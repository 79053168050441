import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const PopupDeleteAlarme = ({
                                         baseUrl,
                                         setLoadData,
                                         item
                                     }) => {
    const buttonText = <span className="link-danger">
        <DeleteForeverIcon fontSize="medium"/>
        &nbsp;Deletar
    </span>

    return (
        <PopupDelete
        baseUrl={baseUrl} setLoadData={setLoadData}
        title={"Confirmar Exclusão do Alarme"} id={item.id}
        buttonClass={'dropdown-item text-danger'}
        buttonText={buttonText}
        >

            <div className="mt-3 mb-3">
                <b>Produto do Alarme: </b>
                <>{item.produto.tipo} - {item.produto.variedade}</>
                <br/>
                <b>Pluviometricidade Miníma: </b>
                <>{item.pluviometricidadeMinima}mm por {item.diasAbaixoPluviosidade} dias</>
                <br/>
                <b>Pluviometricidade Maxima: </b>
                <>{item.pluviometricidadeMaxima}mm por {item.diasAcimaPluviosidade} dias</>
                <br/>
                <b>Temperatura Mínima: </b>
                <>{item.temperaturaMinima}ºC por {item.diasAbaixoTemperatura} dias</>
                <br/>
                <b>Temperatura Máxima: </b>
                <>{item.temperaturaMaxima}ºC por {item.diasAcimaTemperatura} dias</>
                <br/>
                <b>Biomassa Mínima: </b>    
                <>{item.biomassaAbaixo}% por {item.diasAbaixoBiomassa}</>
                <br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir este alarme?</p>

        </PopupDelete>
    )
}
export default PopupDeleteAlarme