import React from "react";
import PopupEditEmitente from "../../Emitente/components/PopupEditEmitente";
import PopupEditCredor from "../../Credor/components/PopupEditCredor";
import {ApiUrl} from "../../../auth/authMethods";

const OpenPopupEdit = ({ setLoadData, item, popupType }) => {
    const getPopupComponent = () => {

        // Verifica se popupType é uma string antes de usá-lo no switch
        if (typeof popupType !== 'string') {
            console.error("Invalid Popup Type");
            return null;
        }

        switch (popupType) {
            case "Emitente já cadastrado em nossa base":
                return <PopupEditEmitente setLoadData={setLoadData} baseUrl={`${ApiUrl}/emitente`} item={item} highlighted={true} />;
            case "Credor já cadastrado em nossa base":
                return <PopupEditCredor setLoadData={setLoadData} baseUrl={`${ApiUrl}/credor`} item={item} highlighted={true} />;
            default:
                return null;
        }
    };

    return <>{getPopupComponent()}</>;
};

export default OpenPopupEdit;

