import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import React, {useState} from "react";
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import InputsCidade from "./InputsCidade";

export default function PopupAddCidade({baseUrl, setLoadData}) {

    const [nome, setNome] = useState('');
    const [estadoId, setEstadoId] = useState('')


    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsCidade
        baseUrl={baseUrl}
        nome={nome} setNome={setNome}
        errorMessage={errorMessage} errorColumn={errorColumn}
        estadoId={estadoId} setEstadoId={setEstadoId}
    />

    const icon = <SouthAmericaIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setEstadoId('')
        setNome('')
    }

    const returnData = () => {

        //
        // let estadoData = estado?.id? {estadoId: estado.id} : {estado}
        console.log({
            nome,
            estadoId
        })
        return {
            nome,
            estadoId
        }
    }

    return <PopupAdd
        baseUrl={baseUrl} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar cidade'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}