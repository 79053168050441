import * as React from "react";
import { PieChart } from "@mui/x-charts";
import { useState, useEffect } from "react";
import axios from "axios";
import { ApiUrl } from "../../auth/authMethods";
import { useUser } from "../../auth/AuthProvider";
import BasicLoader from "../BasicLoader/BasicLoader";
import ErrorIcon from "@mui/icons-material/Error";
import {numberWithPoints} from "../../utils/numberWithPoints";
import {isThisYear} from "date-fns";

const data = [
  { id: 0, value: 25, label: "Risco" },
  { id: 1, value: 30, label: "Alerta" },
  { id: 2, value: 45, label: "Estável" },
];

export default function PieActiveArc({ item, onClick, setStatus }) {
  const [percentageData, setPercentageData] = useState("");
  const [requestStatus, setRequestStatus] = useState("fetching");
  const { user } = useUser();

  const handleOnClick = (event) => {
    const statusColor =
        event.target.style.fill.substring(4, 7) == 255
            ? "risco"
            : event.target.style.fill.substring(4, 7) == 128
                ? "neutro"
                :
                "estavel";

    if (statusColor) {
      setStatus(statusColor);
    }
  };

  useEffect(() => {
    const headersData = {
      Authorization: `Bearer ${user?.token}`,
    };

    axios
      .get(`${ApiUrl}/dashboard/percentual`, { headers: headersData })
      .then(function (response) {
        setPercentageData(response.data);
      });
  }, []);
  const riskPercentage = Number(Math.round(percentageData.riskPercentage));



  return percentageData ? (
    <PieChart
      // , "#ff6961"
      colors={["#77dd77", "#ff6961", "#808080"]}
      series={[
        {
          arcLabel: (item) => `${item.value}%`,
          data: [
            {
              id: 0,
              value: Number(Math.round(percentageData.stablePercentage)) || ``,
              label: `Estável (${Number(Math.round(percentageData.stablePercentage)) || `0`}%)` || `Estável (0%)`,
            },
            {
              id: 1,
              value: Number(Math.round(percentageData.riskPercentage)) || "",
              label: `Risco (${Number(Math.round(percentageData.riskPercentage)) || `0`}%)` || `Risco (0%)`,
            },
            {
              id: 2,
              value: Number(Math.round(percentageData.neutroPercentage)) || "",
              label: `Neutro (${Number(Math.round(percentageData.neutroPercentage)) || `0`}%)`,
            },
            // {
            //   id: 3,
            //   value: Number(Math.round(percentageData.alertPercentage)) || "",
            //   label: `Alerta (${Number(Math.round(percentageData.alertPercentage))}%)`,
            // },
          ],
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          cx: 170,
          cy: 120,
        },
      ]}
      height={250}
      onClick={onClick ? handleOnClick : null}
    />
  ) : (
    <BasicLoader
      message={"Carregando..."}
      height={10}
      notLeftShow={true}
    />
  );
}
