import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsCprs from "./inputs/InputsCprs";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const PopupEditCpr = ({item, baseUrl, setLoadData}) => {

    const [id, setId] = useState("")
    const [numeroOrdem, setNumeroOrdem] = useState("")
    const [dataVencimentoCedula, setDataVencimentoCedula] = useState("")
    const [dataEmissaoCedula, setDataEmissaoCedula] = useState("")
    const [produtoId, setProdutoId] = useState('')
    const [quantidadeProduto, setQuantidadeProduto] = useState('')
    const [estadoId, setEstadoId] = useState('')
    const [localEmissao, setLocalEmissao] = useState('')
    const [cidadeId, setCidadeId] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)
    const [errorColumn, setErrorColumn] = useState(false)

    const [credores, setCredores] = useState([
        {index: 0},
    ]);

    const [emitentes, setEmitentes] = useState([
        {index: 0},
    ]);

    const [coordenadas, setCoordenadas] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [blockSubmit, setBlockSubmit] = useState(false);

    const inputs = <InputsCprs
        numeroOrdem={numeroOrdem} setNumeroOrdem={setNumeroOrdem}
        dataVencimentoCedula={dataVencimentoCedula} setDataVencimentoCedula={setDataVencimentoCedula}
        dataEmissaoCedula={dataEmissaoCedula} setDataEmissaoCedula={setDataEmissaoCedula}
        coordenadas={coordenadas} setCoordenadas={setCoordenadas}
        credores={credores} setCredores={setCredores} emitentes={emitentes} setEmitentes={setEmitentes}
        produtoId={produtoId} setProdutoId={setProdutoId} setBlockSubmit={setBlockSubmit}
        estadoId={estadoId} setEstadoId={setEstadoId}
        quantidadeProduto={quantidadeProduto} setQuantidadeProduto={setQuantidadeProduto}
        localEmissao={localEmissao} setLocalEmissao={setLocalEmissao}
        cidadeId={cidadeId} setCidadeId={setCidadeId}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        errorColumn={errorColumn} errorMessage={errorMessage} setLoadData={setLoadData} openPopupEdit={true}
        inputOrTypography={false} notShowAddressInputs={true}
    />
    const itemToState = () => {
        setId(item.id)
        setNumeroOrdem(item.numeroOrdem.toString().padStart(8, '0'))
        setDataVencimentoCedula(item.dataVencimentoCedula)
        setDataEmissaoCedula(item.dataEmissaoCedula)
        setLocalEmissao(item.localEmissao)
        setCoordenadas(item.coordenadas)
        setCidadeId(item.cidade.id)
        setProdutoId(item.produto.id)
        setQuantidadeProduto(item.quantidadeProduto)
        setCredores(item.credores)
        setEmitentes(item.emitentes)
        setBlockSubmit(false)
    }

    const title = "Editar Cpr"
    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp;{title}
    </>
    const returnData = () => {
        let credoresIds = []
        let credoresData = []
        credores.map((credor) => {
            credor?.id?
                credoresIds.push(credor.id):
                credoresData.push(credor)
        })

        let emitentesIds = []
        let emitentesData = []
        emitentes.map((emitente) => {
            emitente?.id?
                emitentesIds.push(emitente.id):
                emitentesData.push(emitente)
        })

        console.log({
            numeroOrdem,
            dataVencimentoCedula,
            dataEmissaoCedula,
            coordenadas,
            localEmissao,
            cidadeId,
            produtoId,
            quantidadeProduto,
            emitentesIds,
            emitentes: emitentesData,
            credoresIds,
            credores: credoresData
        })

        return {
            numeroOrdem: numeroOrdem.replace(/[^\w\s]/gi, ''),
            dataVencimentoCedula,
            dataEmissaoCedula,
            coordenadas,
            localEmissao,
            cidadeId,
            produtoId,
            quantidadeProduto,
            emitentesIds,
            emitentes: emitentesData,
            credoresIds,
            credores: credoresData
        }
    }


    return (
        <>
            <PopupEdit
                baseUrl={baseUrl + '/cpr/' + id} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                buttonClass='dropdown-item' buttonText={buttonText}
            />
        </>
    )
}
export default PopupEditCpr