import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterFormProduto from "./FilterFormProduto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProdutoActions from "./ProdutoActions";

export default function ListProduto({
                                        baseUrl, loadData,
                                        setLoadData, size, page,
                                        setTotalPages, setTotalElements,
                                        sort, setSort, updateCode, setPage
                                    }) {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})


    const titles = [
        "Tipo",
        "Variedade",
        "Pluviometricidade Miníma",
        "Pluviometricidade Máxima",
        "Temperatura Miníma",
        "Temperatura Máxima",
    ]


    const renderTooltipDeleteDisabled = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Este produto não pode ser excluído por
            já possuir cpr(s) atrelada(s) à ele!
        </Tooltip>
    );

    const buttonDeleteDisabled = <OverlayTrigger
        placement="right"
        delay={{show: 150, hide: 400}}
        overlay={renderTooltipDeleteDisabled}
    >
        <span style={{
            color: '#828282'
        }}>
            Deletar
            <DeleteForeverIcon fontSize="medium"/>
        </span>
    </OverlayTrigger>


    return (
        <>
            <FilterFormProduto filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.tipo}</td>
                        <td>{item.variedade}</td>
                        <td>{item.pluviometricidadeMinima + " mm"}</td>
                        <td>{item.pluviometricidadeMaxima + " mm"}</td>
                        <td>{item.temperaturaMinima + " °C"}</td>
                        <td>{item.temperaturaMaxima + " °C"}</td>
                        <td>
                            <span>
                                <ProdutoActions item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>
                            </span>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}