import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import {Dropdown} from "react-bootstrap";
import {useUser} from "../../../auth/AuthProvider";
import PopupEditProduto from "./PopupEditProduto";
import PopupDeleteProduto from "./PopupDeleteProduto";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "react-bootstrap/Tooltip";
import PopupProdutoDetails from "./PopupProdutoDetails";

export default function ProdutoActions({item, baseUrl, setLoadData}) {

    const {user} = useUser()

    const renderTooltipDeleteDisabled = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Este produto não pode ser excluído por
            já possuir cpr(s) atrelada(s) à ele!
        </Tooltip>
    );

    const buttonDeleteDisabled = <div
        className='dropdown-item'>
        <OverlayTrigger

            placement="right"
            delay={{show: 150, hide: 400}}
            overlay={renderTooltipDeleteDisabled}
        >
        <span style={{
            color: '#828282'
        }}>
            <DeleteForeverIcon fontSize="medium"/>
            Deletar
        </span>
        </OverlayTrigger>

    </div>


    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupProdutoDetails item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>

            {user.isMaster && <>
                <PopupEditProduto
                    setLoadData={setLoadData}
                    baseUrl={baseUrl}
                    item={item}
                />
                {item.canBeRemoved === true ?
                    <td>
                        <PopupDeleteProduto
                            item={item}
                            setLoadData={setLoadData}
                            baseUrl={baseUrl}
                        />
                    </td> :
                    <td>
                        {buttonDeleteDisabled}
                    </td>
                }
            </>}

        </Dropdown.Menu>

    </Dropdown>
}