import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import FilterFormCar from "./FilterFormCar";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import CarActions from "./CarActions";
import {numberWithPoints} from "../../../utils/numberWithPoints";

const ListCars = ({
                      baseUrl, loadData,
                      setLoadData, size, page,
                      setTotalPages, setTotalElements,
                      sort, setSort, updateCode, setPage
                  }) => {

    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState({})


    const title = [
        "Registro",
        "Nome do Proprietário",
        "Nome do Imovél rural",
        "Município",
        "Área Total",
        "CPR Coordenada",
        "Data de Cadastro"
    ];

    return (
        <>
            <FilterFormCar filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={title} baseUrl={baseUrl}
                page={page}
                sort={sort}
                size={size}
                loadData={loadData}
                setTotalPages={setTotalPages}
                setTotalElements={setTotalElements}
                setData={setData}
                setLoadData={setLoadData}
                filter={filter}
                error={error}
                setError={setError}
            >
                {data ? (
                    data.map((item) =>

                        <tr key={item.id}>
                            <td>{item.registro}</td>
                            <td>{item.nomeProprietario}</td>
                            <td>{item.nomeImovelRural}</td>
                            <td>{item.cidade.nome} - {item.cidade.estado.sigla}</td>
                            <td>{numberWithPoints(item.hectares)} ha</td>
                            <td>{item.cpr.coordenadas}</td>
                            <td>{normalizeTimestemp(item.dataCadastro)}</td>
                            <td>
                                <CarActions item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>
                            </td>

                        </tr>
                    )
                ) : (
                    <BasicLoader
                        height="large"
                    />
                )}
            </TableList>
        </>
    );
};
export default ListCars;
