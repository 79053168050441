import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterFormCalendarioPlantio from "./FilterFormCalendarioPlantio";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import PopupDeleteCalendario from "./PopupDeleteCalendario";
import PopupEditCalendario from "./PopupEditCalendario";
import {useUser} from "../../../auth/AuthProvider";


const ListCalendarioPlantio = ({
                                   baseUrl, loadData,
                                   setLoadData, size, page,
                                   setTotalPages, setTotalElements,
                                   sort, setSort, updateCode, setPage
                               }) => {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        "Região",
        "Data Inicial",
        "Data Final",
        "Tipo de Plantio",
        "Produto",
        "Ano"
    ]

    const getTypeLabel = (type) => {
        const typeMap = {
            'PLANTIO': 'Plantio',
            'COLHEITA': 'Colheita',
        };
        return typeMap[type] || type;
    };

    return (
        <>
            <FilterFormCalendarioPlantio filter={filter} setFilter={setFilter} setLoadData={setLoadData}
                                         setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.regiao}</td>
                        <td>{normalizeTimestemp(item.dataInicial)}</td>
                        <td>{normalizeTimestemp(item.dataFinal)}</td>
                        <td>{getTypeLabel(item.tipo)}</td>
                        <td>{item.produtoTipo + " - " + item.produtoVariedade}</td>
                        <td>{item.ano}</td>
                        {user.isMaster && <>
                            <td>
                            <span>
                                <PopupEditCalendario
                                    item={item}
                                    baseUrl={baseUrl}
                                    setLoadData={setLoadData}
                                />
                            </span>
                            </td>
                            <td>
                            <span>
                                <PopupDeleteCalendario
                                    item={item}
                                    baseUrl={baseUrl}
                                    setLoadData={setLoadData}
                                />
                            </span>
                            </td>
                        </>}
                    </tr>
                )}
            </TableList>
        </>
    )
}
export default ListCalendarioPlantio