import {useEffect, useState} from "react";
import {ApiUrl} from "../../auth/authMethods";
import Card from "../../component/Card";
import {Button} from "react-bootstrap";

export default function NoticiasContent() {

    const [noticias, setNoticias] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [totalPages, setTotalPages] = useState(1);
    const [sort, setSort] = useState("asc");
    const defaultImage = "https://www.ufes.br/sites/default/files/field/image/alerta.jpg";

    useEffect(() => {
        const fetchNoticias = async () => {
            try {
                const response = await fetch(`${ApiUrl}/news?page=${currentPage}&size=${pageSize}&sort=${sort}`);

                const data = await response.json();

                if (data.content && Array.isArray(data.content)) {
                    setNoticias(data.content);
                    setTotalPages(data.totalPages);
                } else {
                    console.error('Erro: O retorno da API não contém uma propriedade "content" que é um array', data);
                }
            } catch (error) {
                console.error('Erro ao buscar notícias:', error);
            }
        };

        fetchNoticias();
    }, [currentPage, pageSize, sort]);

    return (
        <>
            <h2>Notícias</h2>
            <div className="noticias-container">
                {noticias.map((noticia, index) => (
                    <Card
                        key={index}
                        title={noticia.title}
                        date={noticia.publishDate}
                        description={noticia.description}
                        image={noticia.image || defaultImage}
                        autor={noticia.author}
                    ></Card>
                ))}
            </div>
            <div className="pagination-controls">
                <Button onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
                        disabled={currentPage === 1}>Anterior</Button>
                <span>Página {currentPage} de {totalPages}</span>
                <Button onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}
                        disabled={currentPage === totalPages}>Próxima</Button>
            </div>
        </>
    );
}