import React, {useEffect, useRef, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Row} from 'react-bootstrap';
import axios from 'axios';
import './Map.css';
import {Divider, Switch} from "@mui/material";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';

function convertDMSToDecimal(degrees, minutes, seconds, direction) {
    let decimalDegrees = degrees + (minutes / 60) + (seconds / 3600);
    if (direction === "S" || direction === "W") {
        decimalDegrees = -decimalDegrees;
    }
    return decimalDegrees;
}

const parseCoordinates = (input, ocultError) => {
    // Verifica se as coordenadas estão no formato decimal (Lat Long)
    const decimalFormatRegex = /Lat\s*(-?\d+(\.\d+)?)\s*;\s*Long\s*(-?\d+(\.\d+)?)/;
    const decimalMatches = input?.match(decimalFormatRegex);

    if (decimalMatches) {
        const lat = parseFloat(decimalMatches[1]);
        const lng = parseFloat(decimalMatches[3]);

        // Converte coordenadas decimais para DMS
        const latLngDMS = convertDMSToDecimal(lat, lng)
        return {lat: lat, lng: lng, formatted: latLngDMS};
    }

    // Verifica se as coordenadas estão no formato DMS
    const regex = /(\d+)°(\d+)'(\d+\.\d+)\"([NS])\s*(\d+)°(\d+)'(\d+\.\d+)\"([EW])/;
    const matches = input?.match(regex);

    if (!matches) {
        if (!ocultError)
            alert('Coordenadas inválidas');
        return null;
    }

    const degreesLat = parseInt(matches[1]);
    const minutesLat = parseInt(matches[2]);
    const secondsLat = parseFloat(matches[3]);
    const directionLat = matches[4];
    const degreesLng = parseInt(matches[5]);
    const minutesLng = parseInt(matches[6]);
    const secondsLng = parseFloat(matches[7]);
    const directionLng = matches[8];

    const lat = convertDMSToDecimal(degreesLat, minutesLat, secondsLat, directionLat);
    const lng = convertDMSToDecimal(degreesLng, minutesLng, secondsLng, directionLng);

    return {lat, lng};
};

const Map = ({id, coordenadas, setSent, setError, setNeedToReload}) => {

    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);

    const formatedCoordinates = parseCoordinates(coordenadas, true)

    const [polygonCoordinates, setPolygonCoordinates] = useState([]);
    const [isPolygonEditable, setIsPolygonEditable] = useState(false);
    const [polygon, setPolygon] = useState(null);
    const [addressInput, setAddressInput] = useState('');
    const [coordinatesInput, setCoordinatesInput] = useState('');
    const [perCoordinate, setPerCoordinate] = useState(true);
    const [map, setMap] = useState(null);
    const [area, setArea] = useState({hectares: null, squareMeters: null});

    const [center, setCenter] = useState(
        formatedCoordinates ?
            formatedCoordinates :
            {lat: -14.235004, lng: -51.925280}
    )
    const [zoom, setZoom] = useState(
        formatedCoordinates ?
            15 : 4
    )

    const [drawingManager, setDrawingManager] = useState(null);
    const [marker, setMarker] = useState(null);

    const handleCalculateArea = () => {
        if (polygonCoordinates.length < 3) {
            alert('O polígono deve ter pelo menos 3 vértices para calcular a área.');
            return;
        }

        const areaInSquareMeters = window.google.maps.geometry.spherical.computeArea(polygonCoordinates);
        const areaInHectares = areaInSquareMeters / 10000;

        setArea({ squareMeters: areaInSquareMeters, hectares: areaInHectares });
        return areaInHectares
    };

    const handleAddressInputChange = (e) => {
        const input = e.target.value;
        setAddressInput(input);
        setCoordinatesInput(input);
    };

    const handleSaveShapefile = () => {

        // console.log(polygonCoordinates)

        axios.post(`${process.env.REACT_APP_SHAPEFILE_BUILDER_LINK}/shapefile/downloadShapefile`, {
            polygonsData: [{
                coordinates: polygonCoordinates,
                area: area.hectares? area.hectares : handleCalculateArea(),
            }],
            cprId: id,
        }, {
            responseType: 'blob',
        })
            .then(response => {
                setSent(true);
                if (setNeedToReload) {
                    setNeedToReload(true);
                }
            })
            .catch(error => {
                setError(true);
            });
    };


    const handleToggleEdit = () => {
        const drawingManagerRef = drawingManager;
        if (polygon) {
            if (!isPolygonEditable) {
                drawingManagerRef.setOptions({
                    drawingControl: true,
                    drawingMode: null,
                });
                polygon.setEditable(true);
            } else {
                drawingManagerRef.setOptions({
                    drawingControl: true,
                    drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                });
                polygon.setEditable(false);
            }
            setIsPolygonEditable(!isPolygonEditable);
        }
    };


    const handleDeletePolygon = () => {
        const drawingManagerRef = drawingManager;

        if (isPolygonEditable) {
            drawingManagerRef.setOptions({drawingControl: true});
            polygon.setEditable(false);
        }
        if (polygon) {
            polygon.setMap(null);
        }
        setPolygonCoordinates([]);
        setIsPolygonEditable(false);
        setPolygon(null);
    };

    const handleToggleSearchMode = () => {
        setPerCoordinate(!perCoordinate);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const mapInstance = new window.google.maps.Map(mapRef.current, {
                center: center,
                zoom: zoom,
                mapTypeId: 'satellite',
                streetViewControl: false,
            });

            const drawingManagerInstance = new window.google.maps.drawing.DrawingManager({
                drawingControl: true,
                drawingControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                },
            });

            drawingManagerInstance.setMap(mapInstance);

            let polygonInstance;

            window.google.maps.event.addListener(drawingManagerInstance, 'overlaycomplete', (event) => {
                if (event.type === 'polygon') {
                    if (polygon) {
                        polygon.setMap(null);
                    }
                    polygonInstance = event.overlay;

                    const coordinates = polygonInstance.getPath().getArray();
                    setPolygonCoordinates(coordinates);
                    setPolygon(polygonInstance);
                    setIsPolygonEditable(false);
                }
            });

            new window.google.maps.Marker({
                position: marker,
                map: mapInstance,
            });

            setMap(mapInstance);
            setDrawingManager(drawingManagerInstance);
            autocompleteRef.current = new window.google.maps.places.Autocomplete(
                document.getElementById('addressInput')
            );
            autocompleteRef.current.addListener('place_changed', () => {
                const place = autocompleteRef.current.getPlace();
                if (place && place.geometry) {
                    setCenter({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
                    setZoom(15);
                    setMarker({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
                }
            });
        }
    }, [center, zoom, marker]);


    const handleChangeCenter = (e) => {
        e.preventDefault()
        const points = handlePlaceChanged();

        if (points) {
            setCenter(points)
            setZoom(15)
            setMarker(points)
        } else {
            setCenter({lat: -14.235004, lng: -51.925280})
            setZoom(4)
            setMarker(null)
        }
    }

    const handlePlaceChanged = () => {
        const mode = perCoordinate ? 'coordinates' : 'address';

        if (mode === 'coordinates') {
            const parsedCoordinates = parseCoordinates(coordinatesInput);
            if (parsedCoordinates) {
                return {lat: parsedCoordinates.lat, lng: parsedCoordinates.lng};
            } else {
                alert('Coordenadas inválidas');
            }
        } else {
            const place = autocompleteRef.current.getPlace()
            if (place?.geometry && place?.geometry.location) {
                return {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
            } else {
                alert('Endereço não encontrado');
            }
        }
        return false
    };

    const handleSelect = async (selectedAddress) => {
        try {
            const results = await geocodeByAddress(selectedAddress);

            if (results && results.length > 0) {
                const latLng = await getLatLng(results[0]);

                if (latLng && latLng.lat && latLng.lng) {
                    setCenter(latLng);
                    setZoom(17);
                    setMarker(latLng);
                } else {
                    alert('Endereço não encontrado ou sem informações de localização.');
                }
            } else {
                alert('Endereço não encontrado.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Container className="Container">
            <Divider/>
            <div>
                Buscar Por: &nbsp;
                <span>
                <h5 className='d-inline'>Por endereço</h5>
                <Switch
                    onChange={handleToggleSearchMode}
                    defaultChecked
                />
                <h5 className='d-inline'>Por Coordenadas</h5>
            </span>
            </div>
            <Row className='mb-3'>
                <form onSubmit={(e) => e.preventDefault()}>

                    <Col md={12}>
                        <div className="AddressInput d-inline">

                            {!perCoordinate ? (
                                <PlacesAutocomplete
                                    value={addressInput}
                                    onChange={setAddressInput}
                                    onSelect={handleSelect}
                                >
                                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: "Digite um endereço",
                                                    className: 'form-control',
                                                    id: "addressInput",
                                                })}
                                            />
                                            <div>
                                                {loading ? <div>Carregando...</div> : null}

                                                {suggestions.map((suggestion, index) => {
                                                    const style = {
                                                        backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                                                    };
                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion, {style})}
                                                             key={index}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            ) : (
                                <input
                                    type="text"
                                    placeholder="Digite as coordenadas"
                                    className='form-control'
                                    onChange={handleAddressInputChange}
                                    id="addressInput"
                                    ref={autocompleteRef}
                                />
                            )}
                        </div>
                    </Col>
                    {perCoordinate && (  // Renderizar o botão somente se não estiver buscando por coordenadas
                        <Col md={12}>
                            <Button
                                className='d-inline'
                                variant="primary"
                                onClick={handleChangeCenter}
                            >
                                Buscar
                            </Button>
                        </Col>
                    )}
                </form>
            </Row>
            {/* Mapa */}
            <Row>
                <Col md={12}>
                    <div ref={mapRef} className="Map">
                        {!isPolygonEditable && area !== null && area.hectares !== null && area.squareMeters !== null && (
                            <div className="description-container" style={{
                                display: 'block',
                                position: 'absolute',
                                top: '1.3%',
                                right: '10%',
                                backgroundColor: 'white',
                                padding: '8.5px',
                                borderRadius: '5px',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                                zIndex: 1000,
                                textAlign: 'center',
                            }}>
                                <div className="description-content">
                                    <p style={{margin: 0}}>Área em Hectares: {area.hectares.toFixed(2)} ha</p>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="ButtonGroup">
                        <Button variant="primary" onClick={handleToggleEdit}>
                            {isPolygonEditable ? 'Parar Edição' : 'Editar Polígono'}
                        </Button>
                        <Button variant="danger" onClick={handleDeletePolygon}>
                            Excluir Polígono
                        </Button>
                        <Button onClick={handleSaveShapefile} disabled={polygonCoordinates.length < 1}>
                            Salvar Área
                        </Button>
                        <Button onClick={handleCalculateArea}
                                disabled={isPolygonEditable || polygonCoordinates.length < 3}>
                            Calcular Área
                        </Button>
                    </div>
                </Col>
            </Row>

        </Container>
    );
};

export default Map;