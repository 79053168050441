import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./index.css";

// material-ui
import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
} from "@mui/material";

import { NumberFormatBase } from "react-number-format";
import axios from "axios";
import { ApiUrl } from "../../auth/authMethods";
import { useUser } from "../../auth/AuthProvider";
import BasicLoader from "../BasicLoader/BasicLoader";
import ErrorIcon from "@mui/icons-material/Error";
import CprAlert from "../../page/Cprs/components/CprAlert";
import Button from "@mui/material/Button";
import { numeroOrdemMask } from "../../utils/numeroOrdemMask";
import PaginationControl from "../Pagination/PaginationControl";
import { normalizeTimestemp } from "../../utils/normalizeTimestemp";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  {
    id: "numeroOrdem",
    align: "left",
    disablePadding: false,
    label: "Número de ordem",
  },
  {
    id: "Emitentes",
    align: "left",
    disablePadding: true,
    label: "Emitentes",
  },
  {
    id: "Campo",
    align: "right",
    disablePadding: false,
    label: "Campo",
  },
  {
    id: "dataEmissaoCedula",
    align: "left",
    disablePadding: false,
    label: "Data de Emissão",
  },
  {
    id: "dataVencimentoCedula",
    align: "right",
    disablePadding: false,
    label: "Data de Vencimento",
  },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="data" spacing={1} alignItems="center">
      {/*<Dot color={color} />*/}
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function OrdersTable({ status }) {
  const { user } = useUser();
  const [order] = useState("desc");
  const [orderBy] = useState("numeroOrdem");
  const [selected] = useState([]);

  const [size, setSize] = useState(20);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");

  const [dataRequest, setData] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [rows, setRows] = useState([10, 20, 50, 100]);
  const [loadData, setLoadData] = useState(true);
  const [requestStatus, setRequestStatus] = useState("fetching");

  useEffect(() => {
    if (status === "estavel") {
      setRequestStatus("fetching");
    } else if (status === "alerta") {
      setRequestStatus("fetching");
    } else {
      setRequestStatus("fetching");
    }
  }, [status]);

  useEffect(() => {
    let displaySort = sort ? sort : "id,desc";
    let params = { page, size, sort: displaySort };

    const headersData = {
      Authorization: `Bearer ${user?.token}`,
    };

    
    axios
      .get(`${ApiUrl}/dashboard/${status}`, { params, headers: headersData })
      .then(function (response) {
        setTotalPages(response.data.totalPages);
        setTotalElements(response.data.totalElements);

        setData(response.data.content);
        setLoadData(false);
      })
      .catch(function (errorReceived) {
        console.log(errorReceived);
        setLoadData(false);
        if (errorReceived.response.status === 401) {
          console.log("Sem autorização.");
        } else {
          console.log("Error:", errorReceived);
        }
      })
      .finally(function (response) {
        setRequestStatus("done");
      });
  }, [status, loadData]);

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

  return requestStatus === "fetching" ? (
    <div className="message">
      <BasicLoader
        message={`Carregando campos ${
          status === "estavel"
            ? '"Estáveis"'
            : status === "neutro"
            ? '"Neutros"'
            : 'em "Risco"'
        }...`}
        height={"30"}
        notLeftShow={true}
      />
    </div>
  ) : dataRequest.length === 0 ? (
    <div className="message">
      <BasicLoader
        message={`Não há CPRs com o estado "${
          status === "estavel"
            ? "Estável"
            : status === "neutro"
            ? "Neutro"
            : "Risco"
        }"`}
        icon={<ErrorIcon color="warning" />}
        height={"30"}
        notLeftShow={true}
      />
    </div>
  ) : (
    <Box>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
          position: "relative",
          display: "block",
          maxWidth: "100%",
          maxHeight: "320px",
          paddingBottom: "6px",
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            "& .MuiTableCell-root:first-of-type": {
              pl: 2,
            },
            "& .MuiTableCell-root:last-of-type": {
              pr: 3,
            },
          }}
        >
          <OrderTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {stableSort(dataRequest, getComparator(order, orderBy)).map(
              //old: props.data
              (data, index) => {
                const isItemSelected =
                  isSelected(data.cpr.numeroOrdem) !== undefined
                    ? isSelected(data.cpr.numeroOrdem)
                    : false;
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={data.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="data"
                      align="left"
                    >
                      <CprAlert
                        textButton={
                          data.cpr && data.cpr.numeroOrdem
                            ? numeroOrdemMask(data.cpr.numeroOrdem)
                            : ""
                        }
                        cprData={data}
                        item={
                          data.cpr && data.cpr.numeroOrdem
                            ? data.cpr.numeroOrdem
                            : ""
                        }
                        biomassa={data.biomassa}
                        statusBiomassa={data.statusBiomassa}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {data.cpr.emitentes[0].nome
                        ? data.cpr.emitentes[0].nome
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      {data.biomassa.arquivo.nome
                        ? data.biomassa.arquivo.nome.substring(
                            0,
                            data.biomassa.arquivo.nome.length - 4
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {data.cpr.dataEmissaoCedula
                        ? normalizeTimestemp(data.cpr.dataEmissaoCedula)
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      {data.cpr.dataVencimentoCedula
                        ? normalizeTimestemp(data.cpr.dataVencimentoCedula)
                        : ""}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
        <PaginationControl
          totalElements={totalElements}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          setLoadData={setLoadData}
          rowsPerPageOptions={rows}
        />
      </TableContainer>
    </Box>
  );
}
