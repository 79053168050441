import {useState} from "react";
import PopupAddClima from "./components/PopupAddClima";
import ListClima from "./components/ListClima";
import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";


export default function Clima() {

    const {user} = useUser()

    const baseUrl = `${ApiUrl}/condicoesideais`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState("")
    const [totalElements, setTotalElements] = useState(10)

    return <>
        <ContentContainer width='xl'>

            <h1> Lista De Condições Climáticas Ideais</h1>
            {user.isMaster &&
                <PopupAddClima baseUrl={baseUrl} setLoadData={setLoadData}/>
            }
            <ListClima
                setLoadData={setLoadData}
                baseUrl={baseUrl}
                loadData={loadData}
                page={page}
                setPage={setPage}
                size={size}
                setTotalPages={setTotalPages}
                setSort={setSort}
                sort={sort}
                setTotalElements={setTotalElements}
            />
            <PaginationControl
                totalElements={totalElements}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                setLoadData={setLoadData}
            />

        </ContentContainer>
    </>
}