import PopupDelete from "../../../component/PopupDelete/PopupDelete";


const PopupDeleteClima = ({
                    baseUrl,
                    setLoadData,
                    item

}) => {
    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de Condição Climática"} id={item.id}
        >

        <div className="mt-3 mb-3">
            <b>Vitalidade do Solo:  </b>
            <>{item.vitalidadeSolo + "%"}</>
            <br/>
            <b>Pluviosidade:  </b>
            <>{item.pluviosidade}</>
            <br/>
            <b>Temperatura: </b>
            <>{item.temperatura + "°"}</>
            <br/>
            <b>Nome do Estado:  </b>
            <>{item.estado.nome}</>
            <br/>
            <b>Tipo de Produto: </b>
            <>{item.produto.tipo}</>
            <br/>
            <b>Variedade do Produto: </b>
            <>{item.produto.variedade}</>
            <br/>
        </div>

            <p className="mb-3">Você deseja realmente excluir essas condições climáticas?</p>

        </PopupDelete>
    )
}
export default PopupDeleteClima