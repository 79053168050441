import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import NavBar from "./component/NavBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AuthProvider} from "./auth/AuthProvider";
import Login from "./page/Login/Login";
import Logout from "./page/Logout/Logout";
import ForgetPassword from "./page/ForgetPassword/ForgetPassword";
import ResetPassword from "./page/ResetPassword/ResetPassword";
import ChangePassword from "./page/ChangePassword/ChangePassword";
import ContactUs from "./page/ContactUs/ContactUs";
import Cprs from "./page/Cprs";
import Cars from "./page/Cars/Cars";
import {PrivateRoute} from "./auth/PrivateRoute";
import HomePage from "./page/HomePage/HomePage";
import {PrivateAdminRoute} from "./auth/PrivateAdminRoute";
import Estados from "./page/Estados";
import Cidades from "./page/Cidades";
import Produtos from "./page/Produtos";
import Clima from "./page/Clima";
import Users from "./page/Users";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import CalendarioPlantio from "./page/CalendarioPlantio";
import TokenValidator from "./component/TokenValidator";
import Emitente from "./page/Emitente";
import Credor from "./page/Credor";
import Noticias from "./page/Noticias";
import CprAlert from "./page/Cprs/components/CprAlert";
import MonitoringField from "./page/Cprs/components/MonitoringField";
import JobManage from "./page/JobManage/JobManage";
import Alarmes from './page/Alarmes';

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>

                    <NavBar/>
                    <TokenValidator/>
                    <Routes>
                        {/* Rotas privadas */}
                        <Route exact path='/' element={<PrivateRoute/>}>
                            <Route path="/logout"
                                   element={<Logout pathToRedirect={'/login'}/>}/>
                            <Route path="/logged"
                                   element={<p className='text-center mt-2'><br/><br/>Usuário logado</p>}/>
                            <Route path="/cprs" element={<Cprs/>}/>
                            <Route path="/cars" element={<Cars/>}/>
                            <Route path="/cpralert" element={<CprAlert/>}/>
                            <Route path="/" element={<HomePage/>}/>
                        </Route>

                        {/* Rotas para admin */}
                        <Route exact path='/' element={<PrivateAdminRoute/>}>
                            <Route path="/jobs" element={<JobManage/>}/>
                            <Route path="/alarmes" element={<Alarmes/>}/>
                            <Route path="/emitentes" element={<Emitente/>}/>
                            <Route path="/credores" element={<Credor/>}/>
                            <Route path="/estados" element={<Estados/>}/>
                            <Route path="/cidades" element={<Cidades/>}/>
                            <Route path="/produtos" element={<Produtos/>}/>
                            <Route path="/condicoesideais" element={<Clima/>}/>
                            <Route path="/users" element={<Users/>}/>
                            <Route path="/calendario" element={<CalendarioPlantio/>}/>
                            <Route path="/monitoringfield/:id" element={<MonitoringField/>}/>
                        </Route>

                        <Route path="/login" element={<Login/>}/>
                        <Route path="/forget-password" element={<ForgetPassword/>}/>
                        <Route path="/forget-password/:resetToken"
                               element={<ResetPassword/>}/>

                        <Route path="/change-password" element={<ChangePassword/>}/>
                        <Route path="/noticias" element={<Noticias />}/>
                        <Route path="/contact-us" element={<ContactUs/>}/>
                        <Route path="*" element={<Navigate to='/login'/>}/>
                    </Routes>
                </LocalizationProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
