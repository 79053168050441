import React, { useState } from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsCidade from "./InputsCidade";

const PopupEditCidade = ({ item, baseUrl, setLoadData }) => {
    const [id, setId] = useState("");
    const [nome, setNome] = useState("");
    const [estadoId, setEstadoId] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");

    const [blockSubmit, setBlockSubmit] = useState(false);

    const inputs = (
        <InputsCidade
            nome={nome}
            setNome={setNome}
            estadoId={estadoId}
            setEstadoId={setEstadoId}
            setErrorColumn={setErrorColumn}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            errorColumn={errorColumn}
        />
    );

    const itemToState = () => {
        setId(item.id); // Assuming item has an 'id' property
        setNome(item.nome);
        setEstadoId(item.estado.id);
        setErrorMessage("");
        setErrorColumn("");
        setBlockSubmit(false);
    };

    const title = "Editar Cidade";

    const returnData = () => {
        console.log({
            nome,
            estadoId
        });
        return {
            nome,
            estadoId
        };
    };

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + id}
                setLoadData={setLoadData}
                inputs={inputs}
                title={title}
                returnData={returnData}
                itemToState={itemToState}
                setErrorMessage={setErrorMessage}
                setErrorColumn={setErrorColumn}
            />
        </>
    );
};
export default PopupEditCidade;
