import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../utils/ApplyRegex";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";


export default function FilterFormCidades({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [nome, setNome] = useState('');
    const [estadoId, setEstadoId] = useState('');

    const [estadoList, setEstadoList] = useState([])
    const baseUrl = `${ApiUrl}`

    const handlesSubmit = (e) => {

        e.preventDefault();

        setFilter({nome, estado: estadoId});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNome('')
        setEstadoId('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <TextField
                label="Cidade"
                inputProps={{
                    maxLength: 40
                }}
                value={nome}
                onChange={(e) => {
                    setNome(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ ])/g))
                }}
                fullWidth
                autoFocus
            />

            <SelectInput
                url={baseUrl + '/estado?size=2000'}
                list={estadoList} set={setEstadoList}
                setObj={setEstadoId}
                returnList={() => {
                    let returnList = []
                    estadoList.forEach((item) => {
                        returnList.push({value: item.id, label: item.nome})
                    })
                    return returnList
                }}
                placeholder={"Estado"}
                defaultValue={estadoId?.nome}
                fullWidth
            />

        </FilterForm>
    </>
}