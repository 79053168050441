import LargeModal from "../../../component/LargeModal/LargeModal";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import DefaultProblemMessage from "../../../component/DefaultProblemMessage/DefaultProblemMessage";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import KMLUploader from "./KMLUploader";
import axios from "axios";

export default function PopupImportKml({ cprId, setLoadData }) {

    const [lgShow, setLgShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [sendingData, setSendingData] = useState(false);
    const [error, setError] = useState(false);
    const [errorKml, setErrorKml] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState(false);
    const [needToReload, setNeedToReload] = useState(false);
    const [polygonsData, setPolygonsData] = useState([]);
    const [file, setFile] = useState(null);

    const title = 'Importar KML/KMZ'

    const buttonText = <>
        <PublishIcon fontSize="medium"/>
        &nbsp; Importar KML/KMZ
    </>

    const iconSpan = <>
        <PublishIcon fontSize="large"/><br />
        <h3>{title}</h3>
    </>

    const calculatePolygonArea = (coordinates) => {
        if (coordinates.length < 3) {
            return null;
        }
        const areaInSquareMeters = window.google.maps.geometry.spherical.computeArea(coordinates);
        return areaInSquareMeters / 10000
    };

    const inputs = <>
        <KMLUploader
            onKMLUpload={(kmlData) => {
                setPolygonsData(kmlData);
                // calculatePolygonArea(kmlData);
            }}
            file={file} setFile={setFile}
            calculatePolygonArea={calculatePolygonArea}
            error={errorKml} setError={setErrorKml}
            //Herdando componentes
            onFileSelect={(file) => setArquivoSelecionado(!!file)}
        />
    </>

    const retry = () => {
        setError(false)
        setLoaded(false)
        setNeedToReload(false)
        setSendingData(false)
        setErrorMsg('')
        // Limpa o arquivo quando clicar em "Importar outro arquivo"
        setArquivoSelecionado(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSendingData(true)

        const filenameData = file.split(/\.kml|\.kmz/);
        console.log(filenameData[0])

        axios.post(`${process.env.REACT_APP_SHAPEFILE_BUILDER_LINK}/shapefile/downloadShapefile`, {
            polygonsData,
            cprId,
            filenameData: filenameData[0],
        }, {
            responseType: 'blob',
        })
            .then(response => {
                setLoaded(true);
            })
            .catch(error => {
                setError(true);
            })
            .finally(() => {
                setSendingData(false)
                setNeedToReload(true)
            })
        console.log({
            polygonsData,
            cprId,
            filenameData: filenameData[0]
        })
    }

    useEffect(() => {
        if (!lgShow && needToReload) {
            setLoadData(true)
            setNeedToReload(false)
        }
    }, [needToReload])

    return <LargeModal
        buttonText={buttonText}
        title={title}
        iconSpan={iconSpan}
        lgShow={lgShow} setLgShow={setLgShow}
        buttonClass='dropdown-item'
    >
        <div>
            {sendingData ?
                <BasicLoader height={20} message={"Efetuando transferência..."} /> :
                error ?
                    <>
                        <DefaultProblemMessage message={errorMsg !== '' ? errorMsg : false}/>
                        <div className="form-inputs">
                            <Button onClick={retry}> Tentar novamente </Button>
                        </div>
                    </> :
                    loaded ?
                        <>
                            <DefaultSuccessMessage
                                message={'Arquivo importado com sucesso!'}/>
                            <div className="form-inputs" style={{ marginTop: '3%' }}>
                                <Button onClick={() => {
                                    retry()
                                    setLgShow(false)
                                    setLoadData(true)
                                }} className='btn-secondary'>
                                    Fechar <CloseIcon fontSize="medium"/>
                                </Button> &nbsp;
                                <Button onClick={retry}>
                                    Importar outro arquivo <AddIcon fontSize="medium"/>
                                </Button>
                            </div>
                        </>
                        :
                        <Form onSubmit={handleSubmit} className="form-inputs">
                            {inputs}
                            <Button type="submit" disabled={!arquivoSelecionado || !!errorKml}>
                                {buttonText}
                            </Button>
                        </Form>
            }
        </div>
    </LargeModal>

}
