import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import React, { useEffect, useState } from "react";
import InputsAlarme from "./InputsAlarme";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

export default function PopupAddAlarme({ baseUrl, setLoadData, apiUrl }) {
  const [tipo, setTipo] = useState("");
  const [variedade, setVariedade] = useState("");
  const [caracteristicas, setCaracteristicas] = useState("");
  const [pluviometricidadeMaxima, setPluviometricidadeMaxima] = useState("");
  const [pluviometricidadeMinima, setPluviometricidadeMinima] = useState("");
  const [temperaturaMaxima, setTemperaturaMaxima] = useState("");
  const [temperaturaMinima, setTemperaturaMinima] = useState("");
  const [biomassa, setBiomassa] = useState("");
  const [toleranciaPluvMinima, setToleranciaPluvMinima] = useState(""); // tolerância em dias
  const [toleranciaPluvMaxima, setToleranciaPluvMaxima] = useState("");
  const [toleranciaTempMinima, setToleranciaTempMinima] = useState("");
  const [toleranciaTempMaxima, setToleranciaTempMaxima] = useState("");
  const [toleranciaBiomassa, setToleranciaBiomassa] = useState("");
  const [produtoId, setProdutoId] = useState("");
  // const [estadoId, setEstadoId] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorColumn, setErrorColumn] = useState("");

  const duracaoDias = {
    toleranciaBiomassa,
    toleranciaPluvMaxima,
    toleranciaPluvMinima,
    toleranciaTempMaxima,
    toleranciaTempMinima,
  };
  const setDuracaoDias = {
    setToleranciaBiomassa,
    setToleranciaPluvMaxima,
    setToleranciaPluvMinima,
    setToleranciaTempMaxima,
    setToleranciaTempMinima,
  };

//   useEffect(() => {
//     console.log(duracaoDias);
//     console.log("--------------");
//     console.log({ biomassa });
//     console.log({ estadoId });
//   }, [duracaoDias]);

  const inputs = (
    <InputsAlarme
      baseUrl={baseUrl}
      produtoId={produtoId}
      setProdutoId={setProdutoId}
      tipo={tipo}
      setTipo={setTipo}
      // estadoId={estadoId}
      // setEstadoId={setEstadoId}
      biomassa={biomassa}
      setBiomassa={setBiomassa}
      duracaoDias={duracaoDias}
      setDuracaoDias={setDuracaoDias}
      variedade={variedade}
      setVariedade={setVariedade}
      caracteristicas={caracteristicas}
      setCaracteristicas={setCaracteristicas}
      errorMessage={errorMessage}
      errorColumn={errorColumn}
      pluviometricidadeMaxima={pluviometricidadeMaxima}
      pluviometricidadeMinima={pluviometricidadeMinima}
      setPluviometricidadeMaxima={setPluviometricidadeMaxima}
      setPluviometricidadeMinima={setPluviometricidadeMinima}
      setTemperaturaMaxima={setTemperaturaMaxima}
      setTemperaturaMinima={setTemperaturaMinima}
      temperaturaMaxima={temperaturaMaxima}
      temperaturaMinima={temperaturaMinima}
    />
  );

  const icon = <AccessAlarmIcon fontSize="large" id="icon-svg" />;

  const clearForm = () => {
    setErrorMessage(false);
    setErrorColumn(false);
    setVariedade("");
    setTipo("");
    setCaracteristicas("");
    setPluviometricidadeMaxima("");
    setPluviometricidadeMinima("");
    setTemperaturaMinima("");
    setTemperaturaMaxima("");
    // setEstadoId("");
    setProdutoId("");
    setBiomassa("");
    setToleranciaPluvMinima("");
    setToleranciaPluvMaxima("");
    setToleranciaTempMaxima("");
    setToleranciaTempMinima("");
    setToleranciaBiomassa("");
  };

  const returnData = () => {
    return {
      diasAcimaTemperatura: duracaoDias.toleranciaTempMaxima,
      diasAbaixoTemperatura: duracaoDias.toleranciaTempMinima,
      diasAcimaPluviosidade: duracaoDias.toleranciaPluvMaxima,
      diasAbaixoPluviosidade: duracaoDias.toleranciaPluvMinima,
      temperaturaMinima: temperaturaMinima,
      temperaturaMaxima: temperaturaMaxima,
      pluviometricidadeMinima: pluviometricidadeMinima,
      pluviometricidadeMaxima: pluviometricidadeMaxima,
      biomassaAbaixo: biomassa,
      diasAbaixoBiomassa: duracaoDias.toleranciaBiomassa,
      // estadoId: estadoId,
      produtoId: produtoId
    }

  };

  return (
    <PopupAdd
      baseUrl={baseUrl}
      setLoadData={setLoadData}
      inputs={inputs}
      title={"Adicionar Alarme"}
      icon={icon}
      clearForm={clearForm}
      returnData={returnData}
      setErrorMessage={setErrorMessage}
      setErrorColumn={setErrorColumn}
    />
  );
}
