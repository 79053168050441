import React, { useState } from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsAlarme from "./InputsAlarme";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const PopupEditAlarme = ({ item, baseUrl, setLoadData, apiUrl }) => {
  const [id, setId] = useState("");
  const [tipo, setTipo] = useState("");
  const [variedade, setvariedade] = useState("");
  // const [estadoId, setEstadoId] = useState("");
  const [biomassa, setBiomassa] = useState("");
  const [temperaturaMaxima, setTemperaturaMaxima] = useState("");
  const [temperaturaMinima, setTemperaturaMinima] = useState("");
  const [pluviometricidadeMaxima, setPluviometricidadeMaxima] = useState("");
  const [pluviometricidadeMinima, setPluviometricidadeMinima] = useState("");
  const [caracteristicas, setCaracteristicas] = useState("");
  const [toleranciaPluvMinima, setToleranciaPluvMinima] = useState(""); // tolerância em dias
  const [toleranciaPluvMaxima, setToleranciaPluvMaxima] = useState("");
  const [toleranciaTempMinima, setToleranciaTempMinima] = useState("");
  const [toleranciaTempMaxima, setToleranciaTempMaxima] = useState("");
  const [toleranciaBiomassa, setToleranciaBiomassa] = useState("");
  const [produtoId, setProdutoId] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorColumn, setErrorColumn] = useState("");

  const duracaoDias = {
    toleranciaBiomassa,
    toleranciaPluvMaxima,
    toleranciaPluvMinima,
    toleranciaTempMaxima,
    toleranciaTempMinima,
  };
  const setDuracaoDias = {
    setToleranciaBiomassa,
    setToleranciaPluvMaxima,
    setToleranciaPluvMinima,
    setToleranciaTempMaxima,
    setToleranciaTempMinima,
  };

  const title = "Editar Alarme";
  const buttonText = (
    <>
      <DriveFileRenameOutlineIcon fontSize="medium" />
      &nbsp;{title}
    </>
  );

  const inputs = (
    <InputsAlarme
      baseUrl={baseUrl}
      tipo={tipo}
      setTipo={setTipo}
      // estadoId={estadoId}
      // setEstadoId={setEstadoId}
      produtoId={produtoId}
      setProdutoId={setProdutoId}
      biomassa={biomassa}
      setBiomassa={setBiomassa}
      setDuracaoDias={setDuracaoDias}
      duracaoDias={duracaoDias}
      variedade={variedade}
      setVariedade={setvariedade}
      caracteristicas={caracteristicas}
      setCaracteristicas={setCaracteristicas}
      temperaturaMinima={temperaturaMinima}
      temperaturaMaxima={temperaturaMaxima}
      setTemperaturaMinima={setTemperaturaMinima}
      setTemperaturaMaxima={setTemperaturaMaxima}
      pluviometricidadeMinima={pluviometricidadeMinima}
      pluviometricidadeMaxima={pluviometricidadeMaxima}
      setPluviometricidadeMinima={setPluviometricidadeMinima}
      setPluviometricidadeMaxima={setPluviometricidadeMaxima}
      setErrorColumn={setErrorColumn}
      setErrorMessage={setErrorMessage}
      errorMessage={errorMessage}
      errorColumn={errorColumn}
      isEdit={true}
      alarmeId={item.id}
    />
  );
  const itemToState = () => {
    setId(item.id);
    setProdutoId(item.produto.id)
    setTipo(item.produto.tipo);
    setPluviometricidadeMinima(item.pluviometricidadeMinima);
    setPluviometricidadeMaxima(item.pluviometricidadeMaxima);
    setTemperaturaMinima(item.temperaturaMinima);
    setTemperaturaMaxima(item.temperaturaMaxima);
    setBiomassa(item.biomassaAbaixo);
    // setEstadoId(item.estado.id);
    setToleranciaBiomassa(item.diasAbaixoBiomassa);
    setToleranciaPluvMaxima(item.diasAcimaPluviosidade);
    setToleranciaPluvMinima(item.diasAbaixoPluviosidade);
    setToleranciaTempMaxima(item.diasAcimaTemperatura);
    setToleranciaTempMinima(item.diasAbaixoTemperatura);
  };

  const returnData = () => {
    return {
      diasAcimaTemperatura: duracaoDias.toleranciaTempMaxima,
      diasAbaixoTemperatura: duracaoDias.toleranciaTempMinima,
      diasAcimaPluviosidade: duracaoDias.toleranciaPluvMaxima,
      diasAbaixoPluviosidade: duracaoDias.toleranciaPluvMinima,
      temperaturaMinima: temperaturaMinima,
      temperaturaMaxima: temperaturaMaxima,
      pluviometricidadeMinima: pluviometricidadeMinima,
      pluviometricidadeMaxima: pluviometricidadeMaxima,
      biomassaAbaixo: biomassa,
      diasAbaixoBiomassa: duracaoDias.toleranciaBiomassa,
      // estadoId: estadoId,
      produtoId: produtoId,
    };
  };

  return (
    <>
      <PopupEdit
        baseUrl={baseUrl + "/" + item.id}
        setLoadData={setLoadData}
        inputs={inputs}
        title={title}
        returnData={returnData}
        itemToState={itemToState}
        setErrorMessage={setErrorMessage}
        setErrorColumn={setErrorColumn}
        buttonClass="dropdown-item"
        buttonText={buttonText}
      />
    </>
  );
};
export default PopupEditAlarme;
