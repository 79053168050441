import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import React from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";


const ClimaTable = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            fontWeight: 'bold', // adiciona negrito ao cabeçalho
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14, // Tamanho da fonte
            borderBottom: '1px solid rgba(224, 224, 224, 1)', // Adiciona uma linha separadora entre as células
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const TableWrapper = styled(TableContainer)({
        border: '1px solid rgba(0, 0, 0, 0.1)', // Adiciona uma borda à tabela
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', // Adiciona uma sombra à tabela
    });

    function createData(day, temperature, coveredCloud, precipitation, moisture, condensation) {
        return { day, temperature, coveredCloud, precipitation, moisture, condensation };
    }


    const rows = [
        createData('21.02.2024', <div>24°C <br/> (Min. 22°C Máx. 28°C)</div>, <div><CloudIcon/> 100%</div>, '12,24 mm',
            <div><WaterDropIcon/> 90% </div>, '22\n' +
            '°C' ),
        createData('20.02.2024', <div>26°C <br/> (Min. 23°C Máx. 30°C)</div>, <div><CloudIcon/> 90%</div>, '3 mm', <div>
            <WaterDropIcon/> 87% </div>,'23\n' +
            '°C' ),
        createData('19.02.2024', <div>25°C <br/> (Min. 23°C Máx. 28°C)</div>, <div><CloudIcon/> 99% </div>, '11,34 mm',
            <div><WaterDropIcon/> 94% </div>, '24\n' +
            '°C' ),
        createData('18.02.2024', <div>25°C <br/> (Min. 24°C Máx. 29°C)</div>, <div><CloudIcon/> 94% </div>, '9,53 mm',
            <div><WaterDropIcon/> 94% </div>, '24\n' +
            '°C' ),
        createData('17.02.2024', <div>26°C <br/> (Min. 24°C Máx. 29°C)</div>, <div><CloudIcon/> 94% </div>, '0,56 mm',
            <div><WaterDropIcon/> 90% </div>, '24\n' +
            '°C'),
        createData('16.02.2024', <div>26°C <br/> (Min. 23°C Máx. 32°C)</div>, <div><CloudIcon/> 97% </div>, '11,18 mm',
            <div><WaterDropIcon/> 89% </div>, '24\n' +
            '°C'),
        createData('15.02.2024', <div>26°C <br/> (Min. 23°C Máx. 32°C)</div>, <div><CloudIcon/> 87% </div>, '2,99 mm',
            <div><WaterDropIcon/> 87% </div>,'23\n' +
            '°C' ),
        createData('14.02.2024', <div>27°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 83% </div>, '1 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('13.02.2024', <div>26°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 89% </div>, '11,54 mm',
            <div><WaterDropIcon/> 88% </div>, '24\n' +
            '°C'),
        createData('12.02.2024', <div>25°C <br/> (Min. 23°C Máx. 28°C)</div>, <div><CloudIcon/> 100% </div>, '9,37 mm',
            <div><WaterDropIcon/> 96% </div>,'24\n' +
            '°C' ),
        createData('11.02.2024', <div>27°C <br/> (Min. 24°C Máx. 31°C)</div>, <div><CloudIcon/> 78% </div>, '11,78 mm',
            <div><WaterDropIcon/> 85% </div>,'24\n' +
            '°C' ),
        createData('10.02.2024', <div>27°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 82% </div>, '0,44 mm',
            <div><WaterDropIcon/> 83% </div>, '23\n' +
            '°C'),
        createData('9.02.2024', <div>25°C <br/> (Min. 24°C Máx. 28°C)</div>, <div><CloudIcon/> 98% </div>, '5,6 mm',
            <div><WaterDropIcon/> 92% </div>, '24\n' +
            '°C'),
        createData('8.02.2024', <div>27°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 91% </div>, '15,28 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('7.02.2024', <div>27°C <br/> (Min. 23°C Máx. 33°C)</div>, <div><CloudIcon/> 59% </div>, '0,18 mm',
            <div><WaterDropIcon/> 84% </div>, '23\n' +
            '°C'),
        createData('6.02.2024', <div>26°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 90% </div>, '14,76 mm',
            <div><WaterDropIcon/> 87% </div>, '24\n' +
            '°C'),
        createData('5.02.2024', <div>27°C <br/> (Min. 23°C Máx. 33°C)</div>, <div><CloudIcon/> 82% </div>, '19,33 mm',
            <div><WaterDropIcon/> 83% </div>, '23\n' +
            '°C'),
        createData('4.02.2024', <div>26°C <br/> (Min. 24°C Máx. 30°C)</div>, <div><CloudIcon/> 50% </div>, '1,79 mm',
            <div><WaterDropIcon/> 87% </div>, '24\n' +
            '°C'),
        createData('3.02.2024', <div>27°C <br/> (Min. 23°C Máx. 33°C) </div>, <div><CloudIcon/> 22% </div>, '0 mm ',
            <div><WaterDropIcon/> 82% </div>, '23\n' +
            '°C'),
        createData('2.02.2024', <div>26°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 82% </div>, '24,15 mm',
            <div><WaterDropIcon/> 87% </div>, '23\n' +
            '°C'),
        createData('1.02.2024', <div>25°C <br/> (Min. 23°C Máx. 29°C)</div>, <div><CloudIcon/> 100% </div>, '73,72 mm',
            <div><WaterDropIcon/> 94% </div>, '24\n' +
            '°C'),
        createData('31.01.2024', <div>26°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 98% </div>, '45,49 mm',
            <div><WaterDropIcon/> 91% </div>, '24\n' +
            '°C'),
        createData('30.01.2024', <div>27°C <br/> (Min. 23°C Máx. 32°C)</div>, <div><CloudIcon/> 71% </div>, '2,66 mm ',
            <div><WaterDropIcon/> 82% </div> , '23\n' +
            '°C'),
        createData('29.01.2024', <div>27°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 88% </div>, '15,03 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('28.01.2024', <div>26°C <br/> (Min. 24°C Máx. 30°C)</div>, <div><CloudIcon/> 97% </div>, '71,97 mm',
            <div><WaterDropIcon/> 91% </div>, '24\n' +
            '°C'),
        createData('27.01.2024', <div>26°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 87% </div>, '1,67 mm ',
            <div><WaterDropIcon/> 84% </div>, '\n' +
            'Máx. 6 km/h\n' +
            '23\n' +
            '°C'),
        createData('26.01.2024', <div>28°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 84% </div>, '0,02 mm ',
            <div><WaterDropIcon/> 76% </div>, '23\n' +
            '°C'),
        createData('25.01.2024', <div>28°C <br/> (Min. 24°C Máx. 34°C)</div>, <div><CloudIcon/> 74% </div>, '0,32 mm ',
            <div><WaterDropIcon/> 76% </div>, '23\n' +
            '°C'),
        createData('24.01.2024', <div>27°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 80% </div>, '0,07 mm ',
            <div><WaterDropIcon/> 82% </div>, '23\n' +
            '°C'),
        createData('23.01.2024', <div>28°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 87% </div>, '2,65 mm ',
            <div><WaterDropIcon/> 79% </div>, '23\n' +
            '°C'),
        createData('22.01.2024', <div>27°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 63% </div>, '1,16 mm ',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
    ];


    return(
        <>
            <div>
                <br/>
                <h5 className='text-center'>
                    Clima
                </h5>
                <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><b>Dia </b></StyledTableCell>
                                <StyledTableCell align="right"> <b>Temperatura </b></StyledTableCell>
                                <StyledTableCell align="right"> <b>Nuvem Cob.&nbsp;(%) </b></StyledTableCell>
                                <StyledTableCell align="right"> <b>Precipitação&nbsp;(mm) </b></StyledTableCell>
                                <StyledTableCell align="right"> <b>Umidade&nbsp;(%) </b></StyledTableCell>
                                <StyledTableCell align="right"> <b>Ponto de <br/> condensação&nbsp;(C) </b></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.day}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.day}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.temperature}</StyledTableCell>
                                    <StyledTableCell align="right">{row.coveredCloud}</StyledTableCell>
                                    <StyledTableCell align="right">{row.precipitation}</StyledTableCell>
                                    <StyledTableCell align="right">{row.moisture}</StyledTableCell>
                                    <StyledTableCell align="right">{row.condensation}</StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
};
export default ClimaTable;