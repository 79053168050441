import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import {Dropdown} from "react-bootstrap";
import CarDetails from "./CarDetails";
import PopupDetails from "../../Cprs/components/PopupDetails";
import PopupDeleteCar from "./PopupDeleteCar";
import PopupEditCar from "./PopupEditCar";
import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";

export default function CarActions({item, baseUrl, setLoadData}) {

    const {user} = useUser()

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <CarDetails item={item}/>
            <PopupDetails
                item={item.cpr}
                baseUrl={`${ApiUrl}`}
            />
            {user.isMaster && <>
                <PopupEditCar
                    item={item} baseUrl={baseUrl} setLoadData={setLoadData}
                />
                <PopupDeleteCar
                    item={item} baseUrl={baseUrl} setLoadData={setLoadData}
                />
            </>}

        </Dropdown.Menu>

    </Dropdown>
}