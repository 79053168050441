import React, { useEffect, useState } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import LargeModal from "../LargeModal/LargeModal";
import { useUser } from "../../auth/AuthProvider";


const PopupEdit = ({
                       baseUrl, setLoadData, inputs,
                       itemToState, title, returnData,
                       setErrorMessage, setErrorColumn,
                       blockSubmit, buttonClass, buttonText,
                       highlighted, className
                   }) => {

    const { user } = useUser()

    // modal control
    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Adicionando estado de carregamento

    useEffect(() => {
        if (!lgShow)
            if (itemToState)
                itemToState()
    }, [lgShow]);


    const iconSpan = <>
        <DriveFileRenameOutlineIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const button = <>
        Editar
        <DriveFileRenameOutlineIcon fontSize="medium"/>
    </>


    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = returnData()

        const headers = {
            Authorization: `Bearer ${user?.token}`
        }

        if (!blockSubmit) {
            setIsLoading(true); // Ativar o estado de isLoading quando o formulário for enviado

            try {
                const response = await axios.put(baseUrl, data, { headers });
                setLoadData(true);
                setLgShow(false);
                if (typeof setErrorColumn == 'function') {
                    setErrorMessage("");
                    setErrorColumn("");
                }
            } catch (error) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(error);
                if (error.response && error.response.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setErrorColumn(error.response.data.column);
                }
            } finally {
                setIsLoading(false); // Desativar o estado de isLoading após o término da solicitação
            }
        }
    }


    return (
        <>
            {/* Botão e popup */}
            <LargeModal
                buttonText={buttonText ? buttonText : button}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonId={"links-action"}
                buttonClass={buttonClass}
                backdrop={'static'}
                highlighted={highlighted}
            >
                <div>
                    <Form onSubmit={handleSubmit} className={className ? className : "form-inputs"}>
                        {inputs}
                        <Button
                            type="submit"
                            disabled={isLoading}
                            className={isLoading ? "btn btn-secondary" : ""}
                        >
                            {isLoading ? "Aguarde..." : "Salvar Alterações"}
                        </Button>
                    </Form>
                </div>


            </LargeModal>
        </>
    )
}

export default PopupEdit
