import ContentContainer from "../../component/ContentContainer";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import ContactUsForm from "./Components/ContactUsForm";
import {ApiUrl} from "../../auth/authMethods";
import axios from "axios";
import displayAlertMsg from "../../component/AlertMsg/displayAlertMsg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";

export default function ContactUs() {

    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            phone,
            email,
            name,
            title,
            description
        }

        setIsLoading(true)
        axios.post(ApiUrl + '/contact-us', data)
            .then(() => {
                setSent(true)
            }).catch(() => {
            setError(displayAlertMsg())
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
        >
            Enviar
        </Button>

    return <ContentContainer>

        {!sent ?
            <ContactUsForm
                phone={phone} setPhone={setPhone}
                email={email} setEmail={setEmail}
                name={name} setName={setName}
                title={title} setTitle={setTitle}
                description={description} setDescription={setDescription}
                button={button} handleSubmit={handleSubmit} error={error}
            /> :
            <Box component="div" sx={{mt: 1}} className="text-center">
                <p style={{color: 'green'}}>
                    <CheckCircleIcon sx={{
                        fontSize: 40,
                        height: '5vw',
                        width: '5vw',
                    }}/><br/>
                    <h4>Sua mensagem foi enviada com sucesso.
                        Aguarde o retorno em um dos contatos informados!</h4>
                </p>
            </Box>
        }


    </ContentContainer>
}