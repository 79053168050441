import FilterForm from "../../../component/FilterForm/FilterForm";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../utils/ApplyRegex";
import InputAdornment from "@mui/material/InputAdornment";
import SelectInput from "../../../component/SelectInput";
import { useUser } from "../../../auth/AuthProvider";
import axios from "axios";
import { ApiUrl } from "../../../auth/authMethods";

export default function FilterFormAlarme({
  filter,
  setFilter,
  setLoadData,
  setPage,
  errorColumn,
  errorMessage,
  baseUrl,
  isEdit,
  alarmeId,
}) {
  const [lgShow, setLgShow] = useState(false);
  const [tipo, setTipo] = useState("");
  const [variedade, setVariedade] = useState("");
  // const [estado, setEstado] = useState("");
  const [biomassa, setBiomassa] = useState("");
  const [temperaturaMaxima, setTemperaturaMaxima] = useState("");
  const [temperaturaMinima, setTemperaturaMinima] = useState("");
  const [pluviometricidadeMaxima, setPluviometricidadeMaxima] = useState("");
  const [pluviometricidadeMinima, setPluviometricidadeMinima] = useState("");
  const [caracteristicas, setCaracteristicas] = useState("");
  const [productsList, setProductsList] = useState("");
  const [produtoId, setProdutoId] = useState("");
  // const [estadoId, setEstadoId] = useState("");
  // const [estadoList, setEstadoList] = useState("");
  const [dataReq, setDataReq] = useState();
  const { user } = useUser();

  const handlesSubmit = (e) => {
    e.preventDefault();

    setFilter({
      pluviometricidadeMinima,
      pluviometricidadeMaxima,
      temperaturaMinima,
      temperaturaMaxima,
      caracteristicas,
      biomassa,
      // estadoId,
      produtoId,
    });
    setLgShow(false);
    setLoadData(true);
    setPage(0);
  };

  const clearFilter = () => {
    setTipo("");
    setVariedade("");
    setPluviometricidadeMinima("");
    setPluviometricidadeMaxima("");
    setTemperaturaMinima("");
    setTemperaturaMaxima("");
    setCaracteristicas("");
    setBiomassa("");
    // setEstado("");
    setProdutoId("");
    setFilter({});
    setLgShow(false);
    setLoadData(true);
    // setEstadoId("");
    setPage(0);
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${user?.token}`,
    };

    axios
      .get(ApiUrl + `/produto/${produtoId}`, { headers })
      .then(function (response) {
        const data = response.data.objectResponse;
        setPluviometricidadeMinima(data.pluviometricidadeMinima);
        setPluviometricidadeMaxima(data.pluviometricidadeMaxima);
        setTemperaturaMinima(data.temperaturaMinima);
        setTemperaturaMaxima(data.temperaturaMaxima);
        setDataReq(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [produtoId]);

  return (
    <>
      <FilterForm
        handleSubmit={handlesSubmit}
        clearFilter={clearFilter}
        lgShow={lgShow}
        setLgShow={setLgShow}
        showSecondaryButton={Object.keys(filter).length !== 0}
      >
        <SelectInput
          url={ApiUrl + "/produto?size=200"}
          list={productsList}
          set={setProductsList}
          setObj={setProdutoId}
          defaultValue={produtoId}
          returnList={() => {
            let returnList = [];
            productsList.forEach((item) => {
              returnList.push({
                value: item.id,
                label: `${item.tipo} - ${item.variedade}`,
              });
            });
            return returnList;
          }}
          placeholder={"Produto"}
          fullWidth
        />
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            label="Pluviometricidade Mínima"
            inputProps={{
              maxLength: 5,
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={pluviometricidadeMinima}
            onChange={(e) => {
              if (e.target.value.length <= 5) {
                setPluviometricidadeMinima(e.target.value);
              }
            }}
            fullWidth
            sx={{ display: "block" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mm(milímetros)</InputAdornment>
              ),
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            label="Pluviometricidade Máxima"
            inputProps={{
              maxLength: 5,
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={pluviometricidadeMaxima}
            onChange={(e) => {
              if (e.target.value.length <= 5) {
                setPluviometricidadeMaxima(e.target.value);
              }
            }}
            fullWidth
            sx={{ display: "block" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mm(milímetros)</InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            label="Temperatura Mínima"
            inputProps={{
              maxLength: 4,
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={temperaturaMinima}
            onChange={(e) => {
              if (e.target.value.length <= 4) {
                setTemperaturaMinima(e.target.value);
              }
            }}
            fullWidth
            sx={{ display: "block" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            label="Temperatura Máxima"
            inputProps={{
              maxLength: 4,
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={temperaturaMaxima}
            onChange={(e) => {
              if (e.target.value.length <= 4) {
                setTemperaturaMaxima(e.target.value);
              }
            }}
            fullWidth
            sx={{ display: "block" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            label="Biomassa Mínima em período de Plantio"
            inputProps={{
              maxLength: 4,
            }}
            type="number"
            errorText="Campo obrigatório!"
            value={biomassa}
            onChange={(e) => {
              if (e.target.value.length <= 3) {
                setBiomassa(e.target.value);
              }
            }}
            fullWidth
            sx={{ display: "block" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>

        {/*<SelectInput*/}
        {/*  url={`${ApiUrl}/estado?size=2000`}*/}
        {/*  list={estadoList}*/}
        {/*  set={setEstadoList}*/}
        {/*  setObj={setEstadoId}*/}
        {/*  returnList={() => {*/}
        {/*    let returnList = [];*/}
        {/*    estadoList.forEach((item) => {*/}
        {/*      returnList.push({*/}
        {/*        value: item.id,*/}
        {/*        label: item.nome + " - " + item.sigla,*/}
        {/*      });*/}
        {/*    });*/}
        {/*    return returnList;*/}
        {/*  }}*/}
        {/*  placeholder={"Estado"}*/}
        {/*  defaultValue={estadoId}*/}
        {/*  fullWidth*/}
        {/*/>*/}
      </FilterForm>
    </>
  );
}
