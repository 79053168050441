import React, {useEffect, useState} from "react";
import FilterForm from "../../../component/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";

const FilterFormCar = ({baseUrl, filter, setFilter, setLoadData, setPage}) => {

    const [lgShow, setLgShow] = useState(false);

    const [registro, setRegistro] = useState("");
    const [dataCadastro, setDataCadastro] = useState("");
    const [nomeImovelRural, setNomeImovelRural] = useState("");

    const [cidadeId, setCidadeId] = useState("");
    const [cidadeList, setCidadeList] = useState([])
    const [cidadeListCurrent, setCidadeListCurrent] = useState([])

    const [estadoId, setEstadoId] = useState("");
    const [estadoList, setEstadoList] = useState([])

    const [moduloFiscal, setModuloFiscal] = useState("");
    const [codigoProtocolo, setCodigoProtocolo] = useState("");


    const handlesSubmit = (e) => {
        if (e) {
            e.preventDefault()
        }

        const filterData = {};

        if (registro !== "") {
            filterData.registro = registro
        }
        if (dataCadastro !== "") {
            filterData.dataCadastro = dataCadastro
        }
        if (nomeImovelRural !== "") {
            filterData.nomeImovelRural = nomeImovelRural
        }
        if (cidadeId !== "") {
            filterData.cidadeId = cidadeId
        }
        if (estadoId !== "") {
            filterData.estadoId = estadoId
        }
        if (moduloFiscal !== "") {
            filterData.moduloFiscal = moduloFiscal
        }
        if (codigoProtocolo !== "") {
            filterData.codigoProtocolo = codigoProtocolo
        }


        setFilter(filterData)

        setLgShow(false)
        setLoadData(true)

        setPage(0);
    }

    useEffect(() => {
        if (estadoId > 0){
            setCidadeListCurrent(cidadeList.filter((cidade) =>
                cidade.estado.id === estadoId
            ))
        }
    }, [estadoId])


    const clearFilter = () => {
        setRegistro("")
        setDataCadastro("")
        setNomeImovelRural("")
        setCidadeId("")
        setEstadoId("")
        setModuloFiscal("")
        setCodigoProtocolo("")

        setFilter({})

        setLgShow(false)
        setLoadData(true)
    }

    return (
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Registro Car"
                error={registro.length > 50}
                helperText={registro.length > 50 ? 'Limite de 50 caracteres excedido' : ''}
                errortext="Campo obrigatório!"
                value={registro}
                onChange={(e) => {
                    if (e.target.value.length <= 50) {
                        setRegistro(e.target.value);
                    }
                }}
                fullWidth
                inputProps={{maxLength: 50}}
                autoFocus
            />
            <TextField
                label="Data de Cadastro"
                type="date"
                errortext="Campo obrigatório!"
                value={dataCadastro}
                onChange={(e) => setDataCadastro(e.target.value)}
                fullWidth
                InputLabelProps={{shrink: true}} //mantem o texto encima
                inputProps={{
                    min: "1970-01-01",
                    max: "2100-12-31"
                }}
            />
            <TextField
                label="Nome do Imóvel Rural"
                error={!/^[A-Za-z\s]*$/.test(nomeImovelRural)} // Verifica se a entrada contém apenas letras e espaços
                helperText={!/^[A-Za-z\s]*$/.test(nomeImovelRural) ? 'Somente letras e espaços são permitidos' : ''}
                value={nomeImovelRural}
                onChange={(e) => {
                    if (/^[A-Za-z\s]*$/.test(e.target.value) || e.target.value === "") {
                        setNomeImovelRural(e.target.value);
                    }
                }}
                fullWidth
                inputProps={{maxLength: 50, pattern: '^[A-Za-z\\s]*$'}} // Aceita apenas letras e espaços
            />
            <SelectInput
                url={ApiUrl + '/estado?size=2000'}
                list={estadoList} set={setEstadoList}
                setObj={setEstadoId}
                returnList={() => {
                    let returnList = []
                    estadoList.forEach((item) => {
                        returnList.push({value: item.id, label: item.nome})
                    })
                    return returnList
                }}
                placeholder={"Estado"}
                defaultValue={estadoId}
                fullWidth
            />
            <SelectInput
                url={ApiUrl + '/cidade?size=20000'}
                list={cidadeListCurrent}
                set={(list) => {
                    setCidadeList(list)
                    setCidadeListCurrent(list)
                }}
                defaultValue={cidadeId}
                setObj={setCidadeId}
                returnList={() => {
                    let returnList = []
                    cidadeListCurrent.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nome} - ${item.estado.sigla}`})
                    })
                    return returnList
                }}
                placeholder={"Cidade"}
                fullWidth
            />
            <TextField
                label="Módulo Fiscal"
                value={moduloFiscal}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remover caracteres que não são números, vírgulas ou pontos
                    const cleanedValue = inputValue.replace(/[^0-9.,]/g, '');
                    setModuloFiscal(cleanedValue);
                }}
                fullWidth
                inputProps={{
                    maxLength: 20,
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />
            <TextField
                label="Código do Protocolo"
                error={codigoProtocolo.length > 40}
                helperText={codigoProtocolo.length > 40 ? 'Limite de 40 caracteres excedido' : ''}
                errortext="Campo obrigatório!"
                value={codigoProtocolo}
                onChange={(e) => {
                    if (e.target.value.length <= 40) {
                        setCodigoProtocolo(e.target.value);
                    }
                }}
                fullWidth
                inputProps={{maxLength: 40}}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />
        </FilterForm>
    )

}
export default FilterFormCar