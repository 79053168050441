import React, { useEffect, useState } from "react";
import BiomassaGrafico from "../../../component/Dashboard/BiomassaGrafico";
import ClimaTable from "../../../component/Clima/ClimaTable";
import CardClimaAlert from "../../../component/CardCprAlert/CardClimaAlert";
import LargeModal from "../../../component/LargeModal/LargeModal";
import { useUser } from "../../../auth/AuthProvider";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { ApiUrl } from "../../../auth/authMethods";
import { normalizeTimestemp } from "../../../utils/normalizeTimestemp";
import { numeroOrdemMask } from "../../../utils/numeroOrdemMask";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import ErrorIcon from "@mui/icons-material/Error";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const CprAlert = ({
  item,
  setLoadData,
  textButton,
  biomassa,
  statusBiomassa,
  cprData,
}) => {
  const { user } = useUser();
  const [lgShow, setLgShow] = useState(false);
  const [needToReload, setNeedToReload] = useState(false);
  const [cpr, setCpr] = useState("");
  const [arquivos, setArquivos] = useState("");
  const [messageText, setMessageText] = useState("");
  const [icon, setIcon] = useState("");


  useEffect(() => {
    if (
        cprData.statusTemperatura === "ESTAVEL" &&
        cprData.statusPluviosidade === "ESTAVEL" &&
        cprData.statusBiomassa === "ESTAVEL"
    ) {
      setMessageText("Campo está Estável!");
      setIcon(
          <CheckBoxIcon
              className="iconTitle"
              style={{color: "rgb(119, 221, 119)", marginTop: "-7px"}}
          />
      );
    } else if (statusBiomassa === "NEUTRO" && cprData.statusTemperatura === "NEUTRO" && cprData.statusPluviosidade === "NEUTRO") {
      setMessageText("Campo está NEUTRO!");
      setIcon(
          <VerifiedUserIcon
              className="iconTitle"
              style={{color: "rgb(128, 128, 128)"}}
          />
      );
    } else if (
        cprData.statusTemperatura === "RISCO" ||
        cprData.statusPluviosidade === "RISCO" ||
        cprData.statusBiomassa === "RISCO"
    ) {
      const statusCprProblems = [];
      if (cprData.statusBiomassa === "RISCO") {
        statusCprProblems.push("biomassa");
      }
      if (cprData.statusPluviosidade === "RISCO") {
        statusCprProblems.push("pluviosidade");
      }
      if (cprData.statusTemperatura === "RISCO") {
        statusCprProblems.push("temperatura");
      }
      setMessageText(
          `Campo está em RISCO devido à(s): ${
              statusCprProblems.length == 1
                  ? statusCprProblems[0]
                  : statusCprProblems.length == 2
                      ? `${statusCprProblems[0]} - ${statusCprProblems[1]}`
                      : `${statusCprProblems[0]} - ${statusCprProblems[1]} - ${statusCprProblems[2]}`
          }`
      );
      setIcon(
          <ErrorIcon
              className="iconTitle"
              style={{color: "rgb(255, 105, 97)", marginTop: "-7px"}}
          />
      );
    } else {
      setMessageText("Status desconhecido");
    }
  }, []);

  useEffect(() => {
    if (!lgShow && needToReload) setLoadData(true);
  }, [lgShow]);

  const button = (
    <>
      <InfoIcon />
      &nbsp; {textButton}
    </>
  );

  useEffect(() => {
    const headersData = {
      Authorization: `Bearer ${user?.token}`,
    };

    axios
      .get(`${ApiUrl}/cpr/get-cpr-by-numero-ordem?numeroOrdem=${item}`, {
        headers: headersData,
      })
      .then(function (response) {
        const newData = response.data;
        setCpr(newData);
      })
      .catch(function (errorReceived) {
        if (errorReceived.response.status === 401) {
          console.log("Sem autorização.");
        } else {
          console.log("Error:", errorReceived);
        }
      });
  }, []);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${user?.token}`,
    };
    axios
      .get(`${ApiUrl}/biomassa/${biomassa.arquivo.id}`, { headers })
      .then(function (response) {
        const arquivos = response.data;

        setArquivos(arquivos);
      })
      .catch(function (errorReceived) {
        console.log(errorReceived);
      })
      .finally(() => {});
  }, []);

  // useEffect(() => {
  //     console.log({cpr})
  //
  // }, [cpr])

  const title = `Monitoramento da CPR: ${
    cpr && cpr.emitentes[0].nome ? cpr.emitentes[0].nome : ""
  }`;

  // const data = [
  //     {
  //         "imagem": "img - 03 fev 2024.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/b9ba5bd5-4565-441c-be77-9c07c9b25a67/thumbnails/vitality.png",
  //         "biomassa": "15%",
  //         "nuvens": "22%",
  //         "dataCriacao": "2024-02-03",
  //         "dataNormalizada": "03 fev 2024"
  //     },
  //     {
  //         "imagem": "img - 04 jan 2024.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/39baa913-9217-4252-93f2-142c565d74e1/thumbnails/vitality.png",
  //         "biomassa": "66%",
  //         "nuvens": "14%",
  //         "dataCriacao": "2024-01-04",
  //         "dataNormalizada": "04 jan 2024"
  //     },
  //     {
  //         "imagem": "img - 26 out 2023.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/22e47ef3-4896-44a2-bd6c-a4d001448d63/thumbnails/vitality.png",
  //         "biomassa": "32%",
  //         "nuvens": "0%",
  //         "dataCriacao": "2023-10-26",
  //         "dataNormalizada": "26 out 2023"
  //     },
  //     {
  //         "imagem": "img - 01 out 2023.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/64b61135-d32a-41ae-9129-2c8dfdaebf3a/thumbnails/vitality.png",
  //         "biomassa": "41%",
  //         "nuvens": "0%",
  //         "dataCriacao": "2023-10-01",
  //         "dataNormalizada": "01 out 2023"
  //     },
  //     {
  //         "imagem": "img - 26 set 2023.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/36e002c9-f052-43ea-8c51-1c89cf06daa4/thumbnails/vitality.png",
  //         "biomassa": "44%",
  //         "nuvens": "0%",
  //         "dataCriacao": "2023-09-26",
  //         "dataNormalizada": "26 set 2023"
  //     },
  //     {
  //         "imagem": "img - 06 set 2023.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/dbaf20d9-d4ae-4f3e-9fc0-313d3be438bc/thumbnails/vitality.png",
  //         "biomassa": "57%",
  //         "nuvens": "38%",
  //         "dataCriacao": "2023-09-06",
  //         "dataNormalizada": "06 set 2023"
  //     },
  //     {
  //         "imagem": "img - 27 ago 2023.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/9ca88abe-28eb-4053-9e60-578e14c6d1de/thumbnails/vitality.png",
  //         "biomassa": "67%",
  //         "nuvens": "2%",
  //         "dataCriacao": "2023-08-27",
  //         "dataNormalizada": "27 ago 2023"
  //     },
  //     {
  //         "imagem": "img - 22 ago 2023.jpg",
  //         "url": "https://tiles.skyfld.com/biomassmap/04a7dd2b-4dce-4795-874d-0e440c133e82/thumbnails/vitality.png",
  //         "biomassa": "65%",
  //         "nuvens": "39%",
  //         "dataCriacao": "2023-08-22",
  //         "dataNormalizada": "22 ago 2023"
  //     }
  // ]

  return (
    <>
      <LargeModal
        buttonText={button}
        lgShow={lgShow}
        setLgShow={setLgShow}
        title={cpr && cpr.emitentes[0].nome ? title : ""}
      >
        {cpr === "" || cpr === undefined ? (
          <div>
            <BasicLoader />
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <strong style={{ marginBottom: "10px" }}>
                <h4>
                  {messageText}
                  {icon}
                </h4>
              </strong>
              <Link
                to={`/monitoringfield/${biomassa.arquivo.id}`}
                target="_blank"
              >
                <Button
                  style={{ alignSelf: "flex-start" }}
                  variant="primary"
                  size="sm"
                >
                  Monitoramento Completo do Campo:{" "}
                  {biomassa.arquivo.nome.split(".zip")[0]}(#
                  {biomassa.arquivo.id})
                </Button>
              </Link>
            </div>
            <div style={{ display: "flex", marginTop: "5vh" }}>
              <CardClimaAlert item={cpr} biomassa={biomassa} />
              <div style={{ width: 1120, marginLeft: 25 }}>
                <h5 className="text-center">Histórico de Biomassa</h5>
                <BiomassaGrafico arquivos={arquivos} />
              </div>
            </div>
            <hr />
            <ClimaTable />
          </div>
        )}
      </LargeModal>
    </>
  );
};
export default CprAlert;
