import TextField from "@mui/material/TextField";
import React, {useEffect, useRef, useState} from "react";
import SelectInput from "../../../component/SelectInput";
import {cnpjMask} from "../../../utils/cnpjMask";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import {cpfMask} from "../../../utils/cpfMask";
import {validadeCnpj} from "../../../utils/validadeCnpj";
import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";
import CheckIcon from "@mui/icons-material/Check";
import {validadeCpf} from "../../../utils/validadeCpf";
import {ApiUrl} from "../../../auth/authMethods";

export default function InputsEmitente({
                                           nome, setNome,
                                           rg, setRg,
                                           required,
                                           cnpj, setCnpj,
                                           cpf, setCpf,
                                           cep, setCep,
                                           logradouro, setLogradouro,
                                           numero, setNumero,
                                           complemento, setComplemento,
                                           bairro, setBairro,
                                           endereco, setEndereco,
                                           cidadeId, setCidadeId,
                                           errorColumn, errorMessage, cidadeNome, setCidadeNome,
                                       }) {

    const baseUrl2 = ApiUrl
    const [cidadeList, setCidadeList] = useState([])
    const inputNumberRef = useRef(null)

    const [errorCep, setErrorCep] = useState(false)
    const [errorCepText, setErrorCepText] = useState(false)
    const [errorCnpj, setErrorCnpj] = useState(false)
    const [errorCnpjText, setErrorCnpjText] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [errorCpfText, setErrorCpfText] = useState(false)
    const [obj, setObj] = useState(false)
    const [message, setMessage] = useState(false)

    const [showAddressInputs, setShowAddressInputs] = useState(cep?.length > 0)
    const [isCnpj, setIsCnpj] = useState();

    const {user} = useUser()

    const handleToggleAddressInputs = () => {
        setCep(null);
        setLogradouro(null);
        setNumero(null);
        setComplemento(null);
        setBairro(null);
        setCidadeId(null);
        setCidadeNome(null);
        setShowAddressInputs((prev) => !prev);
    };


    const toggleSwitch = () => {
        setMessage("")
        setObj("")
        setIsCnpj((prev) => !prev);
    };

    useEffect(() => {
        if (cnpj !== '' && cpf === '') {
            setIsCnpj(false)
        } else if (cnpj === '' && cpf !== '') {
            setIsCnpj(true)
        }
    }, [])


    // Altere a função changeCpf
    const changeCpf = async (cpf) => {
        setMessage("")
        setObj("")
        if (cpf !== undefined) {
            const numericCpf = cpf.replace(/\D/g, '');

            setCpf(numericCpf.replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1-$2'));
        }


        if (!validadeCpf(cpf)) {
            setErrorCpf('cpf')
            setErrorCpfText('Cnpj inválido!')
            // docRef.current.focus();
            // setBlockSubmit(true)
        } else {
            setErrorCpf(false)
            setErrorCpfText('')
            const resp = await axios.get(baseUrl2 + '/emitente/find-cpf?cpf=' + cpf, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                },
            })
            if (resp.data.message) {
                setObj(resp.data.objectResponse)
                setMessage("Emitente já cadastrado no banco!")
            } else {
                setObj({cpf})
            }
        }

        setCnpj(null)
    };

    const changeCnpj = async (cnpj) => {
        setMessage("")
        setObj("")
        if (cnpj !== undefined) {
            const numericCnpj = cnpj.replace(/\D/g, '');

            setCnpj(
                numericCnpj.replace(/(\d{2})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1/$2')
                    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            );
        }

        if (!validadeCnpj(cnpj)) {
            setErrorCnpj('cnpj')
            setErrorCnpjText('Cnpj inválido!')
            // docRef.current.focus();
            // setBlockSubmit(true)
        } else {
            setErrorCnpj(false)
            setErrorCnpjText('')
            const resp = await axios.get(baseUrl2 + '/emitente/find-cnpj?cnpj=' + cnpj, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                },
            })
            if (resp.data.message) {
                setObj(resp.data.objectResponse)
                setMessage("Emitente já cadastrado no banco!")
            } else {
                setObj({cnpj})
            }
        }

        setCpf(null)
    };


    const changeRg = (rg) => {
        if (rg !== undefined) {
            // Remove a pontuação do RG, mantendo apenas os números e letras
            const alphanumericRg = rg.replace(/[^\w]+/g, '');

            setRg(alphanumericRg);
        }
    };

    const buscarCep = async (cepRecebido) => {
        if (validaCep(cepRecebido)) {
            let url = `https://viacep.com.br/ws/${cepRecebido}/json/`

            // const resp = await fetch(url, {mode:'no-cors'})
            const resp = await fetch(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'aplication/json'
                }
            })
            const data = await resp.json()

            if (!data.erro) {
                setEndereco({
                    ...endereco,
                    logradouro: data.logradouro ? data.logradouro : null,
                    bairro: data.bairro ? data.bairro : null,
                    cep: cepRecebido,
                })

                setLogradouro(data.logradouro || null)
                setBairro(data.bairro || null)
                setCidadeNome(data.localidade || null)
                inputNumberRef.current.focus()
            }
        }
    }
    //Validacao do CEP digitado
    const validaCep = (cepRecebido) => {
        if (cepRecebido === undefined)
            return false
        cepRecebido = cepRecebido.replace(/[^0-9]/gi, "");
        return cepRecebido?.length === 8
    }

    // useEffect(() => {
    //     setEstado({
    //     })
    // }, [estado])


    return (
        <>
            <>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="body1">
                        {isCnpj ? 'CPF' : 'CNPJ'}
                    </Typography>
                    <Switch
                        checked={isCnpj}
                        onChange={toggleSwitch}
                        color="primary"
                        inputProps={{'aria-label': 'alternar entre CNPJ e CPF'}}
                    />
                </div>
                {isCnpj ? (

                    <TextField
                        label="CPF"
                        required={true}
                        inputProps={{
                            maxLength: 14,
                            minLength: 14,
                        }}
                        errorText="Campo obrigatório!"
                        error={errorCpf}
                        helperText={errorCpfText}
                        value={cpfMask(cpf)}
                        onChange={(e) => changeCpf(e.target.value)}
                        id="input-cpf"
                        InputLabelProps={{
                            style: {
                                zIndex: 0
                            },
                        }}
                        fullWidth
                        autoFocus
                    />

                ) : (

                    <TextField
                        label="CNPJ"
                        required={true}
                        inputProps={{
                            maxLength: 18,
                            minLength: 18,
                        }}
                        errorText="Campo obrigatório!"
                        error={errorCnpj}
                        helperText={errorCnpjText}
                        value={cnpjMask(cnpj)}
                        onChange={(e) => changeCnpj(e.target.value)}
                        id="input-cnpj"
                        fullWidth
                        InputLabelProps={{
                            style: {
                                zIndex: 0
                            },
                        }}
                        autoFocus
                    />

                )}

                {obj?.id && (obj?.cnpj?.length === 18 || obj?.cpf?.length === 14) && (
                    <p style={{color: 'green'}}>
                        <CheckIcon/> {message}
                    </p>
                )}

                <TextField
                    label="Nome"
                    required={true}
                    inputProps={{
                        maxLength: 80,
                        minLength: 1
                    }}
                    type="text"
                    erortext="Campo obrigatório!"
                    value={nome}
                    onChange={(e) => setNome(e.target.value.replace(/[^a-zA-ZÀ-ÿ\s]/g, ''))}
                    fullWidth
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                />
                <TextField
                    label="RG"
                    required={false}
                    inputProps={{
                        maxLength: 20,
                        minLength: 10,
                    }}
                    type="text"
                    erortext="Campo obrigatório!"
                    value={rg}
                    onChange={(e) => setRg(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                />

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="body1">Adicionar endereço:</Typography>
                    <Switch
                        checked={showAddressInputs}
                        onChange={handleToggleAddressInputs}
                        color="primary"
                        inputProps={{'aria-label': 'alternar para adicionar endereço'}}
                    />
                </div>

                {showAddressInputs &&
                    <>
                        <TextField
                            label="CEP"
                            required={true}
                            inputProps={{
                                maxLength: 8,
                                minLength: 8,
                            }}
                            type="text"
                            errorText="Campo obrigatório!"
                            value={cep}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                setCep(inputValue)
                                buscarCep(inputValue)
                            }}
                            error={errorCep}
                            helperText={errorCepText}
                            fullWidth
                        />

                        <TextField
                            label="Logradouro"
                            required={true}
                            inputProps={{
                                maxLength: 80,
                                minLength: 1
                            }}
                            type="text"
                            erortext="Campo obrigatório!"
                            value={logradouro? logradouro : ''}
                            onChange={(e) => setLogradouro(e.target.value)
                            }
                            fullWidth
                        />
                        <TextField
                            id="input-number"
                            label="Número"
                            required={true}
                            inputProps={{
                                maxLength: 10,
                                minLength: 1
                            }}
                            type="text"
                            erortext="Campo obrigatório!"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)
                            }
                            fullWidth
                            inputRef={inputNumberRef}
                        />
                        <TextField
                            label="Complemento"
                            inputProps={{
                                maxLength: 45
                            }}
                            type="text"
                            erortext="Campo obrigatório!"
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value
                            )}
                            fullWidth
                        />
                        <TextField
                            label="Bairro"
                            required={true}
                            inputProps={{
                                maxLength: 80,
                                minLength: 1
                            }}
                            type="text"
                            erortext="Campo obrigatório!"
                            value={bairro? bairro : ''}
                            onChange={(e) => setBairro(e.target.value.replace(/[^a-zA-ZÀ-ÿ\s]/g, ''))}
                            fullWidth
                            helperText='Digite o nome da cidade para filtrar as opções (A consulta pode demorar um pouco!)'
                        />
                        <SelectInput
                            url={ApiUrl + '/cidade?size=20000'}
                            list={cidadeList}
                            set={setCidadeList}
                            setObj={setCidadeId}
                            defaultValue={cidadeId}
                            returnList={() => {
                                let returnList = []
                                cidadeList.forEach((item) => {
                                    returnList.push({value: item.id, label: `${item.nome} - ${item.estado.sigla}`})
                                })
                                return returnList
                            }}
                            placeholder={"Cidade *"}
                            fullWidth
                        />
                    </>
                }
            </>

        </>
    )

}