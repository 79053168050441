import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputUsersEdit from "./InputUsersEdit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {ApiUrl} from "../../../auth/authMethods";


const PopupEditUsers = ({item, setLoadData}) => {

    const id = item.id

    const [username, setUsername] = useState(item.username);
    const [email, setEmail] = useState(item.email);
    const [roleId, setRoleId] = useState(item.roles[0].id)
    const [isEnabled, setIsEnabled] = useState(item.isEnabled);
    const [isCredentialsNonExpired, setIsCredentialsNonExpired] = useState(item.isCredentialsNonExpired);
    const [isAccountNonExpired, setIsAccountNonExpired] = useState(item.isAccountNonExpired);
    const [isAccountNonLocked, setIsAccountNonLocked] = useState(item.isAccountNonLocked);
    const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(item.isTwoFactorEnabled);


    const [roleList, setRoleList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [blockSubmit, setBlockSubmit] = useState("")

    const inputs = <InputUsersEdit
        id={id}
        username={username} setUsername={setUsername}
        email={email} setEmail={setEmail}
        roles={roleId} setRoles={setRoleId}
        isEnabled={isEnabled} setIsEnabled={setIsEnabled}
        isCredentialsNonExpired={isCredentialsNonExpired} setIsCredentialsNonExpired={setIsCredentialsNonExpired}
        isAccountNonExpired={isAccountNonExpired} setIsAccountNonExpired={setIsAccountNonExpired}
        isAccountNonLocked={isAccountNonLocked} setIsAccountNonLocked={setIsAccountNonLocked}
        isTwoFactorEnabled={isTwoFactorEnabled} setIsTwoFactorEnabled={setIsTwoFactorEnabled}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
        roleList={roleList} setRoleList={setRoleList}
        setBlockSubmit={setBlockSubmit}
    />


    const title = "Editar Usuário"

    const returnData = () => {
        return {
            username,
            email,
            roleId,
            isEnabled,
            isCredentialsNonExpired,
            isAccountNonExpired,
            isAccountNonLocked,
            isTwoFactorEnabled
        }
    }

    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp; {title}
    </>

    return (
        <>
            <PopupEdit
                baseUrl={ApiUrl + '/user/' + id} setLoadData={setLoadData}
                inputs={inputs} title={title} returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                buttonClass='dropdown-item' buttonText={buttonText}
                blockSubmit={blockSubmit}
            />
        </>
    )
}
export default PopupEditUsers