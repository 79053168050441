import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import FilterFormCprs from "./FilterFormCprs";
import {useUser} from "../../../auth/AuthProvider";
import PopupAddCprs from "./PopupAddCprs";
import CprActions from "./CprActions";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import {numeroOrdemMask} from "../../../utils/numeroOrdemMask";

const ListCprs = ({
                      baseUrl, loadData,
                      setLoadData, size, page,
                      setTotalPages, setTotalElements,
                      sort, setSort, updateCode, setPage
                  }) => {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})


    const titles = [
        "Número de Ordem",
        "Data de Vencimento",
        "Data de Emissão",
        "Produto",
        "Quantidade de Produto",
        "Nome(s) do(s) Emitente(s)",
        "Nome(s) do(s) Credor(es)",
    ]


    return (
        <>
            <FilterFormCprs baseUrl={baseUrl} filter={filter} setFilter={setFilter} setLoadData={setLoadData}
                            setPage={setPage}/>
            {user?.isMaster &&
                <PopupAddCprs baseUrl={baseUrl} setLoadData={setLoadData}/>
            }
            <TableList
                titles={titles} baseUrl={baseUrl + '/cpr'}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{numeroOrdemMask(item.numeroOrdem.toString())}</td>
                        <td>{normalizeTimestemp(item.dataVencimentoCedula)}</td>
                        <td>{normalizeTimestemp(item.dataEmissaoCedula)}</td>
                        <td>{item.produto.tipo}</td>
                        <td>{item.quantidadeProduto?
                            <>{numberWithPoints(item.quantidadeProduto, 0)} Saca(s)</>:
                            <></>
                        }</td>
                        <td>{item.emitentes.map(emitente => emitente.nome).join(' - ')}</td>
                        <td>{item.credores.map(credor => credor.nome).join(' - ')}</td>
                        <td>
                            <CprActions item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}

export default ListCprs