import React, {createContext, useContext} from 'react';
import useStorage from "../utils/storage/useStorage";

// Crie um contexto para o token JWT
const UserContext = createContext();

// Crie um provedor de contexto para envolver seus componentes
export function AuthProvider({children}) {
    const {state: userData, set: setUserStorage, remove: removeUser} = useStorage('user');

    let user = userData
    try {
        user = JSON.parse(atob(userData))
    } catch (e) {
    }

    const setUser = (userPassed) => {
        setUserStorage(btoa(JSON.stringify(userPassed)))
    }

    return (
        <UserContext.Provider value={{user, setUser, removeUser}}>
            {children}
        </UserContext.Provider>
    );
}

// Crie um hook personalizado para acessar o contexto do usuário
export function useUser() {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUser deve ser usado dentro de um UserProvider');
    }

    return context;
}
