import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import React, {useState} from "react";
import InputsProduto from "./InputsProduto";
import GrassIcon from '@mui/icons-material/Grass';

export default function PopupAddProduto({baseUrl, setLoadData}) {

    const [tipo, setTipo] = useState('');
    const [variedade, setVariedade] = useState('');
    const [caracteristicas, setCaracteristicas] = useState('');
    const [pluviometricidadeMaxima, setPluviometricidadeMaxima] = useState("")
    const [pluviometricidadeMinima, setPluviometricidadeMinima] = useState("")
    const [temperaturaMaxima, setTemperaturaMaxima] = useState("")
    const [temperaturaMinima, setTemperaturaMinima] = useState("")


    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsProduto
        tipo={tipo} setTipo={setTipo}
        variedade={variedade} setVariedade={setVariedade}
        caracteristicas={caracteristicas} setCaracteristicas={setCaracteristicas}
        errorMessage={errorMessage} errorColumn={errorColumn}
        pluviometricidadeMaxima={pluviometricidadeMaxima} pluviometricidadeMinima={pluviometricidadeMinima}
        setPluviometricidadeMaxima={setPluviometricidadeMaxima} setPluviometricidadeMinima={setPluviometricidadeMinima}
        setTemperaturaMaxima={setTemperaturaMaxima} setTemperaturaMinima={setTemperaturaMinima}
        temperaturaMaxima={temperaturaMaxima} temperaturaMinima={temperaturaMinima}
    />

    const icon = <GrassIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setVariedade('')
        setTipo('')
        setCaracteristicas('')
        setPluviometricidadeMaxima('')
        setPluviometricidadeMinima('')
        setTemperaturaMinima('')
        setTemperaturaMaxima('')
    }

    const returnData = () => {
        return {
            tipo,
            variedade,
            caracteristicas,
            pluviometricidadeMaxima,
            pluviometricidadeMinima,
            temperaturaMinima,
            temperaturaMaxima
        }
    }

    return <PopupAdd
        baseUrl={baseUrl} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar Produto'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}