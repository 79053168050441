import {useUser} from "../../../auth/AuthProvider";
import {Link} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";


export default function AdminNavLinks() {
    const {user} = useUser()

    if (user?.isAdmin) {
        return <>
            <Link to={'/cprs'} className='link-light nav-link'>
                CPR(s)
            </Link>
            <Link to={'/cars'} className='link-light nav-link'>
                CAR(s)
            </Link>
            <NavDropdown title={`Administrativo`} id="basic-nav-dropdown">
            <NavDropdown.Item>
                    <Link to={'/alarmes'} className='link-dark text-decoration-none dropdown-item'>
                        Alarmes
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/estados'} className='link-dark text-decoration-none dropdown-item'>
                        Estados
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/cidades'} className='link-dark text-decoration-none dropdown-item'>
                        Cidades
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/produtos'} className='link-dark text-decoration-none dropdown-item'>
                        Produtos
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/condicoesideais'} className='link-dark text-decoration-none dropdown-item'>
                        Condições Climáticas Ideais
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/calendario'} className='link-dark text-decoration-none dropdown-item'>
                        Calendario de Plantio
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/emitentes'} className='link-dark text-decoration-none dropdown-item'>
                        Emitente(s)
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/credores'} className='link-dark text-decoration-none dropdown-item'>
                        Credor(es)
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Link to={'/jobs'} className='link-dark text-decoration-none dropdown-item'>
                        Jobs
                    </Link>
                </NavDropdown.Item>
            </NavDropdown>
            <Link to={'/users'} className='link-light nav-link'>
                Usuários
            </Link>
        </>
    } else {
        return <></>
    }
}