import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from 'react-router-dom'
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import './NavBar.css'
import {useUser} from "../../auth/AuthProvider";
import CommonUserNavLinks from "./components/CommonUserNavLinks";
import AdminNavLinks from "./components/AdminNavLinks"; // Importando o arquivo de estilos


const NavBar = () => {

    const {user} = useUser()

    return (
        <Navbar expand="lg" className=" fixed-top">
            <Container>
                <Navbar.Brand href="/" className='link-light '>
                    <img
                        alt=""
                        src="/simbolo-branco.png"
                        width="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <img
                        alt=""
                        src="/marca-branca.png"
                        width="120"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to={'/'} className='link-light nav-link'>
                            Home
                        </Link>
                        <CommonUserNavLinks/>
                        <AdminNavLinks/>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    {user?.token ?
                        <NavDropdown title={`Olá ${user?.username}`} id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link to={'/change-password'} className='link-dark text-decoration-none'>
                                    Alterar senha
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <Link to={'/logout'} className='link-dark text-decoration-none dropdown-item'>
                                <LogoutIcon fontSize='medium'/> &nbsp; Sair
                            </Link>
                        </NavDropdown> :
                        <Link to={'/login'} className="btn btn-dark">
                            Entrar <LoginIcon fontSize='medium'/>
                        </Link>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
