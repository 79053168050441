import React, {useState} from "react";
import FilterForm from "../../../component/FilterForm/FilterForm";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {aliasRoles} from "./aliasRoles";
import {ApiUrl} from "../../../auth/authMethods";

export default function FilterFormUsers({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('')
    const [isEnabled, setIsEnabled] = useState('');
    const [isAccountNonExpired, setIsAccountNonExpired] = useState('');
    const [roleList, setRoleList] = useState([])

    const baseUrl = `${ApiUrl}`

    const handlesSubmit = (e) => {
        e.preventDefault();

        setFilter({
            username,
            email,
            role,
            isEnabled,
            isAccountNonExpired
        });
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setUsername('')
        setEmail('')
        setRole('')
        setIsEnabled('')
        setIsAccountNonExpired('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Username"
                inputProps={{
                    maxLength: 25
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                autoFocus
            />
            <TextField
                label="Email"
                type={"email"}
                inputProps={{
                    maxLength: 45
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
            />
            <SelectInput
                url={baseUrl + '/role?size=2000'}
                list={roleList} set={setRoleList}
                setObj={setRole}
                defaultValue={role}
                returnList={() => {
                    let returnList = []
                    roleList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: aliasRoles.filter(alias => alias.received === item.name)[0].display
                        })
                    })
                    return returnList
                }}
                placeholder={"Tipo de usuário"}
                required={false}
                fullWidth
            />
            <FormControl>
                <FormLabel>Usuário ativo</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={isEnabled}
                    onChange={(e) => setIsEnabled(e.target.value)}
                >
                    <FormControlLabel value={''} control={<Radio/>} label="Todos"/>
                    <FormControlLabel value={true} control={<Radio/>} label="Apenas Aivos"/>
                    <FormControlLabel value={false} control={<Radio/>} label="Apenas Inaivos"/>
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Conta expirada</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={isAccountNonExpired}
                    onChange={(e) => setIsAccountNonExpired(e.target.value)}
                >
                    <FormControlLabel value={''} control={<Radio/>} label="Ambos"/>
                    <FormControlLabel value={true} control={<Radio/>} label="Não"/>
                    <FormControlLabel value={false} control={<Radio/>} label="Sim"/>
                </RadioGroup>
            </FormControl><br/>
        </FilterForm>
    </>


}