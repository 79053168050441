import {useState} from "react";
import ContentContainer from "../../component/ContentContainer";
import PaginationControl from "../../component/Pagination/PaginationControl";
import PopupAddAlarmes from "./components/PopupAddAlarmes";
import ListAlarmes from "./components/ListAlarmes";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";
import './style.css'


export default function Alarmes() {

    const {user} = useUser()

    const baseUrl = `${ApiUrl}/dados-ambientais` // /alarmes --------------
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("id")
    
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return <ContentContainer width='xl'>

        <h1>Alarmes (com base nos dados ambientais)</h1>
        {user.isMaster &&
            <PopupAddAlarmes apiUrl={ApiUrl} baseUrl={baseUrl} setLoadData={setLoadData}/>
        }
        <ListAlarmes
            baseUrl={baseUrl}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
        />
        <PaginationControl
            totalElements={totalElements}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setLoadData={setLoadData}
        />

    </ContentContainer>
}