import {Divider} from "@mui/material";

export default function UseTerms() {
    return (
        <div className="text-center p-4">
            <h3>Termos de uso</h3>
            <Divider color='black' className='mb-3'/>
            <span>
                Seu acesso e utilização das informações contidas neste portal estão sujeitos aos seguintes termos e
                condições:
                <br/><br/>
                A Cenpros mantém este portal para relações com as diversas áreas, clientes autorizados pela Cenpros, e
                outros clientes, enfim, entidades de seu interesse para comunicações, divulgações e informações gerais.
                Ao usar este portal você está concordando em submeter-se às obrigações aqui relacionadas, estando desde
                já avisado que eventual utilização indevida lhe imputará responsabilidades cíveis e criminais.
                <br/><br/>
                Este portal tem seu conteúdo protegido pelas leis de direitos autorais e marcas comerciais aplicáveis.
                Você poderá imprimir uma única cópia do conteúdo, desde que a mesma contenha todos os avisos de direitos
                autorais ou de exclusividade. E atenção, não modifique o conteúdo, nem faça download de quantidades do
                conteúdo para um banco de dados que possa ser usado por terceiros para evitar downloads futuros deste
                portal.
                <br/><br/>
                Fica proibida a reprodução, distribuição ou publicação adicional de qualquer parte deste portal.
                Lembre-se caso você acesse um link para qualquer portal a partir deste portal, você deverá observar as
                disposições de direitos autorais dos referidos sites.
                <br/><br/>
                Você não está autorizado a decompilar, desmontar, nem fazer engenharia reversa deste portal nem de
                qualquer outra maneira tentar identificar o código-fonte ou segredos comerciais do portal, violar sua
                segurança ou interromper e interferir com a operação do portal.
                <br/><br/>
                Entende-se por conteúdo, todo e qualquer texto, documento, fotografia, imagem, gráfico, logotipo,
                emblema, design, layout, marca registrada, nome comercial, marca de serviço, materiais com registro de
                direitos autorais, apresentações em áudio e vídeo e outras informações disponibilizadas neste portal ou
                através dele.
                <br/><br/>
                O usuário concorda em não usar este portal para fins comerciais diversos do aqui pretendido, nem editar
                as informações aqui dispostas, ficando estabelecido que a Cenpros se reserva no direito de monitorar,
                filtrar, censurar, regulamentar as informações ou o conteúdo, fazendo-o à sua própria discrição.
                <br/><br/>
                <b>ACESSO</b>
                <Divider color='gray' className='mb-3'/>
                Você poderá ter acesso ao portal, mas somente poderá se manifestar através dos administradores das áreas
                internas especificas que munidos de senhas de acesso poderão fazer as inserções necessárias. Estes
                administradores estão cientes de sua responsabilidade, não podendo em suas inserções envolver-se e à
                Cenpros em qualquer atividade prejudicial às imagens e marcas envolvidas, bem como fazer uso de
                expressões e material de natureza ilegal, ofensiva, obscena, ameaçadora, assediante, lesiva ou abusiva,
                bem como que viole qualquer direito de terceiros e da Cenpros, atividade que interfira no acesso de um
                usuário a este portal ou à sua própria, nem assumir a identidade de terceiros ou entidade.
                <br/><br/>
                As opiniões dispostas pelo usuário através do administrador, bem como qualquer manifestação, dúvida,
                resposta, sugestão ou afins enviados pelo usuário à Cenpros por meio deste portal será tratado como
                material não confidencial e não-proprietário, não assumindo a Cenpros qualquer responsabilidade em
                relação a ele, nem pelo recebimento ou não recebimento dele pela Cenpros. O recebimento da comunicação
                por parte da Cenpros não significa sua aceitação ou priorização por parte da Cenpros e não prejudica a
                Cenpros em contestar os direitos de propriedade intelectual porventura existente ou futuros.
                <br/><br/>
                Quando o usuário envia mensagens de e-mail, a qualquer das áreas acessíveis neste portal, ele está se
                comunicando com a Cenpros de maneira eletrônica e consente receber comunicações da Cenpros via
                eletrônica. Além disso, o usuário concorda que todos os acordos, notificações, divulgações e outras
                comunicações que a Cenpros fornece ao usuário eletronicamente satisfazem qualquer requerimento legal que
                tal comunicação seja feita por escrito.
                <br/><br/>
                A Cenpros poderá, à sua própria discrição, fazer alterações a qualquer aspecto deste portal, incluindo,
                mas sem limitações, a qualquer conteúdo, quaisquer atividades disponíveis neste portal e a quaisquer
                produtos ou serviços oferecidos por meio deste portal. A Cenpros poderá também, revisar a qualquer tempo
                este documento.
                <br/><br/>
                <b>INVESTIGAÇÕES:</b>
                <Divider color='gray' className='mb-3'/>
                A Cenpros se reserva no direito, sem nenhum tipo de limitação, de investigar violações suspeitas da
                respectiva segurança deste portal ou de sua tecnologia da informação ou de outros sistemas ou redes,
                envolver-se e cooperar com as autoridades do setor de cumprimento das leis na investigação desta
                questão; processar judicialmente eventuais infratores deste portal, interromper este portal ou cessar o
                acesso de determinado usuário a qualquer momento, sem aviso prévio, por qualquer motivo e sem nenhuma
                obrigação perante o usuário.
            </span>
        </div>
    )
}