import TextField from "@mui/material/TextField";
import React from "react";
import applyRegex from "../../../utils/ApplyRegex";

export default function InputsEstado({
                                         nome, setNome,
                                         sigla, setSigla,
                                         errorColumn, errorMessage
                                     }) {

    return <>
        <TextField
            label="Nome"
            inputProps={{
                maxLength: 38
            }}
            error={errorColumn === 'nome'}
            helperText={errorColumn === 'nome' ? errorMessage : ''}
            value={nome}
            onChange={(e) => {
                setNome(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ ])/g))
            }}
            required
            fullWidth
            autoFocus
        />

        <TextField
            label="Sigla"
            inputProps={{
                maxLength: 2,
                minLength: 2
            }}
            error={errorColumn === 'sigla'}
            helperText={errorColumn === 'sigla' ? errorMessage : ''}
            value={sigla}
            onChange={(e) => {
                setSigla(applyRegex(e.target.value.toUpperCase(), /[A-Z]/g));
            }}
            required
            fullWidth
        />
    </>

}