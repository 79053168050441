import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useState} from "react";
import axios from "axios";
import {ApiUrl} from "../../auth/authMethods";
import displayAlertMsg from "../../component/AlertMsg/displayAlertMsg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentContainer from '../../component/ContentContainer/'

export default function ForgetPassword() {

    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false)

    const sendEmail = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);

        const email = data.get('email')
        const dataToSend = `?email=${email}`

        setIsLoading(true)

        axios.post(ApiUrl + '/auth/resetEmail' + dataToSend)
            .then((_) => {
                setSent(true)
            }).catch((err) => {
            if (err.response.data.message)
                setError(displayAlertMsg(err.response.data.message))
            else
                setError(displayAlertMsg('Erro ao fazer o envio do email, tente novamente mais tarde.'))
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
        >
            Enviar link para alteração
        </Button>

    return (
        <>
            <ContentContainer>
                {!sent ?
                    <Box component="form" onSubmit={sendEmail} sx={{mt: 1}}>
                        <div className="text-center">
                            <LockIcon fontSize='large' sx={{fontSize: '4rem'}}/><br/>
                            <b>Enfrentando dificuldades para acessar sua conta?</b><br/>
                            Digite seu endereço de email e nós lhe enviaremos um link para restabelecer o acesso à
                            sua
                            conta.
                        </div>
                        {error}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            type='email'
                            autoComplete="email"
                            variant='standard'
                            autoFocus
                        />
                        {button}

                        <Link to='/login'>
                            <p className='text-center'>Voltar para o login</p>
                        </Link>
                    </Box>
                    :
                    <Box component="div" sx={{mt: 1}} className="text-center">
                        <p style={{color: 'green'}}>

                            <CheckCircleIcon sx={{
                                fontSize: 40,
                                height: '5vw',
                                width: '5vw',
                            }}/><br/>
                            <h4>Enviamos um email para a alteração da senha, verifique sua caixa de entrada e spam.</h4>
                        </p>
                    </Box>
                }
            </ContentContainer>
        </>
    )
}