export const normalizeTimestemp = (timestamp, withoutTime) => {
    if (typeof timestamp === 'string') {
        let data = new Date(timestamp)
        if (timestamp.length === 10){
            const dataSplit = timestamp.split('-')
            return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`
        }

        let ano = data.getFullYear();
        let mes = data.getMonth() + 1;
        let dia = data.getDate();
        let hora = data.getHours();
        let minuto = data.getMinutes();
        let segundo = data.getSeconds();

        if (mes < 10) {
            mes = "0" + mes;
        }
        if (dia < 10) {
            dia = "0" + dia;
        }
        if (hora < 10) {
            hora = "0" + hora;
        }
        if (minuto < 10) {
            minuto = "0" + minuto;
        }
        if (segundo < 10) {
            segundo = "0" + segundo;
        }

        if (hora + ":" + minuto + ":" + segundo === '00:00:00' || withoutTime)
            return dia + "/" + mes + "/" + ano;
        else
            return dia + "/" + mes + "/" + ano + " " + hora + ":" + minuto + ":" + segundo;
    } else {
        return '';
    }
};
