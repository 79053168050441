import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import DateRangeIcon from '@mui/icons-material/DateRange';
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import LargeModal from "../../../component/LargeModal/LargeModal";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import {cnpjMask} from "../../../utils/cnpjMask";

// icons
import Filter1Icon from '@mui/icons-material/Filter1';
import RoomIcon from '@mui/icons-material/Room';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GrassIcon from '@mui/icons-material/Grass';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DescriptionIcon from '@mui/icons-material/Description';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BadgeIcon from '@mui/icons-material/Badge';
import axios from "axios";
import MapWithFeatures from "./MapWithFeatures";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import {useUser} from "../../../auth/AuthProvider";
import {cpfMask} from "../../../utils/cpfMask";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import {numeroOrdemMask} from "../../../utils/numeroOrdemMask";

export default function PopupDetails({item, baseUrl, setLoadData, setErrorMessage, setErrorColumn}) {


    const produto = item.produto
    const credores = item.credores
    const emitentes = item.emitentes

    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes da CPR
    </>

    const title = "Detalhes da CPR"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    function calcularPesoEmQuilogramas(numeroSacas, pesoPorSaca) {
        return numeroSacas * pesoPorSaca;
    }

    const quantityDisplay = item.quantidadeProduto ?
        `Quantidade: ${numberWithPoints(item.quantidadeProduto, 0)} Sacas de ${produto.tipo} ou ${numberWithPoints(calcularPesoEmQuilogramas(item.quantidadeProduto, 60), 0)} Kg` :
        'Quantidade: * não cadastrada';


    const handleEndereco = (enderecoObj) => {
        if (!enderecoObj || enderecoObj.logradouro === '')
            return '* não cadastrado'
        return `${enderecoObj.logradouro}, ${enderecoObj.numero} ${enderecoObj.complemento ? '- ' + enderecoObj.complemento : ''}, ${enderecoObj.bairro}, ${enderecoObj.cidade.nome} - ${enderecoObj.cidade.estado.nome}, ${enderecoObj.cep}`;
    }

    return (
        <LargeModal
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
                <>
                    <List
                        sx={{width: '80%', bgcolor: 'background.paper', marginInline: '10%'}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className='pointer-event'
                    >
                        {/* Dados primários */}
                        <DisplayDetailsData
                            openDetails={true}
                            key="primaryData" // Adicione uma chave única aqui
                            title='Dados primários'
                            itens={[
                                {
                                    icon: <Filter1Icon key="item1"/>,
                                    display: `Número de ordem:  ${numeroOrdemMask(item.numeroOrdem.toString())}`
                                },
                                {
                                    icon: <DateRangeIcon key="item2"/>,
                                    display: `Data de vencimento:  ${normalizeTimestemp(item.dataVencimentoCedula)}`
                                },
                                {
                                    icon: <DateRangeIcon key="item3"/>,
                                    display: `Data de emissão:  ${normalizeTimestemp(item.dataEmissaoCedula)}`
                                },
                                {
                                    icon: <RoomIcon key="item4"/>,
                                    display: `Local de Emissão:  ${item.localEmissao}`
                                },
                            ]}
                        />

                        {/* Produto */}
                        <DisplayDetailsData
                            openDetails={true}
                            key="productData" // Adicione uma chave única aqui
                            title='Produto'
                            itens={[
                                {
                                    icon: <GrassIcon/>,
                                    display: `Tipo:  ${produto.tipo}`
                                },
                                {
                                    icon: <GrassIcon/>,
                                    display: quantityDisplay
                                },
                                // {
                                //     icon: <PlaylistAddCheckIcon/>,
                                //     display: `Variedade:  ${produto.variedade}`
                                // },
                                {
                                    icon: <DescriptionIcon/>,
                                    display: `Características:  ${produto.caracteristicas}`
                                }
                            ]}
                        />

                        {/* Emitentes */}
                        {
                            emitentes.map((emitente, index) => (
                                <DisplayDetailsData
                                    openDetails={true}
                                    key={emitente.id}
                                    title={'Emitente ' + (index + 1)}
                                    itens={[
                                        {
                                            icon: <DriveFileRenameOutlineIcon/>,
                                            display: `Nome:  ${emitente.nome}`
                                        },
                                        {
                                            icon: <BadgeIcon/>,
                                            display: `Cnpj/Cpf:  ${emitente.cnpj === null ? cpfMask(emitente.cpf) : cnpjMask(emitente.cnpj)}`
                                        },
                                        {
                                            icon: <RoomIcon key="item9"/>,
                                            display: `Endereço:  ${handleEndereco(emitente.endereco)}`
                                        }
                                    ]}
                                />
                            ))
                        }

                        {/* Credores */}
                        {
                            credores.map((credor, index) => (
                                <DisplayDetailsData
                                    openDetails={true}
                                    key={credor.id}
                                    title={'Credor ' + (index + 1)}
                                    itens={[
                                        {
                                            icon: <DriveFileRenameOutlineIcon/>,
                                            display: `Nome:  ${credor.nome}`
                                        },
                                        {
                                            icon: <BadgeIcon/>,
                                            display: `Cnpj/Cpf:  ${credor.cnpj === null ? cpfMask(credor.cpf) : cnpjMask(credor.cnpj)}`
                                        },
                                        {
                                            icon: <RoomIcon key="item9"/>,
                                            display: `Endereço:  ${handleEndereco(credor.endereco)}`
                                        }
                                    ]}
                                />
                            ))
                        }

                    </List>
                </>
        </LargeModal>
    )
}