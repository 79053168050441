import Container from "@mui/material/Container";


export default function ContentContainer({ width, children}) {
    return <Container
        component="main"
        maxWidth={ width? width:"xs"}
        sx={{
            backgroundColor: '#fff',
            boxShadow: 3,
            padding: 2,
            borderRadius: 2,
            marginTop: 10,
            marginBottom: 5
        }}
    >
        {children}
    </Container>
}