import React, {useState} from "react";
import FilterFormClimas from "./FilterFormClimas";
import TableList from "../../../component/TableList/TableList";
import PopupDeleteClima from "./PopupDeleteClima";
import PopupEditClima from "./PopupEditClima";
import {useUser} from "../../../auth/AuthProvider";


const ListClima = ({
                       baseUrl, loadData,
                       setLoadData, size, page,
                       setTotalPages, setTotalElements,
                       sort, setSort, updateCode, setPage
                   }) => {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})


    const titles = [
        "Vitalidade do solo",
        "Pluviometria",
        "Temperatura",
        "Estado",
        "Produto"
    ]

    return (
        <>
            <FilterFormClimas baseUrl={baseUrl} filter={filter} setFilter={setFilter} setLoadData={setLoadData}
                              setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.vitalidadeSolo + "%"}</td>
                        <td>{item.pluviosidade + "mm"}</td>
                        <td>{item.temperatura + "°C"}</td>
                        <td>{item.estado.nome}</td>
                        <td>{item.produto.tipo + " - " + item.produto.variedade}</td>
                        {user.isMaster && <>
                            <td>
                            <span>
                                <PopupEditClima
                                    item={item}
                                    setLoadData={setLoadData}
                                    baseUrl={baseUrl}
                                />
                            </span>
                            </td>
                            <td>
                            <span>
                                <PopupDeleteClima
                                    item={item}
                                    setLoadData={setLoadData}
                                    baseUrl={baseUrl}
                                />
                            </span>
                            </td>
                        </>}
                    </tr>
                )}
            </TableList>
        </>
    )
}
export default ListClima