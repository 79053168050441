import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import {Dropdown} from "react-bootstrap";
import {useUser} from "../../../auth/AuthProvider";
import PopupEditAlarmes from "./PopupEditAlarmes";
import PopupDeleteAlarmes from "./PopupDeleteAlarmes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "react-bootstrap/Tooltip";
import PopupProdutoDetails from "./PopupDetailsAlarmes";
import { ApiUrl } from "../../../auth/authMethods";

export default function AlarmeActions({item, baseUrl, setLoadData}) {

    const {user} = useUser()

    const buttonDeleteDisabled = <div
        className='dropdown-item'>
        <OverlayTrigger

            placement="right"
            delay={{show: 150, hide: 400}}
        >
        <span style={{
            color: '#828282'
        }}>
            <DeleteForeverIcon fontSize="medium"/>
            Deletar
        </span>
        </OverlayTrigger>

    </div>


    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupProdutoDetails item={item} baseUrl={ApiUrl + "/alarmes"} setLoadData={setLoadData}/>

            {user.isMaster && <>
                <PopupEditAlarmes
                    setLoadData={setLoadData}
                    baseUrl={baseUrl}
                    item={item}
                    apiUrl={ApiUrl}
                />
                {
                    <td>
                        <PopupDeleteAlarmes
                            item={item}
                            setLoadData={setLoadData}
                            baseUrl={baseUrl}
                        />
                    </td>
                }
            </>}

        </Dropdown.Menu>

    </Dropdown>
}