import React from "react";
import Box from "@mui/material/Box";
import CancelIcon from '@mui/icons-material/Cancel';

export default function DefaultProblemMessage({message}) {

    return (
        <Box className="text-center" sx={{color: 'red'}}>
            <CancelIcon sx={{
                fontSize: 40,
                height: '5vw',
                width: '5vw',
            }}/><br/>
            <h4>{message ? message : 'Ocorreu um erro no processo, tente novamente mais tarde!'}</h4>
        </Box>
    )
}