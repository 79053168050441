import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import LargeModal from "../LargeModal/LargeModal";
import { Button, Form } from "react-bootstrap";
import "./PopupAdd.css";
import toast, { Toaster } from 'react-hot-toast';
import { useUser } from "../../auth/AuthProvider";

const PopupAdd = ({
                      baseUrl,
                      setLoadData,
                      inputs,
                      title,
                      icon,
                      clearForm,
                      returnData,
                      setErrorMessage,
                      setErrorColumn,
                      blockSubmit,
                      button,
                      headers,
                      disableButton,
                      buttonClass,
                  }) => {

    const { user } = useUser()

    // modal control
    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Adicionando estado de carregamento
    const [error, setError] = useState(false);

    const iconSpan = (
        <>
            {icon}
            <h3>{title}</h3>
        </>
    );

    const buttonText = button ? (
        button
    ) : (
        <>
            Adicionar
            <AddIcon fontSize="medium"/>
        </>
    );

    const notifySuccess = () => toast.success('Cadastrado com sucesso!');
    const notifyError = () => toast.error('Houve um erro no cadastro. Verifique algum campo, ou tente novamente mais tarde.');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = returnData();
        const headersData = {
            Authorization: `Bearer ${user?.token}`,
            ...headers
        }

        if (!blockSubmit) {
            setIsLoading(true); // Ativar o estado de isLoading quando o formulário for enviado

            try {
                const response = await axios.post(baseUrl, data, { headers: headersData });
                if (setLoadData) setLoadData(true);
                if (clearForm) clearForm();
                notifySuccess(); // Mostra toast de sucesso
                window.location.reload();
            } catch (error) {
                console.log("Error: " + error);
                if (error.response && error.response.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setErrorColumn(error.response.data.column);
                }
                notifyError(); // Mostra toast de erro
            } finally {
                setIsLoading(false); // Desativar o estado de isLoading após o término da solicitação
            }
        }
    };

    const handleModalClose = () => {
        setLgShow(false);

        if (clearForm) clearForm();
    };

    const toggleShow = (value) => {
        if (value)
            setLgShow(value)
        else
            handleModalClose()
    }

    return (
        <>
            {/* Botão e popup */}
            <LargeModal
                buttonText={buttonText}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow}
                setLgShow={toggleShow}
                handleClose={handleModalClose}
                buttonClass={buttonClass ? buttonClass : "btn btn-primary button-show"}
                backdrop={'static'}
            >
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {inputs}
                        <Button
                            type="submit"
                            disabled={isLoading || disableButton} // Desabilitar o botão enquanto estiver carregando
                            className={disableButton || isLoading ? "btn btn-secondary" : ""}
                        >
                            {isLoading ? "Aguarde..." : button ? button : "Adicionar"}
                        </Button>
                        <Toaster />
                    </Form>
                </div>
            </LargeModal>
        </>
    );
};

export default PopupAdd;
