import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../utils/ApplyRegex";
import InputAdornment from "@mui/material/InputAdornment";


export default function FilterFormProduto({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [tipo, setTipo] = useState('');
    const [variedade, setVariedade] = useState('');
    const [temperaturaMaxima, setTemperaturaMaxima] = useState("")
    const [temperaturaMinima, setTemperaturaMinima] = useState("")
    const [pluviometricidadeMaxima, setPluviometricidadeMaxima] = useState("")
    const [pluviometricidadeMinima, setPluviometricidadeMinima] = useState("")
    const [caracteristicas, setCaracteristicas] = useState('');

    const handlesSubmit = (e) => {

        e.preventDefault();

        setFilter({tipo, variedade,pluviometricidadeMinima, pluviometricidadeMaxima, temperaturaMinima, temperaturaMaxima,caracteristicas});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setTipo('')
        setVariedade('')
        setPluviometricidadeMinima('')
        setPluviometricidadeMaxima('')
        setTemperaturaMinima('')
        setTemperaturaMaxima('')
        setCaracteristicas('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <TextField
                label="Tipo"
                inputProps={{
                    maxLength: 40
                }}
                value={tipo}
                onChange={(e) => {
                    setTipo(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ ])/g))
                }}
                fullWidth
                autoFocus
            />

            <TextField
                label="Variedade"
                inputProps={{
                    maxLength: 40
                }}
                value={variedade}
                onChange={(e) => {
                    setVariedade(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ0-9 -])/g))
                }}
                fullWidth
            />

            <TextField
                label="Pluviometricidade Mínima"
                inputProps={{
                    min: 1,
                    maxLength: 9
                }}
                type="text"
                errorText="Campo obrigatório!"
                value={pluviometricidadeMinima}
                onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^\d.,]/g, ''); // Remove tudo exceto números, ponto e vírgula
                    setPluviometricidadeMinima(numericValue);
                }}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm(milímetros)</InputAdornment>,
                }}
            />

            <TextField
                label="Pluviometricidade Máxima"
                inputProps={{
                    min: 1,
                    maxLength: 9
                }}
                type="text"
                errorText="Campo obrigatório!"
                value={pluviometricidadeMaxima}
                onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^\d.,]/g, ''); // Remove tudo exceto números, ponto e vírgula
                    setPluviometricidadeMaxima(numericValue);
                }}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm(milímetros)</InputAdornment>,
                }}
            />

            <TextField
                label="Temperatura Mínima"
                inputProps={{
                    min: 1,
                    maxLength: 9
                }}
                type="text"
                errorText="Campo obrigatório!"
                value={temperaturaMinima}
                onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^\d.,]/g, ''); // Remove tudo exceto números, ponto e vírgula
                    setTemperaturaMinima(numericValue);
                }}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                }}
            />

            <TextField
                label="Temperatura Máxima"
                inputProps={{
                    min: 1,
                    maxLength: 9
                }}
                type="text"
                errorText="Campo obrigatório!"
                value={temperaturaMaxima}
                onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^\d.,]/g, ''); // Remove tudo exceto números, ponto e vírgula
                    setTemperaturaMaxima(numericValue);
                }}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                }}
            />




            <div className="form-floating mb-2">
                <textarea className="form-control" placeholder=""
                          id="floatingTextarea" value={caracteristicas}
                          onChange={(e) => setCaracteristicas(e.target.value)}>
                </textarea>
                <label htmlFor="floatingTextarea">Características do produto</label>
            </div>

        </FilterForm>
    </>
}