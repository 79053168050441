import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {useState} from "react";
import ListEmitente from "./components/ListEmitente";
import {ApiUrl} from "../../auth/authMethods";

export default function Emitente() {

    const baseUrl = `${ApiUrl}/emitente`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(100)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("nome")

    const [totalPages, setTotalPages] = useState("")
    const [totalElements, setTotalElements] = useState(10)

    return <>
        <ContentContainer width='xl'>

            <h1> Lista de Emitentes </h1>
            {/*<PopupAddCidade baseUrl={baseUrl} setLoadData={setLoadData}/>*/}
            <ListEmitente
                setLoadData={setLoadData}
                baseUrl={baseUrl}
                loadData={loadData}
                page={page}
                setPage={setPage}
                size={size}
                setTotalPages={setTotalPages}
                setSort={setSort}
                sort={sort}
                setTotalElements={setTotalElements}
            />
            <PaginationControl
                totalElements={totalElements}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                setLoadData={setLoadData}
                rowsPerPageOptions={[100, 200, 500, 1000]}
            />

        </ContentContainer>
    </>
}