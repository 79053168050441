import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import InputAdornment from "@mui/material/InputAdornment";
import {ApiUrl} from "../../../auth/authMethods";


export default function InputsClima({baseUrl, vitalidadeSolo, setVitalidadeSolo,
                                    temperatura,setTemperatura,
                                    pluviosidade,setPluviosidade,
                                    setErrorColumn,setErrorMessage,
                                    errorColumn, errorMessage,estadoId, setEstadoId, produtoId, setProdutoId}){


    const [estadoList, setEstadoList] = useState([])
    const [productsList, setProductsList] = useState([])

    const handleVitalidadeSoloChange = (e) => {
        const inputValue = e.target.value;

        const regex = /^\d*\.?\d{0,1}$/;
        if (inputValue === '' || (regex.test(inputValue) && parseFloat(inputValue) >= 0 && parseFloat(inputValue) <= 100)) {
            setVitalidadeSolo(inputValue);
            setErrorColumn(null);
            setErrorMessage('');
        } else {
        }
    };

    const handleTemperaturaChange = (e) => {
        const inputValue = e.target.value;

        const regex = /^\d*\.?\d{0,1}$/;
        if (inputValue === '' || (regex.test(inputValue) && parseFloat(inputValue) >= 0 && parseFloat(inputValue) <= 100)) {
            setTemperatura(inputValue);
            setErrorColumn(null);
            setErrorMessage('');
        } else {
        }
    };



    return<>
        <TextField
            label="Vitalidade do solo"
            inputProps={{
                maxLength: 4,
            }}
            type="number"
            error={errorColumn === 'Vitalidade do solo'}
            helperText={errorColumn === 'Vitalidade do solo' ? errorMessage : ''}
            value={vitalidadeSolo}
            onChange={handleVitalidadeSoloChange}
            required
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            autoFocus
        />

        <TextField
            label="Temperatura"
            inputProps={{
                maxLength: 5, // ajustado para permitir até 3 dígitos inteiros e uma casa decimal
            }}
            type="text" // mudado para texto para permitir validação personalizada
            error={errorColumn === 'Temperatura'}
            helperText={errorColumn === 'Temperatura' ? errorMessage : ''}
            value={temperatura}
            onChange={handleTemperaturaChange}
            required
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
        />

        <TextField
            label="Pluviometria"
            inputProps={{
                maxLength: 5
            }}
            type="number"
            error={errorColumn === 'pluviosidade'}
            helperText={errorColumn === 'pluviosidade' ? errorMessage : ''}
            value={pluviosidade}
            onChange={(e) =>{
                if (e.target.value.length <= 5) {
                    setPluviosidade(e.target.value);
                }
            }}
            required
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">mm(milímetros)</InputAdornment>,
            }}
        />
        <SelectInput
            url={`${ApiUrl}/estado?size=2000`}
            list={estadoList} set={setEstadoList}
            setObj={setEstadoId}
            returnList={() => {
                let returnList = []
                estadoList.forEach((item) => {
                    returnList.push({value: item.id, label: item.nome +" - "+ item.sigla})
                })
                return returnList
            }}
            placeholder={"Estado"}
            defaultValue={estadoId}
            required={true}
            fullWidth
        />

        <SelectInput
            url={`${ApiUrl}/produto?size=2000`}
            list={productsList} set={setProductsList}
            setObj={setProdutoId}
            returnList={() => {
                let returnList = []
                productsList.forEach((item) => {
                    returnList.push({value: item.id, label: item.tipo + " - " + item.variedade})
                })
                return returnList
            }}
            placeholder={"Produto"}
            defaultValue={produtoId}
            required={true}
            fullWidth
        />

    </>

}