import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import SelectInput from "../../../../component/SelectInput";
import DisplayManyInputs from "./DisplayManyInputs";
import applyRegex from "../../../../utils/ApplyRegex";
import {DatePicker} from "@mui/x-date-pickers";
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import {ApiUrl} from "../../../../auth/authMethods";
import InputMask from "react-input-mask";
import {NumericFormat} from "react-number-format";

export default function InputsCprs({
                                       cpfPresent, cnpjPresent,
                                       numeroOrdem, setNumeroOrdem,
                                       dataVencimentoCedula, setDataVencimentoCedula,
                                       dataEmissaoCedula, setDataEmissaoCedula,
                                       coordenadas, setCoordenadas,
                                       credores, setCredores,
                                       emitentes, setEmitentes,
                                       produtoId, setProdutoId,
                                       quantidadeProduto, setQuantidadeProduto,
                                       localEmissao, setLocalEmissao,
                                       cidadeId, setCidadeId,
                                       setBlockSubmit, estadoId, setEstadoId,
                                       errorMessage, setErrorMessage,
                                       errorColumn, setErrorColumn, setLoadData, openPopupEdit,
                                       inputOrTypography, notShowAddressInputs
                                   }) {

    const baseUrl = ApiUrl

    const [productsList, setProductsList] = useState([])
    const [cidadeList, setCidadeList] = useState([])
    const [isCnpj, setIsCnpj] = useState()


    const handleChangeData = (value, set) => {
        let data = new Date(value)
        try {
            set(data.toISOString().slice(0, 10))
        } catch (e) {
            set(value)
        }
    }

    const handleChangeValue = (e, set) => {
        set(e.floatValue)
    }

    return (
        <>
            <InputMask mask="99/9999-99" value={numeroOrdem} maskChar={null}
                       onChange={(e) => setNumeroOrdem(e.target.value)}>
                {() =>
                    <TextField
                        label="Número de Ordem"
                        required={true}
                        inputProps={{
                            minLength: 11,
                            maxLength: 11
                        }}
                        type="text"
                        erortext="Campo obrigatório!"
                        // value={numeroOrdem}
                        // onChange={(e) => {
                        //     if (e.target.value.length <= 9) {
                        //         setNumeroOrdem(applyRegex(e.target.value, /^\d+$/));
                        //     }
                        // }}
                        fullWidth
                    />}
            </InputMask>

            <DatePicker
                label="Data de Vencimento da Cédula"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataVencimentoCedula ? dayjs(dataVencimentoCedula) : null}
                onChange={(e) => handleChangeData(e, setDataVencimentoCedula)}
                slotProps={{
                    textField: {
                        required: true
                    },
                }}
            />

            <DatePicker
                label="Data de Emissão da Cédula"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataEmissaoCedula ? dayjs(dataEmissaoCedula) : null}
                onChange={(e) => handleChangeData(e, setDataEmissaoCedula)}
                slotProps={{
                    textField: {
                        required: true
                    },
                }}
            />

            <TextField
                label="Coordenadas"
                inputProps={{maxLength: 38}}
                fullWidth
                value={coordenadas}
                erortext="Campo obrigatório!"
                onChange={(e) => setCoordenadas(e.target.value.replaceAll('_', ''))}
            />

            <TextField
                label="Local de emissão"
                inputProps={{maxLength: 60}}
                fullWidth
                required={true}
                value={localEmissao}
                erortext="Campo obrigatório!"
                onChange={(e) => setLocalEmissao(e.target.value)}
                helperText='Digite o nome da cidade para filtrar as opções (A consulta pode demorar um pouco!)'
            />
            <SelectInput
                url={baseUrl + '/cidade?size=20000'}
                list={cidadeList}
                set={setCidadeList}
                setObj={setCidadeId}
                defaultValue={cidadeId}
                returnList={() => {
                    let returnList = []
                    cidadeList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nome} - ${item.estado.sigla}`})
                    })
                    return returnList
                }}
                placeholder={"Local de Formação do Produto *"}
                required={true}
                fullWidth
            />


            <Divider/>
            <Box mb={0.5} pb={0.5}>
                Produto
            </Box>
            <SelectInput
                url={baseUrl + '/produto?size=2000'}
                list={productsList} set={setProductsList}
                setObj={setProdutoId}
                defaultValue={produtoId}
                returnList={() => {
                    let returnList = []
                    productsList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.tipo} - ${item.variedade}`})
                    })
                    return returnList
                }}
                placeholder={"Produto *"}
                defaultValue={produtoId}
                required={true}
                fullWidth
            />

            <NumericFormat
                customInput={TextField}
                thousandSeparator="."
                decimalSeparator=","
                suffix=' Saca(s)'
                decimalScale={2}
                label="Quantidade de produto (em Sacas)"
                max='999999'
                inputProps={{
                    min: 1,
                    maxLength: 17,
                    step: ".01"
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0,
                    },
                }}
                value={quantidadeProduto}
                onValueChange={(e) => handleChangeValue(e, setQuantidadeProduto)}
                required
                fullWidth
            />

            <Box mt={2}>
                Informações do Emitente
            </Box>
            <hr/>
            <DisplayManyInputs
                obj={emitentes} setObj={setEmitentes}
                setBlockSubmit={setBlockSubmit} baseUrl={baseUrl + '/emitente'}
                message={'Emitente já cadastrado em nossa base'}
                emailRequired={false} name={'Emitente'} rgInput={true}
                setErrorColumn={setErrorColumn}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
                errorColumn={errorColumn}
                cnpjPresent={cnpjPresent} cpfPresent={cpfPresent}
                switchInput={true}
                setLoadData={setLoadData}
                openPopupEdit={openPopupEdit}
                inputOrTypography={inputOrTypography}
                notShowAddressInputs={notShowAddressInputs}
            />
            {/*<InputsObjects*/}
            {/*    obj={emitente} setObj={setEmitente}*/}
            {/*    setBlockSubmit={setBlockSubmit} baseUrl={baseUrl + '/emitente'}*/}
            {/*    message={'Emitente já cadastrado em nossa base'}*/}
            {/*    emailRequired={true}*/}
            {/*/>*/}

            <hr/>
            <Box mb={0.5} pb={0.5}>
                Informações do Credor
            </Box>
            <DisplayManyInputs
                obj={credores} setObj={setCredores}
                setBlockSubmit={setBlockSubmit} baseUrl={baseUrl + '/credor'}
                message={'Credor já cadastrado em nossa base'} name={'Credor'}
                setErrorColumn={setErrorColumn}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
                errorColumn={errorColumn}
                switchInput={true}
                cpfPresent={cpfPresent}
                cnpjPresent={cnpjPresent}
                openPopupEdit={openPopupEdit}
                inputOrTypography={inputOrTypography}
                isCredor={true} notShowAddressInputs={notShowAddressInputs}
            />
        </>
    )

}