import TextField from "@mui/material/TextField";
import {cnpjMask} from "../../../../utils/cnpjMask";
import React, {useEffect, useRef, useState} from "react";
import {validadeCnpj} from "../../../../utils/validadeCnpj";
import {validadeCpf} from "../../../../utils/validadeCpf";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from "../../../../component/SelectInput";
import {Button} from "react-bootstrap";
import {useUser} from "../../../../auth/AuthProvider";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import {cpfMask} from "../../../../utils/cpfMask";
import OpenPopupEdit from "../OpenPopupEdit";
import {ApiUrl} from "../../../../auth/authMethods";
import applyRegex from "../../../../utils/ApplyRegex";

export default function InputsObjects({
                                          obj, setObj, baseUrl,
                                          setBlockSubmit,
                                          message, emailRequired,
                                          canBeRemoved, removeFunction,
                                          rgInput, errorMessage, setErrorMessage,
                                          errorColumn, setErrorColumn,
                                          setLoadData, openPopupEdit,
                                          notShowAddressInputs,
                                          isCredor
                                      }) {

    const {user} = useUser()

    const haveId = obj.id !== undefined && obj.id !== null

    const docRef = useRef()

    const inputNumberRef = useRef(null)

    const [cpf, setCpf] = useState(obj?.cpf ? obj.cpf : '')

    const defaultValueCnpj = obj?.cnpj ?
        obj.cnpj :
        isCredor && obj.index === 0 ?
            '02.219.378/0001-06' : ''

    const [cnpj, setCnpj] = useState(defaultValueCnpj)

    const [id, setId] = useState(obj.id)
    useEffect(() => {
        if (obj.id !== id && !(!id && !obj.id)) {
            setCpf(obj?.cpf ? obj.cpf : '')
            setCnpj(obj?.cnpj ? obj.cnpj : '')
            setId(obj.id)
        }
    }, [obj.id])

    const [errorCep, setErrorCep] = useState(false)
    const [errorCepText, setErrorCepText] = useState(false)
    const [errorCnpj, setErrorCnpj] = useState(false)
    const [errorCnpjText, setErrorCnpjText] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [errorCpfText, setErrorCpfText] = useState(false)

    const [cidadeList, setCidadeList] = useState([])
    const [cidadeId, setCidadeId] = useState('')

    const [isCpf, setIsCpf] = useState(false);
    const [showAllInputs, setShowAllInputs] = useState(false)

    const [showAddressInputs, setShowAddressInputs] = useState(false)

    useEffect(() => {
        if(!notShowAddressInputs){
            if (!showAddressInputs && obj && obj.endereco){
                obj.endereco.cep = '';
                obj.endereco.logradouro = '';
                obj.endereco.numero = '';
                obj.endereco.complemento = '';
                obj.endereco.bairro = '';
                setCidadeId('')
            }
        }

    }, [notShowAddressInputs, showAddressInputs])

    const handleToggleAddressInputs = () => {
        setShowAddressInputs(!showAddressInputs);
    };

    useEffect(() => {
        if (obj?.cnpj !== null && obj?.cpf === null) {
            setIsCpf(false)
        } else if (obj?.cnpj === null && obj?.cpf !== null) {
            setIsCpf(true)
        }
    }, [])

    const toggleSwitch = () => {
        setCnpj('')
        setErrorCnpj(false)
        setErrorCnpjText('')

        setCpf('')
        setErrorCpf(false)
        setErrorCpfText('')

        setObj(prevObj => {
            return {
                ...prevObj,
                cnpj: '',
                cpf: ''
            }
        })
        setShowAllInputs(false)

        setIsCpf((prev) => !prev);
    };

    const handleEditUser = (user, type) => {
        return (
            <OpenPopupEdit
                setLoadData={setLoadData}
                item={user}
                popupType={type}
            />
        );
    };

    // validação de email
    const [errorEmail, setErrorEmail] = useState('')
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    useEffect(() => {
        if (obj?.email?.length > 3 && !emailRegex.test(obj?.email)) {
            setErrorEmail('Email inválido!')
            setBlockSubmit(true)
        } else {
            setErrorEmail('')
            setBlockSubmit(false)
        }
    }, [obj?.email])

    // effect para controle e validação de cnpj
    useEffect(() => {
        const execute = async () => {
            changeCnpj(cnpj)
            setErrorColumn("")
            setErrorMessage("")
            setBlockSubmit(true)
            if (cnpj.length === 18) {
                setBlockSubmit(false)
                if (!validadeCnpj(cnpj)) {
                    setErrorCnpj('cnpj')
                    setErrorCnpjText('Cnpj inválido!')
                    docRef.current.focus();
                    setBlockSubmit(true)
                    setShowAllInputs(false)
                    setObj({...obj, id: null})
                    setShowAllInputs(false)
                } else {
                    setErrorCnpj(false)
                    setErrorCnpjText('')
                    const resp = await axios.get(baseUrl + '/find-cnpj?cnpj=' + cnpj, {
                        headers: {
                            'Authorization': `Bearer ${user?.token}`
                        },
                    })
                    if (resp.data.objectResponse) {
                        setObj(resp.data.objectResponse)
                        setShowAllInputs(false)
                    } else {
                        setShowAllInputs(true)
                        setObj({cnpj})
                    }
                }
            } else {
                setErrorCnpj(false)
                setErrorCnpjText('')
                setObj({...obj, id: null})
                setId(null)
                setShowAllInputs(false)
            }
        }

        execute()
    }, [cnpj])

    // effect para controle e validação de cpf
    useEffect(() => {
        const execute = async () => {
            changeCpf(cpf)
            setErrorColumn("");
            setErrorMessage("");
            setBlockSubmit(true);
            if (cpf.length === 14) {
                setBlockSubmit(false);
                if (!validadeCpf(cpf)) {
                    setErrorCpf("cpf");
                    setErrorCpfText("CPF inválido!");
                    docRef.current.focus();
                    setBlockSubmit(true);
                    setShowAllInputs(false)
                    setObj({...obj, id: null});
                    setShowAllInputs(false)
                } else {
                    setErrorCpf(false);
                    setErrorCpfText('');
                    const resp = await axios.get(baseUrl + '/find-cpf?cpf=' + cpf, {
                        headers: {
                            'Authorization': `Bearer ${user?.token}`
                        },
                    });
                    if (resp.data.objectResponse) {
                        setObj(resp.data.objectResponse);
                        setShowAllInputs(false);
                    } else {
                        setShowAllInputs(true);
                        setObj({cpf});
                    }
                }
            } else {
                setErrorCpf(false);
                setErrorCpfText('');
                setObj({...obj, id: null});
                setId(null)
                setShowAllInputs(false)
            }
        };

        execute();
    }, [cpf]);

    useEffect(() => {
        setErrorCep(false)
        setErrorCepText(false)
        setErrorCnpj(false)
        setErrorCnpjText(false)
        setErrorCpf(false)
        setErrorCpfText(false)
        switch (errorColumn) {
            case "cnpj":
                setErrorCnpj(true)
                setErrorCnpjText(errorMessage)
                document.getElementById('input-cnpj').focus()
                break
            case "cpf":
                setErrorCpf(true)
                setErrorCpfText("CPF inválido")
                document.getElementById('input-cpf').focus()
                break
            case "cep":
                setErrorCep(true)
                setErrorCepText(errorMessage)
                break
            case "email":
                setErrorEmail(errorMessage)
                break
        }
    }, [errorColumn, errorMessage])

    const changeCnpj = (cnpj) => {
        if (cnpj !== undefined)
            setObj({
                ...obj,
                cnpj: cnpj
                    .replaceAll('.', '')
                    .replaceAll('-', '')
                    .replaceAll('/', '')
            })
    }

    const changeCpf = (cpf) => {
        if (cpf !== undefined) {
            // Remove non-numeric characters from the CPF
            const numericCpf = cpf.replace(/\D/g, '');

            setObj({
                ...obj,
                cpf: numericCpf.replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1-$2'),
            });
        }
    };

    const buscarCep = async (cepRecebido) => {
        if (validaCep(cepRecebido)) {
            let url = `https://viacep.com.br/ws/${cepRecebido}/json/`

            // const resp = await fetch(url, {mode:'no-cors'})
            const resp = await fetch(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'aplication/json'
                }
            })
            const data = await resp.json()


            setObj({
                ...obj,
                endereco: {
                    ...obj?.endereco,
                    logradouro: data.logradouro ? data.logradouro : null,
                    bairro: data.bairro ? data.bairro : null,
                    cep: cepRecebido,
                }
            })
            if (data.erro !== true)
                inputNumberRef.current.focus()
        }
    }
    //Validacao do CEP digitado
    const validaCep = (cepRecebido) => {
        if (cepRecebido === undefined)
            return false
        cepRecebido = cepRecebido.replace(/[^0-9]/gi, "");
        return cepRecebido?.length === 8
    }

    // effect para alterar cidade dentro do objeto a partir do state
    useEffect(() => {
        setObj({
            ...obj,
            cidadeId
        })
    }, [cidadeId])

    const styleForCnpjInput = canBeRemoved ? {width: '80%'} : {}

    const onBlurDocInput = () => {
        if (!isCpf) {
            if (cnpj.length < 18) {
                setErrorCnpj(true)
                setErrorCnpjText('O CNPJ deve ter 18 caracteres!')
            }
        } else {
            if (cpf.length < 14) {
                setErrorCpf(true)
                setErrorCpfText('O CPF deve ter 14 caracteres!')
            }
        }
    }

    const renderInputField = (disabled) => {
        return (
            <>
                {isCpf ? (
                    <TextField
                        label="CPF"
                        required={true}
                        inputProps={{
                            maxLength: 14,
                            minLength: 14,
                        }}
                        error={errorCpf}
                        helperText={errorCpfText}
                        value={cpf}
                        onChange={(e) => {
                            setCpf(cpfMask(e.target.value))
                        }}
                        ref={docRef}
                        onBlur={onBlurDocInput}
                        fullWidth
                        InputLabelProps={{
                            style: {
                                zIndex: 0,
                            },
                        }}
                        style={styleForCnpjInput}
                        disabled={disabled}
                    />
                ) : (
                    <TextField
                        label="CNPJ"
                        required={true}
                        inputProps={{
                            maxLength: 18,
                            minLength: 18,
                        }}
                        error={errorCnpj}
                        helperText={errorCnpjText}
                        value={cnpj}
                        onChange={(e) => {
                            setCnpj(cnpjMask(e.target.value))
                        }}
                        ref={docRef}
                        onBlur={onBlurDocInput}
                        fullWidth
                        InputLabelProps={{
                            style: {
                                zIndex: 0,
                            },
                        }}
                        style={styleForCnpjInput}
                        disabled={disabled}
                    />
                )}
            </>
        );
    }


    return (
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{marginRight: '10px'}}>
                    <Typography variant="body1">
                        {isCpf ? "CPF" : "CNPJ"}
                    </Typography>
                    <Switch
                        checked={isCpf}
                        onChange={toggleSwitch}
                        color="primary"
                        inputProps={{'aria-label': 'alternar entre CNPJ e CPF'}}
                        disabled={haveId && openPopupEdit}
                    />
                </div>

                {renderInputField((haveId && openPopupEdit))}

                <div style={{marginBottom: '10px', marginLeft: '8px'}}>
                    {obj && message && openPopupEdit && haveId ? handleEditUser(obj, message) : ''}
                </div>
            </div>

            {canBeRemoved &&
                <Button
                    onClick={removeFunction}
                    style={{
                        width: '18%',
                        marginLeft: '2%',
                        height: '3.5rem',
                        marginBottom: '10px'
                    }}
                    variant="outline-secondary">
                    Remover
                </Button>
            }

            {obj?.id && (
                obj?.cnpj?.length === 18 || obj?.cpf?.length === 14 ||
                obj?.cnpj?.length === 14 || obj?.cpf?.length === 11
            ) && (
                <p style={{color: 'green'}}>
                    <CheckIcon/> {message}
                </p>
            )}

            {showAllInputs &&
                <>
                    <TextField
                        label="Nome"
                        required={true}
                        inputProps={{
                            maxLength: 48
                        }}
                        autoFocus
                        type="text"
                        erortext="Campo obrigatório!"
                        value={obj?.nome}
                        onChange={(e) => setObj({...obj, nome: applyRegex(e.target.value, /^[ a-zA-ZÀ-ÖØ-öø-ÿ]+/)})}
                        fullWidth
                        InputLabelProps={{
                            style: {
                                zIndex: 0
                            },
                        }}
                    />
                    {rgInput && (
                        <TextField
                            label="RG"
                            required={false}
                            inputProps={{
                                maxLength: 20
                            }}
                            type="text"
                            erortext="Campo obrigatório!"
                            value={obj?.rg}
                            onChange={(e) => setObj({...obj, rg: e.target.value})}
                            fullWidth
                            InputLabelProps={{
                                style: {
                                    zIndex: 0
                                },
                            }}
                        />
                    )}

                    <TextField
                        label="Email"
                        required={emailRequired}
                        inputProps={{
                            maxLength: 80
                        }}
                        type="email"
                        erortext="Campo obrigatório!"
                        value={obj?.email}
                        error={!!errorEmail}
                        helperText={errorEmail}
                        onChange={(e) => setObj({...obj, email: e.target.value})}
                        fullWidth
                        InputLabelProps={{
                            style: {
                                zIndex: 0
                            },
                        }}
                    />
                    {/* Checkbox para adicionar endereço */}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="body1">Adicionar endereço:</Typography>
                        <Switch
                            checked={showAddressInputs}
                            onChange={handleToggleAddressInputs}
                            color="primary"
                            inputProps={{'aria-label': 'alternar para adicionar endereço'}}
                        />
                    </div>

                    {/* Inputs de endereço condicionalmente exibidos */}
                    {showAddressInputs &&
                        <>
                            {/* Inputs de endereço aqui */}
                            <TextField
                                label="CEP"
                                required={true}
                                inputProps={{
                                    maxLength: 8,
                                    minLength: 8,
                                }}
                                type="text"
                                errorText="Campo obrigatório!"
                                value={obj?.endereco?.cep}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^[0-9]+$/.test(inputValue) || inputValue === '') {
                                        setObj({
                                            ...obj,
                                            endereco: {
                                                ...obj?.endereco,
                                                cep: inputValue,
                                            },
                                        });
                                        buscarCep(inputValue);
                                    }
                                }}
                                error={errorCep}
                                helperText={errorCepText}
                                fullWidth
                            />

                            <TextField
                                label="Logradouro"
                                required={true}
                                inputProps={{
                                    maxLength: 38
                                }}
                                type="text"
                                erortext="Campo obrigatório!"
                                value={obj?.endereco?.logradouro ? obj?.endereco?.logradouro : ''}
                                onChange={(e) => setObj({
                                    ...obj,
                                    endereco: {
                                        ...obj?.endereco,
                                        logradouro: e.target.value
                                    }
                                })}
                                fullWidth
                            />
                            <TextField
                                id="input-number"
                                label="Número"
                                required={true}
                                inputProps={{
                                    maxLength: 10
                                }}
                                type="text"
                                erortext="Campo obrigatório!"
                                value={obj?.endereco?.numero}
                                onChange={(e) => setObj({
                                    ...obj,
                                    endereco: {
                                        ...obj?.endereco,
                                        numero: e.target.value
                                    }
                                })}
                                fullWidth
                                inputRef={inputNumberRef}
                            />
                            <TextField
                                label="Complemento"
                                inputProps={{
                                    maxLength: 38
                                }}
                                type="text"
                                erortext="Campo obrigatório!"
                                value={obj?.endereco?.complemento}
                                onChange={(e) => setObj({
                                    ...obj,
                                    endereco: {
                                        ...obj?.endereco,
                                        complemento: e.target.value
                                    }
                                })}
                                fullWidth
                            />
                            <TextField
                                label="Bairro"
                                required={true}
                                inputProps={{
                                    maxLength: 38
                                }}
                                type="text"
                                erortext="Campo obrigatório!"
                                value={obj?.endereco?.bairro ? obj?.endereco?.bairro : ''}
                                onChange={(e) => setObj({
                                    ...obj,
                                    endereco: {
                                        ...obj?.endereco,
                                        bairro: e.target.value
                                    }
                                })}
                                fullWidth
                                helperText='Digite o nome da cidade para filtrar as opções (A consulta pode demorar um pouco!)'
                            />
                            <SelectInput
                                url={ApiUrl + '/cidade?size=20000'}
                                list={cidadeList}
                                set={setCidadeList}
                                setObj={setCidadeId}
                                defaultValue={cidadeId}
                                returnList={() => {
                                    let returnList = []
                                    cidadeList.forEach((item) => {
                                        returnList.push({value: item.id, label: `${item.nome} - ${item.estado.sigla}`})
                                    })
                                    return returnList
                                }}
                                placeholder={"Cidade *"}
                                required={true}
                                fullWidth
                            />
                        </>
                    }
                </>
            }
        </>
    )
}