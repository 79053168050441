import ContentContainer from "../../component/ContentContainer";
import {Divider} from "@mui/material";
import Dashboard from "../../component/Dashboard";
import React, {useEffect, useState} from "react";
import NoticiasContent from "../Noticias/NoticiasContent";
import BiomassaGrafico from "../../component/Dashboard/BiomassaGrafico";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";
import axios from "axios";
import {numberWithPoints} from "../../utils/numberWithPoints";


export default function HomePage(){

    const {user} = useUser()

    const [hectares, setHectares] = useState()
    const [quantidade, setQuantidade] = useState()

    useEffect(() => {

        const headersData = {
            Authorization: `Bearer ${user?.token}`,
        }

        axios.get(`${ApiUrl}/arquivos/somar-hectares`, { headers: headersData })
            .then(function (response) {
                setHectares(response.data);
            })
            .catch(function (errorReceived) {
                if (errorReceived.response.status === 401) {
                    console.log("Sem autorização.");
                } else {
                    console.log("Error:", errorReceived);
                }
            });

        axios.get(`${ApiUrl}/cpr/somar-quantidade-produtos`, { headers: headersData })
            .then(function (response) {
                setQuantidade(response.data);
            })
            .catch(function (errorReceived) {
                if (errorReceived.response.status === 401) {
                    console.log("Sem autorização.");
                } else {
                    console.log("Error:", errorReceived);
                }
            });
    }, []);

    return (
        <ContentContainer width='xl'>
            <div style={{
                width: '100%',
                textAlign: 'center'
            }}>
                <h3>Bem vindo(a) ao
                    <img
                        alt=""
                        src="/marca-verde.png"
                        width="160"
                        className="d-inline-block align-top"
                    />{' '}
                </h3>
                <div style={{display: 'flex', flexDirection: 'row', fontSize: '15px', opacity: '60%', fontWeight: '700'}}>
                    <span style={{marginRight: 'auto', marginLeft: '5px'}}>Hectares monitorados: {numberWithPoints(hectares, 2)} ha</span>
                    <span style={{marginRight: '12px'}}>Produção em sacas financiadas: {numberWithPoints(quantidade, 2)} sacas</span>
                </div>
                <Divider color='black' className='m-2' style={{ width: '98.5%' }}/>
            </div>
            {/*<div className='text-center mt-2'>*/}
            {/*    <img src="/home-img.jpeg" style={{*/}
            {/*        width: '88%',*/}
            {/*        borderRadius: '20px'*/}
            {/*    }} alt=''/>*/}
            {/*</div>*/}

            {/*    Parte de gráficos    */}
            <Dashboard/>

            {/*<BiomassaGrafico/>*/}
            {/*<NoticiasContent/>*/}

        </ContentContainer>
    )
}