import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterFormAlarmes from "./FilterFormAlarme";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProdutoActions from "./AlarmeActions";
import { ApiUrl } from "../../../auth/authMethods";
import { boxClasses } from "@mui/material";
import AlarmeActions from "./AlarmeActions";

export default function ListAlarmes({
                                        baseUrl, loadData,
                                        setLoadData, size, page,
                                        setTotalPages, setTotalElements,
                                        sort, setSort, updateCode, setPage
                                    }) {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})


    const titles = [
        "Produto",
        "Pluviometricidade Miníma",
        "Pluviometricidade Máxima",
        "Temperatura Miníma",
        "Temperatura Máxima",
        "Biomassa Mínima",
        // "Estado",
    ]


    return (
        <>
            <FilterFormAlarmes filter={filter} setFilter={setFilter} baseUrl={baseUrl} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.produto.tipo + " - " + item.produto.variedade}</td>
                        <td>{item.pluviometricidadeMinima + "mm  por "+ item.diasAbaixoPluviosidade + " dias" }</td>
                        <td>{item.pluviometricidadeMaxima + "mm  por "+ item.diasAcimaPluviosidade + " dias"}</td>
                        <td>{item.temperaturaMinima + "°C  por "+ item.diasAbaixoTemperatura + " dias"}</td>
                        <td>{item.temperaturaMaxima + "°C  por "+ item.diasAcimaTemperatura + " dias"}</td>
                        <td>{item.biomassaAbaixo + "% por "+ item.diasAbaixoBiomassa + " dias"}</td>
                        {/*<td>{item.estado.nome}</td>*/}
                        <td>
                            <span>
                                <AlarmeActions item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>
                            </span>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}