import ContentContainer from "../../../component/ContentContainer";
import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import {CardActionArea, Grid, Stack} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GrassIcon from "@mui/icons-material/Grass";
import CloudIcon from "@mui/icons-material/Cloud";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import BiomassaGrafico from "../../../component/Dashboard/BiomassaGrafico";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {Link as RouterLink, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";
import {ApiUrl} from "../../../auth/authMethods";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


const MonitoringField = () => {

    const {user} = useUser()

    const {id} = useParams()

    const [campo, setCampo] = useState("");

    const [showAll, setShowAll] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [arquivos, setArquivos] = useState([])

    const [images, setImages] = useState([])

    const [dateImages, setDateImages] = useState([])

    const [biomassa, setBiomassa] = useState([])

    const [cloud, setClouds] = useState([])

    const [itemsToShow, setItemsToShow] = useState(8); // Controla o número de itens a serem exibidos

    const handleAddItems = () => {
        setItemsToShow(prevItems => prevItems + 4);
    };

    const handleShowAllItems = () => {
        setItemsToShow(prevItems => prevItems + (arquivos.length - itemsToShow));
    };
    const handleRemoveAllItems = () => {
        setItemsToShow(8);
    };
    const handleRemoveItems = () => {
        setItemsToShow(prevItems => prevItems - 4);
    };


    // console.log("id recebido:", id)

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14, //Tamanho da fonte
        },
        '&:not(:last-child)': {
            borderRight: '1px solid rgba(224, 224, 224, 1)', //Bota a BorDa
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createData(day, temperature, coveredCloud, precipitation, moisture, condensation) {
        return {day, temperature, coveredCloud, precipitation, moisture, condensation};
    }

    // Lista

    // console.log(images[0])

    const items = [];

    for (let i = 0; i < arquivos.length; i++) {
        items.push({
            date: normalizeTimestemp(dateImages[i]),
            image: images[i],
            biomass: " Biomassa:" + biomassa[i] + "%",
            cloud: " Nuvens: " + cloud[i] + "%"
        });
    }

    const rows = [
        createData('21.02.2024', <div>24°C <br/> (Min. 22°C Máx. 28°C)</div>, <div><CloudIcon/> 100%</div>, '12,24 mm',
            <div><WaterDropIcon/> 90% </div>, '22\n' +
            '°C'),
        createData('20.02.2024', <div>26°C <br/> (Min. 23°C Máx. 30°C)</div>, <div><CloudIcon/> 90%</div>, '3 mm', <div>
            <WaterDropIcon/> 87% </div>, '23\n' +
            '°C'),
        createData('19.02.2024', <div>25°C <br/> (Min. 23°C Máx. 28°C)</div>, <div><CloudIcon/> 99% </div>, '11,34 mm',
            <div><WaterDropIcon/> 94% </div>, '24\n' +
            '°C'),
        createData('18.02.2024', <div>25°C <br/> (Min. 24°C Máx. 29°C)</div>, <div><CloudIcon/> 94% </div>, '9,53 mm',
            <div><WaterDropIcon/> 94% </div>, '24\n' +
            '°C'),
        createData('17.02.2024', <div>26°C <br/> (Min. 24°C Máx. 29°C)</div>, <div><CloudIcon/> 94% </div>, '0,56 mm',
            <div><WaterDropIcon/> 90% </div>, '24\n' +
            '°C'),
        createData('16.02.2024', <div>26°C <br/> (Min. 23°C Máx. 32°C)</div>, <div><CloudIcon/> 97% </div>, '11,18 mm',
            <div><WaterDropIcon/> 89% </div>, '24\n' +
            '°C'),
        createData('15.02.2024', <div>26°C <br/> (Min. 23°C Máx. 32°C)</div>, <div><CloudIcon/> 87% </div>, '2,99 mm',
            <div><WaterDropIcon/> 87% </div>, '23\n' +
            '°C'),
        createData('14.02.2024', <div>27°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 83% </div>, '1 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('13.02.2024', <div>26°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 89% </div>, '11,54 mm',
            <div><WaterDropIcon/> 88% </div>, '24\n' +
            '°C'),
        createData('12.02.2024', <div>25°C <br/> (Min. 23°C Máx. 28°C)</div>, <div><CloudIcon/> 100% </div>, '9,37 mm',
            <div><WaterDropIcon/> 96% </div>, '24\n' +
            '°C'),
        createData('11.02.2024', <div>27°C <br/> (Min. 24°C Máx. 31°C)</div>, <div><CloudIcon/> 78% </div>, '11,78 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('10.02.2024', <div>27°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 82% </div>, '0,44 mm',
            <div><WaterDropIcon/> 83% </div>, '23\n' +
            '°C'),
        createData('9.02.2024', <div>25°C <br/> (Min. 24°C Máx. 28°C)</div>, <div><CloudIcon/> 98% </div>, '5,6 mm',
            <div><WaterDropIcon/> 92% </div>, '24\n' +
            '°C'),
        createData('8.02.2024', <div>27°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 91% </div>, '15,28 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('7.02.2024', <div>27°C <br/> (Min. 23°C Máx. 33°C)</div>, <div><CloudIcon/> 59% </div>, '0,18 mm',
            <div><WaterDropIcon/> 84% </div>, '23\n' +
            '°C'),
        createData('6.02.2024', <div>26°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 90% </div>, '14,76 mm',
            <div><WaterDropIcon/> 87% </div>, '24\n' +
            '°C'),
        createData('5.02.2024', <div>27°C <br/> (Min. 23°C Máx. 33°C)</div>, <div><CloudIcon/> 82% </div>, '19,33 mm',
            <div><WaterDropIcon/> 83% </div>, '23\n' +
            '°C'),
        createData('4.02.2024', <div>26°C <br/> (Min. 24°C Máx. 30°C)</div>, <div><CloudIcon/> 50% </div>, '1,79 mm',
            <div><WaterDropIcon/> 87% </div>, '24\n' +
            '°C'),
        createData('3.02.2024', <div>27°C <br/> (Min. 23°C Máx. 33°C) </div>, <div><CloudIcon/> 22% </div>, '0 mm ',
            <div><WaterDropIcon/> 82% </div>, '23\n' +
            '°C'),
        createData('2.02.2024', <div>26°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 82% </div>, '24,15 mm',
            <div><WaterDropIcon/> 87% </div>, '23\n' +
            '°C'),
        createData('1.02.2024', <div>25°C <br/> (Min. 23°C Máx. 29°C)</div>, <div><CloudIcon/> 100% </div>, '73,72 mm',
            <div><WaterDropIcon/> 94% </div>, '24\n' +
            '°C'),
        createData('31.01.2024', <div>26°C <br/> (Min. 23°C Máx. 31°C)</div>, <div><CloudIcon/> 98% </div>, '45,49 mm',
            <div><WaterDropIcon/> 91% </div>, '24\n' +
            '°C'),
        createData('30.01.2024', <div>27°C <br/> (Min. 23°C Máx. 32°C)</div>, <div><CloudIcon/> 71% </div>, '2,66 mm ',
            <div><WaterDropIcon/> 82% </div>, '23\n' +
            '°C'),
        createData('29.01.2024', <div>27°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 88% </div>, '15,03 mm',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
        createData('28.01.2024', <div>26°C <br/> (Min. 24°C Máx. 30°C)</div>, <div><CloudIcon/> 97% </div>, '71,97 mm',
            <div><WaterDropIcon/> 91% </div>, '24\n' +
            '°C'),
        createData('27.01.2024', <div>26°C <br/> (Min. 24°C Máx. 32°C)</div>, <div><CloudIcon/> 87% </div>, '1,67 mm ',
            <div><WaterDropIcon/> 84% </div>, '\n' +
            'Máx. 6 km/h\n' +
            '23\n' +
            '°C'),
        createData('26.01.2024', <div>28°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 84% </div>, '0,02 mm ',
            <div><WaterDropIcon/> 76% </div>, '23\n' +
            '°C'),
        createData('25.01.2024', <div>28°C <br/> (Min. 24°C Máx. 34°C)</div>, <div><CloudIcon/> 74% </div>, '0,32 mm ',
            <div><WaterDropIcon/> 76% </div>, '23\n' +
            '°C'),
        createData('24.01.2024', <div>27°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 80% </div>, '0,07 mm ',
            <div><WaterDropIcon/> 82% </div>, '23\n' +
            '°C'),
        createData('23.01.2024', <div>28°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 87% </div>, '2,65 mm ',
            <div><WaterDropIcon/> 79% </div>, '23\n' +
            '°C'),
        createData('22.01.2024', <div>27°C <br/> (Min. 24°C Máx. 33°C)</div>, <div><CloudIcon/> 63% </div>, '1,16 mm ',
            <div><WaterDropIcon/> 85% </div>, '24\n' +
            '°C'),
    ];

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${user?.token}`
        }
        axios.get(`${ApiUrl}/arquivos?id=${id}`, {headers})
            .then(function (response) {

                setCampo(response.data.objectResponse.nome);
                // console.log({campo})
            })
            .catch(function (errorReceived) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(errorReceived);
            });
    }, []);

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${user?.token}`
        }
        axios.get(`${ApiUrl}/biomassa/${id}`, {headers})
            .then(function (response) {
                const arquivos = response.data;
                const urls = arquivos.map(obj => obj.urlImagemSkyfield);
                const dates = arquivos.map(obj => obj.dataCriacao);
                const massas = arquivos.map(obj => obj.biomassa);
                const nuvens = arquivos.map(obj => obj.nuvem);

                setArquivos(arquivos)
                setDateImages(dates)
                setClouds(nuvens)
                setBiomassa(massas)
                setImages(urls);
            })
            .catch(function (errorReceived) {
                console.log(errorReceived);
            }).finally(() => {
            setLoaded(true)
        })
    }, []);

    const handleVoltarClick = () => {
        // Fechar a janela atual
        window.close();
    };


    console.log(arquivos.length)

    // console.log({ images })
    // console.log({ dateImages })

    return (
        <ContentContainer width='xl'>
            {!loaded ?
                <BasicLoader/> :
                arquivos.length < 1 ?
                    <>
                        <div style={{display: 'flex', alignItems: 'center', borderRadius: "5px"}}>
                            <h2 style={{marginRight: '39vw', padding: '15px'}}> Monitoramento do
                                Campo: {campo.split(".")[0]} </h2>
                            <Button variant="contained" component={RouterLink} to="/cprs" onClick={handleVoltarClick}>
                                Voltar
                            </Button>
                        </div>

                        <h4 className="text-center mt-5">
                            Ainda não há registros de monitoramento para este campo, em breve as
                            informações estarão disponíveis!
                        </h4>
                    </> :
                    <>
                        <div style={{display: 'flex', alignItems: 'center', borderRadius: "5px"}}>
                            <h2 style={{marginRight: '39vw', padding: '15px'}}> Monitoramento do
                                Campo: {campo.split(".")[0]} </h2>
                            <Button variant="contained" component={RouterLink} to="/cprs" onClick={handleVoltarClick}>
                                Voltar
                            </Button>
                        </div>
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                            {items.slice(0, itemsToShow).map((item, index) => (
                                // Renderização dos itens
                                <Card key={index} sx={{maxWidth: '30%', margin: '30px', maxHeight: '60vh'}}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image={item.image}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {item.date}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                <GrassIcon/>{item.biomass}
                                                <br/>
                                                <CloudIcon/>{item.cloud}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div>
                                <Button
                                    onClick={handleRemoveItems}
                                    disabled={itemsToShow === 8}
                                    color={'warning'}
                                    variant={'outlined'}
                                    startIcon={<RemoveCircleOutlineIcon/>}
                                    style={{ marginRight: '10px' }}
                                >
                                    Mostrar Menos
                                </Button>
                                <Button
                                    onClick={handleRemoveAllItems}
                                    disabled={itemsToShow === 8}
                                    color={'inherit'}
                                    variant={'outlined'}
                                    startIcon={<RemoveCircleOutlineIcon/>}
                                >
                                    Mostrar os Primeiros
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={handleShowAllItems}
                                    disabled={itemsToShow >= arquivos.length}
                                    color={'inherit'}
                                    variant={'outlined'}
                                    endIcon={<AddCircleOutlineIcon />}
                                    style={{ marginRight: '10px' }}
                                >
                                    Mostrar Todos
                                </Button>
                                <Button
                                    onClick={handleAddItems}
                                    disabled={itemsToShow >= arquivos.length}
                                    color={'success'}
                                    variant={'outlined'}
                                    endIcon={<AddCircleOutlineIcon />}
                                >
                                    Mostrar Mais
                                </Button>
                            </div>
                        </div>


                        <hr/>
                        <h5 className='text-center'>
                            Biomassa
                            <Grid >
                                <br/>
                                <Stack alignItems="center" >
                                    <Button
                                        size="small"
                                        onClick={() => setShowAll(false)}
                                        color={!showAll ? 'primary' : 'secondary'}
                                        variant={!showAll ? 'outlined' : 'text'}
                                    >
                                        Mostrar Apenas Últimos Registros
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => setShowAll(true)}
                                        color={showAll ? 'primary' : 'secondary'}
                                        variant={showAll ? 'outlined' : 'text'}
                                    >
                                        Mostrar Todos os Registros
                                    </Button>
                                </Stack>
                            </Grid>
                        </h5>
                        <BiomassaGrafico arquivos={arquivos} showAll={showAll}/>:
                        <hr/>
                        <h5 className='text-center'>
                            Clima
                        </h5>
                        <TableContainer component={Paper} style={{maxHeight: 400}}>
                            <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell><b>Dia </b></StyledTableCell>
                                        <StyledTableCell align="right"> <b>Temperatura </b></StyledTableCell>
                                        <StyledTableCell align="right"> <b>Nuvem Cob.&nbsp;(%) </b></StyledTableCell>
                                        <StyledTableCell align="right"> <b>Precipitação&nbsp;(mm) </b></StyledTableCell>
                                        <StyledTableCell align="right"> <b>Umidade&nbsp;(%) </b></StyledTableCell>
                                        <StyledTableCell align="right"> <b>Ponto de <br/> condensação&nbsp;(C)
                                        </b></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.day}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.day}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{row.temperature}</StyledTableCell>
                                            <StyledTableCell align="right">{row.coveredCloud}</StyledTableCell>
                                            <StyledTableCell align="right">{row.precipitation}</StyledTableCell>
                                            <StyledTableCell align="right">{row.moisture}</StyledTableCell>
                                            <StyledTableCell align="right">{row.condensation}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
            }
        </ContentContainer>
    )
}
export default MonitoringField;