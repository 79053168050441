import {useParams} from "react-router-dom";
import {useState} from "react";
import Button from "@mui/material/Button";
import PasswordIcon from "@mui/icons-material/Password";
import InputPassword from "../../component/InputPassword/InputPassword";
import Box from "@mui/material/Box";
import axios from "axios";
import {ApiUrl} from "../../auth/authMethods";
import verifyPassword from "../../utils/verifyPassword";
import displayAlertMsg from "../../component/AlertMsg/displayAlertMsg";
import ChangePasswordConfirmation from "../ChangePassword/Components/ChangePasswordConfirmation";
import ContentContainer from "../../component/ContentContainer";


export default function ResetPassword() {
    const {resetToken} = useParams()

    const [isLoading, setIsLoading] = useState(false);
    const [changed, setChanged] = useState(false)

    const [error, setError] = useState(false)
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [passwordConfError, setPasswordConfError] = useState(false)

    const changePassword = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);

        const newPassword = data.get('newPassword')
        const passwordConf = data.get('passwordConf')

        let passwordValid = verifyPassword(newPassword)
        if (passwordValid !== 'Senha válida') {
            setError(displayAlertMsg(passwordValid))
            setNewPasswordError(true)
            return
        }

        if (newPassword !== passwordConf) {
            setError(
                displayAlertMsg('Confirme corretamente a nova senha!')
            )
            setPasswordConfError(true)
            return
        }

        const dataToSend = `?resetToken=${resetToken}&newPassword=${newPassword}`

        setIsLoading(true)

        axios.post(ApiUrl + '/auth/reset' + dataToSend)
            .then((_) => {
                setChanged(true)
            }).catch((_) => {
            setError(displayAlertMsg('Erro ao fazer alteração de senha. Tente novamente mais tarde'))
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
        >
            Alterar
        </Button>

    return <ContentContainer>
        {!changed ?
            <Box component="form" onSubmit={changePassword} sx={{mt: 1}}>
                <div className="text-center">
                    <PasswordIcon fontSize='large' sx={{fontSize: '4rem'}}/><br/>
                    <b>Alterar sua senha </b><br/>
                    <div style={{border: '1px solid black', fontSize: '.8rem', margin: '2% 0'}}>
                        <p>Requisitos para a nova senha:</p>
                        <ul style={{margin: '-1% 0 1% 0'}} className='text-start '>
                            <li>No mínimo 8 caracteres</li>
                            <li>Possuir ao menos uma letra maiúscula</li>
                            <li>Possuir ao menos uma letra minúscula</li>
                            <li>Possuir ao menos um caractere especial</li>
                        </ul>
                    </div>
                    Preencha os campos abaixo.
                </div>
                {error}
                <InputPassword
                    id={'newPassword'}
                    name={'newPassword'}
                    label={'Nova senha'}
                    error={newPasswordError}
                />
                <InputPassword
                    id={'passwordConf'}
                    name={'passwordConf'}
                    label={'Confirmar nova senha'}
                    error={passwordConfError}
                />

                {button}
            </Box> :
            <ChangePasswordConfirmation linkToLogin={true}/>
        }
    </ContentContainer>
}