import TextField from "@mui/material/TextField";
// import Select from "@mui/material/Select";
import MultipleSelect from "../../../component/Select";
import MenuItem from "@mui/material/MenuItem";
import SelectInput from "../../../component/SelectInput";
import React, { useEffect, useState } from "react";
import applyRegex from "../../../utils/ApplyRegex";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import { useUser } from "../../../auth/AuthProvider";
import { ApiUrl } from "../../../auth/authMethods";
import { ApiOutlined } from "@mui/icons-material";

export default function InputsAlarme({
  tipo,
  setTipo,
  biomassa,
  setBiomassa,
  // estadoId,
  // setEstadoId,
  produtoId,
  setProdutoId,
  variedade,
  setVariedade,
  setDuracaoDias,
  duracaoDias,
  pluviometricidadeMaxima,
  setPluviometricidadeMaxima,
  pluviometricidadeMinima,
  setPluviometricidadeMinima,
  temperaturaMaxima,
  setTemperaturaMaxima,
  temperaturaMinima,
  setTemperaturaMinima,
  errorColumn,
  errorMessage,
  baseUrl,
  isEdit,
  alarmeId,
}) {
  const [productsList, setProductsList] = useState("");
  // const [estadoList, setEstadoList] = useState("");
  const [dataReq, setDataReq] = useState();
  const { user } = useUser();

  useEffect(() => {
    if (!isEdit) {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
      };

      axios
        .get(`${ApiUrl}/produto/${produtoId}`, { headers })
        .then(function (response) {
          const data = response.data.objectResponse;
          setPluviometricidadeMinima(data.pluviometricidadeMinima);
          setPluviometricidadeMaxima(data.pluviometricidadeMaxima);
          setTemperaturaMinima(data.temperaturaMinima);
          setTemperaturaMaxima(data.temperaturaMaxima);
          setDataReq(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
      };

      axios
        .get(`${baseUrl}/${alarmeId}`, { headers })
        .then(function (response) {
          console.log(response)
          const data = response.data.objectResponse;
          setPluviometricidadeMinima(data.pluviometricidadeMinima);
          setPluviometricidadeMaxima(data.pluviometricidadeMaxima);
          setTemperaturaMinima(data.temperaturaMinima);
          setTemperaturaMaxima(data.temperaturaMaxima);
          setDataReq(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [produtoId]);

  return (
    <>
      <SelectInput
        url={ApiUrl + "/produto?size=200"}
        list={productsList}
        set={setProductsList}
        setObj={setProdutoId}
        defaultValue={produtoId}
        returnList={() => {
          let returnList = [];
          productsList.forEach((item) => {
            returnList.push({
              value: item.id,
              label: `${item.tipo} - ${item.variedade}`,
            });
          });
          return returnList;
        }}
        placeholder={"Produto"}
        fullWidth
      />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          label="Pluviometricidade Mínima"
          required={true}
          inputProps={{
            maxLength: 5,
          }}
          type="number"
          errorText="Campo obrigatório!"
          value={pluviometricidadeMinima}
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              setPluviometricidadeMinima(e.target.value);
            }
          }}
          fullWidth
          sx={{ width: "70%", display: "block" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">mm(milímetros)</InputAdornment>
            ),
          }}
        />
        <TextField
          label="Tempo de Tolerância (dias)"
          width="11em"
          type="number"
          value={duracaoDias.toleranciaPluvMinima}
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              setDuracaoDias.setToleranciaPluvMinima(e.target.value);
            }
          }}
        ></TextField>
      </div>

      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          label="Pluviometricidade Máxima"
          required={true}
          inputProps={{
            maxLength: 5,
          }}
          type="number"
          errorText="Campo obrigatório!"
          value={pluviometricidadeMaxima}
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              setPluviometricidadeMaxima(e.target.value);
            }
          }}
          fullWidth
          sx={{ width: "70%", display: "block" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">mm(milímetros)</InputAdornment>
            ),
          }}
        />
        <TextField
          label="Tempo de Tolerância (dias)"
          width="11em"
          type="number"
          value={duracaoDias.toleranciaPluvMaxima}
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              setDuracaoDias.setToleranciaPluvMaxima(e.target.value);
            }
          }}
        ></TextField>
      </div>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          label="Temperatura Mínima"
          required={true}
          inputProps={{
            maxLength: 4,
          }}
          type="number"
          errorText="Campo obrigatório!"
          value={temperaturaMinima}
          onChange={(e) => {
            if (e.target.value.length <= 4) {
              setTemperaturaMinima(e.target.value);
            }
          }}
          fullWidth
          sx={{ width: "70%", display: "block" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">°C</InputAdornment>,
          }}
        />
        <TextField
          label="Tempo de Tolerância (dias)"
          width="11em"
          type="number"
          value={duracaoDias.toleranciaTempMinima}
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              setDuracaoDias.setToleranciaTempMinima(e.target.value);
            }
          }}
        ></TextField>
      </div>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          label="Temperatura Máxima"
          required={true}
          inputProps={{
            maxLength: 4,
          }}
          type="number"
          errorText="Campo obrigatório!"
          value={temperaturaMaxima}
          onChange={(e) => {
            if (e.target.value.length <= 4) {
              setTemperaturaMaxima(e.target.value);
            }
          }}
          fullWidth
          sx={{ width: "70%", display: "block" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">°C</InputAdornment>,
          }}
        />
        <TextField
          label="Tempo de Tolerância (dias)"
          width="11em"
          type="number"
          value={duracaoDias.toleranciaTempMaxima}
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              setDuracaoDias.setToleranciaTempMaxima(e.target.value);
            }
          }}
        ></TextField>
      </div>

      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          label="Biomassa Mínima em período de Plantio"
          required={true}
          inputProps={{
            maxLength: 4,
          }}
          type="number"
          errorText="Campo obrigatório!"
          value={biomassa}
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              setBiomassa(e.target.value);
            }
          }}
          fullWidth
          sx={{ width: "70%", display: "block" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Tempo de Tolerância (dias)"
          width="11em"
          type="number"
          value={duracaoDias.toleranciaBiomassa}
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              setDuracaoDias.setToleranciaBiomassa(e.target.value);
            }
          }}
        ></TextField>
      </div>

      {/*<SelectInput*/}
      {/*  url={`${ApiUrl}/estado?size=2000`}*/}
      {/*  list={estadoList}*/}
      {/*  set={setEstadoList}*/}
      {/*  setObj={setEstadoId}*/}
      {/*  returnList={() => {*/}
      {/*    let returnList = [];*/}
      {/*    estadoList.forEach((item) => {*/}
      {/*      returnList.push({*/}
      {/*        value: item.id,*/}
      {/*        label: item.nome + " - " + item.sigla,*/}
      {/*      });*/}
      {/*    });*/}
      {/*    return returnList;*/}
      {/*  }}*/}
      {/*  placeholder={"Estado"}*/}
      {/*  defaultValue={estadoId}*/}
      {/*  required={true}*/}
      {/*  fullWidth*/}
      {/*/>*/}
    </>
  );
}
