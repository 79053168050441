import LargeModal from "../../../component/LargeModal/LargeModal";
import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import PopupReset from "../../../component/PopupReset/PopupReset";

const PasswordReset = ({baseUrl, setLoadData, item, children}) => {



    return (
        <>
            <PopupReset
                baseUrl={baseUrl} setLoadData={setLoadData} id={item.id}
            >

                <div className="mt-3 mb-3">
                    <b>Nome do Usuário: </b>
                    <>{item.username}</>
                    <br/>
                    <b>Email do Usuário: </b>
                    <>{item.email}</>
                    <br/>
                </div>

                <p className="mb-3">Você deseja realmente redefinir a senha deste usuário?</p>

            </PopupReset>
        </>
    )
}

export default PasswordReset