import React, {useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SelectInput from "../../../component/SelectInput";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import {ApiUrl} from "../../../auth/authMethods";


export default function InputsCalendarioPlantio({
                                                    ano,setAno,
                                                    regiao,setRegiao,
                                                    dataInicial,setDataInicial,
                                                    dataFinal,setDataFinal,
                                                    tipo,setTipo,
                                                    produtoId,setProdutoId,
                                                    errorColumn, errorMessage
                                                }) {

    const [produtoList, setProdutoList] = useState([])

    const regioesNomes = [
        { value: 'Norte', label: 'Norte' },
        { value: 'Nordeste', label: 'Nordeste' },
        { value: 'Centro-Oeste', label: 'Centro-Oeste' },
        { value: 'Sudeste', label: 'Sudeste' },
        { value: 'Sul', label: 'Sul' },
    ]

    const tipoList = [
        { value: 'PLANTIO', label: 'Plantio' },
        { value: 'COLHEITA', label: 'Colheita' },
    ]

    const handleChangeData = (value, set) => {
        let data = new Date(value)
        try {
            set(data.toISOString().slice(0, 10))
        } catch (e) {
            set(value)
        }
    }

    return <>
        {/*Colocar inputs de cadastro com onChange*/}
        <DatePicker
            label="Data Inicial"
            sx={{
                width: '100%'
            }}
            defaultValue={dataInicial ? dayjs(dataInicial) : null}
            onChange={(e) => handleChangeData(e, setDataInicial)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
            autoFocus
        />
        <DatePicker
            label="Data Final"
            sx={{
                width: '100%'
            }}
            defaultValue={dataFinal ? dayjs(dataFinal) : null}
            onChange={(e) => handleChangeData(e, setDataFinal)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
        />
        <SelectInput
            url={`${ApiUrl}/produto?size=2000`}
            list={produtoList} set={setProdutoList}
            setObj={setProdutoId}
            returnList={() => {
                let returnList = []
                produtoList.forEach((item) => {
                    returnList.push({value: item.id, label: item.tipo + " - " + item.variedade})
                })
                return returnList
            }}
            placeholder={"Produto"}
            defaultValue={produtoId}
            required={true}
            fullWidth
        />
        <Select
            options={regioesNomes}
            onChange={(e) => setRegiao(e?.value)}
            defaultValue={regioesNomes.find(option => option.value === regiao)}
            id="form_select"
            placeholder={"Regiões"}
            fullWidth
        />
        <Select options={tipoList}
            onChange={(e) => setTipo(e?.value)}
            defaultValue={tipoList.find(option => option.value === tipo)}
            id="form_select"
            placeholder={"Tipos"}
            fullWidth
        />
        <TextField
            label="Ano"
            iinputProps={{
                maxLength: 4
            }}
            type="number"
            value={ano}
            onChange={(e) =>{
                if (e.target.value.length <= 4) {
                    setAno(e.target.value);
                }
            }}
            inputProps={{
                min: 1970,
                max: 2100,
                maxLength: 5
            }}
            InputLabelProps={{
                style: {
                    zIndex: 0
                },
            }}
            required
            fullWidth
        />
    </>
}

