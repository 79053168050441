import React, {useState} from "react";
import FilterForm from "../../../component/FilterForm/FilterForm";
import applyRegex from "../../../utils/ApplyRegex";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../component/SelectInput";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import Select from "react-select";
import {ApiUrl} from "../../../auth/authMethods";


export default function FilterFormCalendarioPlantio({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [regiao, setRegiao] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [tipo, setTipo] = useState('');
    const [ano, setAno] = useState('');
    const [produtoId, setProdutoId] = useState('');

    const [productsList, setProductsList] = useState([])

    const baseUrl = `${ApiUrl}`

    const handlesSubmit = (e) => {
        e.preventDefault();

        console.log({
            regiao,
            dataInicial,
            dataFinal,
            tipo,
            produtoId,
            ano
        })

        const requestData ={
        }

        if (regiao !== null && regiao !== "") {
            requestData.regiao = regiao;
        }

        if (dataInicial !== null && dataInicial !== "") {
            requestData.dataInicial = dataInicial;
        }

        if (dataFinal !== null && dataFinal !== "") {
            requestData.dataFinal = dataFinal;
        }

        if (tipo !== null && tipo !== "") {
            requestData.tipo = tipo;
        }

        if (produtoId !== null && produtoId !== "") {
            requestData.produtoId = produtoId;
        }

        if (ano !== null && ano !== "") {
            requestData.ano = ano;
        }

        setFilter(requestData);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {

        setRegiao('')
        setDataInicial('')
        setDataFinal('')
        setTipo('')
        setProdutoId('')
        setAno('')
        // colocar setConst para filtros com os campos
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const handleChangeData = (value, set) => {
        let data = new Date(value)
        try {
            set(data.toISOString().slice(0, 10))
        } catch (e) {
            set(value)
        }
    }

    const regioesNomes = [
        { value: 'Norte', label: 'Norte' },
        { value: 'Nordeste', label: 'Nordeste' },
        { value: 'Centro-Oeste', label: 'Centro-Oeste' },
        { value: 'Sudeste', label: 'Sudeste' },
        { value: 'Sul', label: 'Sul' },
    ]

    const tipoList = [
        { value: 'PLANTIO', label: 'Plantio' },
        { value: 'COLHEITA', label: 'Colheita' },
    ]

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <DatePicker
                label="Data Inicial"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataInicial ? dayjs(dataInicial) : null}
                onChange={(e) => handleChangeData(e, setDataInicial)}
                slotProps={{
                    textField: {
                    },
                }}
                autoFocus
            />
            <DatePicker
                label="Data Final"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataFinal ? dayjs(dataFinal) : null}
                onChange={(e) => handleChangeData(e, setDataFinal)}
                slotProps={{
                    textField: {
                    },
                }}
            />
            <SelectInput
                url={baseUrl + '/produto?size=2000'}
                list={productsList} set={setProductsList}
                setObj={setProdutoId}
                defaultValue={produtoId}
                returnList={() => {
                    let returnList = []
                    productsList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.tipo} - ${item.variedade}`})
                    })
                    return returnList
                }}
                placeholder={"Produto"}
                fullWidth
            />
            <Select
                options={regioesNomes}
                onChange={(e) => setRegiao(e?.value)}
                defaultValue={regioesNomes.find(option => option.value === regiao)}
                id="form_select"
                placeholder={"Regiões"}
                fullWidth
            />
            <Select options={tipoList}
                    onChange={(e) => setTipo(e?.value)}
                    defaultValue={tipoList.find(option => option.value === tipo)}
                    id="form_select"
                    placeholder={"Tipos"}
                    fullWidth
            />
            <TextField
                label="Ano"
                type="number"
                errorText="Campo obrigatório!"
                value={ano}
                onChange={(e) =>{
                    if (e.target.value.length <= 4) {
                        setAno(e.target.value);
                    }
                }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    min: 1970,
                    max: 2100,
                    maxLength: 5
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />


        </FilterForm>


    </>


}