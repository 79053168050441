import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsProduto from "./InputsProduto";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";


const PopupEditProduto = ({item, baseUrl, setLoadData}) => {

    const [id, setId] = useState("")
    const [tipo, setTipo] = useState("")
    const [variedade, setvariedade] = useState("")
    const [temperaturaMaxima, setTemperaturaMaxima] = useState("")
    const [temperaturaMinima, setTemperaturaMinima] = useState("")
    const [pluviometricidadeMaxima, setPluviometricidadeMaxima] = useState("")
    const [pluviometricidadeMinima, setPluviometricidadeMinima] = useState("")
    const [caracteristicas, setCaracteristicas] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const title = "Editar Produto"
    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp;{title}
    </>

    const inputs = <InputsProduto
        tipo={tipo} setTipo={setTipo}
        variedade={variedade} setVariedade={setvariedade}
        caracteristicas={caracteristicas} setCaracteristicas={setCaracteristicas}
        temperaturaMinima={temperaturaMinima} temperaturaMaxima={temperaturaMaxima}
        setTemperaturaMinima={setTemperaturaMinima} setTemperaturaMaxima={setTemperaturaMaxima}
        pluviometricidadeMinima={pluviometricidadeMinima} pluviometricidadeMaxima={pluviometricidadeMaxima}
        setPluviometricidadeMinima={setPluviometricidadeMinima} setPluviometricidadeMaxima={setPluviometricidadeMaxima}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
    />
    const itemToState = () => {
        setId(item.id)
        setTipo(item.tipo)
        setPluviometricidadeMinima(item.pluviometricidadeMinima)
        setPluviometricidadeMaxima(item.pluviometricidadeMaxima)
        setTemperaturaMinima(item.temperaturaMinima)
        setTemperaturaMaxima(item.temperaturaMaxima)
        setvariedade(item.variedade)
        setCaracteristicas(item.caracteristicas)
    }

    const returnData = () => {
        return {
            id: item.id,
            tipo,
            pluviometricidadeMinima,
            pluviometricidadeMaxima,
            temperaturaMinima,
            temperaturaMaxima,
            variedade,
            caracteristicas
        }
    }

    return(
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + id} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                buttonClass='dropdown-item' buttonText={buttonText}
            />
        </>
    )
}
export default PopupEditProduto