import React, {useState} from 'react';
import {List,} from '@mui/material';
import LargeModal from '../../../component/LargeModal/LargeModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ExploreIcon from "@mui/icons-material/Explore";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import Filter1Icon from "@mui/icons-material/Filter1";
import HouseIcon from '@mui/icons-material/House';
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../../utils/numberWithPoints";

const CarDetails = ({item}) => {
    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do CAR
    </>

    const title = "Detalhes do CAR"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    return (
        <LargeModal
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonId={"links-action"}
            buttonClass='dropdown-item'
        >

            <List
                sx={{width: '80%', bgcolor: 'background.paper', marginInline: '10%'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className='pointer-event'
            >
                <DisplayDetailsData
                    key="primaryData"
                    title="Dados primários"
                    itens={[
                        {
                            icon: <Filter1Icon key="item1"/>,
                            display: `Registro: ${item.registro}`,
                        },
                        {
                            icon: <HouseIcon key="item2"/>,
                            display: `Nome do Imovél rural: ${
                                item.nomeImovelRural ? item.nomeImovelRural : 'Não cadastrado'
                            }`,
                        },
                        {
                            icon: <InsertInvitationIcon key="item3"/>,
                            display: `Data de Cadastro: ${normalizeTimestemp(item.dataCadastro)}`,
                        },
                        {
                            icon: <LocationCityIcon key="item4"/>,
                            display: `Município: ${item.cidade.nome}`,
                        },
                        {
                            icon: <MapIcon key="item5"/>,
                            display: `Estado: ${item.cidade.estado.nome}`,
                        },
                    ]}
                />
                <DisplayDetailsData
                    key="areaImovel"
                    title="Área Do Imovel"
                    itens={[
                        {
                            icon: <SquareFootIcon key="item1"/>,
                            display: `Área Total: ${numberWithPoints(item.hectares, 4)} ha`,
                        },
                        {
                            icon: <ExploreIcon key="item2"/>,
                            display: `Latitude: ${item.latitude}`,
                        },
                        {
                            icon: <ExploreIcon key="item3"/>,
                            display: `Longitude: ${item.longitude}`,
                        },
                    ]}
                />
                <DisplayDetailsData
                    key="informacoesAdicionais"
                    title="Informações Adicionais"
                    itens={[
                        {
                            icon: <SupervisorAccountIcon key="item1"/>,
                            display: `Módulo Fiscal: ${
                                item.moduloFiscal ? item.moduloFiscal : 'Não cadastrado'
                            }`
                        },
                        {
                            icon: <VpnKeyIcon key="item2"/>,
                            display: `Código de Protocolo: ${
                                item.codigoProtocolo ? item.codigoProtocolo : 'Não cadastrado'
                            }`
                        },
                    ]}
                />
                <DisplayDetailsData
                    key="informacoesCpr"
                    title="Informações da CPR Cadastrada"
                    itens={[
                        {
                            icon: <VpnKeyIcon key="item1"/>,
                            display: `Número de Ordem: ${item.cpr.numeroOrdem}`,
                        },
                        {
                            icon: <ExploreIcon key="item2"/>,
                            display: `Coordenadas: ${item.cpr.coordenadas}`,
                        },
                        {
                            icon: <InsertInvitationIcon key="item3"/>,
                            display: `Data de Emissão: ${normalizeTimestemp(item.cpr.dataEmissaoCedula)}`,
                        },
                        {
                            icon: <InsertInvitationIcon key="item4"/>,
                            display: `Data de Vencimento: ${normalizeTimestemp(item.cpr.dataVencimentoCedula)}`,
                        },
                    ]}
                />
            </List>
        </LargeModal>
    )
}

export default CarDetails