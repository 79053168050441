import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import React from "react";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {ApiUrl} from "../../../auth/authMethods";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const PopupDeleteCpr = ({
                            setLoadData,
                            item
                        }) => {
    const baseUrl = ApiUrl + "/cpr"

    const buttonText = <span className="link-danger">
        <DeleteForeverIcon fontSize="medium"/>
        &nbsp;Deletar
    </span>

    return (
        <PopupDelete
            buttonClass={'dropdown-item text-danger'}
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de CPR"} id={item.id}
            buttonText={buttonText}
        >

            <div className="mt-3 mb-3">
                <b>Numero de ordem: </b>
                <>{item.numeroOrdem}</>
                <br/>
                <b>Data de vencimento: </b>
                <>{normalizeTimestemp(item.dataVencimentoCedula)}</>
                <br/>
                <b>Data de emissão: </b>
                <>{normalizeTimestemp(item.dataEmissaoCedula)}</>
                <br/>
                <b>Numero de emitentes: </b>
                <>{item.emitentes.length}</>
                <br/>
                <b>Data de credores: </b>
                <>{item.credores.length}</>
                <br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir esta CPR?</p>

        </PopupDelete>
    )
}
export default PopupDeleteCpr