import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../utils/ApplyRegex";
import SelectInput from "../../../component/SelectInput";
import {cnpjMask} from "../../../utils/cnpjMask";
import {rgMask} from "../../../utils/rgMask";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import {cpfMask} from "../../../utils/cpfMask";
import {ApiUrl} from "../../../auth/authMethods";


export default function FilterFormEmitente({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [nome, setNome] = useState('');
    const [rg, setRg] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [cpf, setCpf] = useState('');
    const [cidadeId, setCidadeId] = useState('');
    const [cidadeList, setCidadeList] = useState([])

    const [isCnpj, setIsCnpj] = useState();

    const toggleSwitch = () => {
        setIsCnpj((prev) => !prev);
    };

    useEffect(() => {
        if(cnpj !== '' && cpf === ''){
            setIsCnpj(false)
        } else if(cnpj === '' && cpf !== ''){
            setIsCnpj(true)
        }
    },[])

    const changeCpf = (cpf) => {
        if (cpf !== undefined) {
            setCpf(cpf.replaceAll('.', '').replaceAll('-', ''));
        }
    };

// Altere a função changeCnpj
    const changeCnpj = (cnpj) => {
        if (cnpj !== undefined) {
            setCnpj(cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''));
        }
    };

    const baseUrl = ApiUrl

    const handlesSubmit = (e) => {
        console.log({nome,
            rg,
            cnpj,
            cpf,
            cidadeId})

        e.preventDefault();

        const data ={}

        if (cnpj !== "") {
            data.cnpj = cnpj;
        }
        if (cpf !== "") {
            data.cpf = cpf;
        }
        if (nome !== "") {
            data.nome = nome;
        }
        if (rg !== "") {
            data.rg = rg;
        }
        if (cidadeId !== "") {
            data.cidadeId = cidadeId;
        }

            setFilter(data);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNome('')
        setRg('')
        setCnpj('')
        setCpf('')
        setCidadeId('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <TextField
                label="Nome do Emitente"
                inputProps={{
                    maxLength: 40
                }}
                value={nome}
                onChange={(e) => {
                    setNome(applyRegex(e.target.value, /([a-zA-Za-záàâãéèêíïóôõöúçñ ])/g))
                }}
                fullWidth
                autoFocus
            />
            <TextField
                label="RG"
                inputProps={{
                    maxLength: 20,
                    minLength: 10,
                }}
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                fullWidth
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                    {isCnpj ? 'CPF' : 'CNPJ'}
                </Typography>
                <Switch
                    checked={isCnpj}
                    onChange={toggleSwitch}
                    color="primary"
                    inputProps={{ 'aria-label': 'alternar entre CNPJ e CPF' }}
                />

            {isCnpj ? (

                <TextField
                    label="CPF"
                    inputProps={{
                        maxLength: 14
                    }}
                    errorText="Campo obrigatório!"
                    value={cpfMask(cpf)}
                    onChange={(e) => changeCpf(e.target.value)}
                    id="input-cpf"
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                    fullWidth
                />

            ) : (

                <TextField
                    label="CNPJ"
                    inputProps={{
                        maxLength: 18
                    }}
                    errorText="Campo obrigatório!"
                    value={cnpjMask(cnpj)}
                    onChange={(e) => changeCnpj(e.target.value)}
                    id="input-cnpj"
                    fullWidth
                    InputLabelProps={{
                        style: {
                            zIndex: 0
                        },
                    }}
                />
            )}
            </div>
            {/*<SelectInput*/}
            {/*    url={baseUrl + '/cidade?size=1000000'}*/}
            {/*    list={cidadeList} set={setCidadeList}*/}
            {/*    setObj={setCidadeId}*/}
            {/*    returnList={() => {*/}
            {/*        let returnList = []*/}
            {/*        cidadeList.forEach((item) => {*/}
            {/*            returnList.push({value: item.id, label: item.nome + " - " + item.estado.sigla})*/}
            {/*        })*/}
            {/*        return returnList*/}
            {/*    }}*/}
            {/*    placeholder={"Cidade"}*/}
            {/*    defaultValue={cidadeId}*/}
            {/*    fullWidth*/}
            {/*/>*/}

        </FilterForm>
    </>
}