import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import applyRegex from "../../../utils/ApplyRegex";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";

export default function InputsCidade({  nome, setNome,
                                         errorColumn, errorMessage,estadoId, setEstadoId
                                     }) {

    const [estadoList, setEstadoList] = useState([])

    return <>
        <TextField
            label="Nome"
            inputProps={{
                maxLength: 38
            }}
            error={errorColumn === 'nome'}
            helperText={errorColumn === 'nome' ? errorMessage : ''}
            value={nome}
            onChange={(e) => {
                setNome(applyRegex(e.target.value, /^[a-záàâãéèêíïóôõöúçñ ]+$/i))
            }}
            required
            fullWidth
            autoFocus
        />

        <SelectInput
            url={`${ApiUrl}/estado?size=2000`}
            list={estadoList} set={setEstadoList}
            setObj={setEstadoId}
            returnList={() => {
                let returnList = []
                estadoList.forEach((item) => {
                    returnList.push({value: item.id, label: item.nome +" - "+ item.sigla})
                })
                return returnList
            }}
            placeholder={"Estado"}
            defaultValue={estadoId}
            required={true}
            fullWidth
        />
    </>

}