import React, {useState} from "react";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import InputsClima from "./InputsClima";


export default function PopupAddClima({ baseUrl,setLoadData}) {


    const [vitalidadeSolo, setVitalidadeSolo] = useState('');
    const [temperatura, setTemperatura] = useState('');
    const [pluviosidade, setPluviosidade] = useState('');

    const [estadoId, setEstadoId] = useState('');
    const [produtoId, setProdutoId] = useState('')

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsClima
        baseUrl={baseUrl}
        vitalidadeSolo={vitalidadeSolo} setVitalidadeSolo={setVitalidadeSolo}
        temperatura={temperatura} setTemperatura={setTemperatura}
        pluviosidade={pluviosidade} setPluviosidade={setPluviosidade}
        estadoId={estadoId} setEstadoId={setEstadoId}
        produtoId={produtoId} setProdutoId={setProdutoId}
        errorColumn={errorColumn}
        setErrorColumn={setErrorColumn}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
    />

    //Trocar Icon
    const icon = <SouthAmericaIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setVitalidadeSolo('')
        setTemperatura('')
        setPluviosidade('')
        setEstadoId('')
        setProdutoId('')
    }

    const returnData = () => {

        console.log({
            vitalidadeSolo,
            temperatura,
            pluviosidade,
            estadoId,
            produtoId
        })
        return {
            vitalidadeSolo,
            temperatura,
            pluviosidade,
            estadoId,
            produtoId
        }
    }


    return <PopupAdd
        baseUrl={baseUrl} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar Condições Ideais'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}